import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function StocCostTable() {


    const navigate = useNavigate();



  const tableHeaderStyle = {
    background: '#f2f2f2',
    border: '1px solid #dddddd',
    padding: '8px',
    color: 'black',
    textAlign: 'left',
    fontWeight: 'bold',
  };

  const tableCellStyle = {
    border: '1px solid #dddddd',
    color: 'grey',
    padding: '8px',
    textAlign: 'left',
  };

  const [goldbar, setgoldbar] = useState([]);
  const [goldcoin, setgoldcoin] = useState([]);
  const [goldcoin916, setgoldcoin916] = useState([]);
    const [silverbar, setsilverbar] = useState([]);
    const [silvercoin, setsilvercoin] = useState([]);
const [goldName,setGoldName] = useState("");
const [silverName,setSilverName] = useState("");
const [goldCoinName,setGoldCoinName] = useState("");
const [goldCoin916Name,setGoldCoin916Name] = useState("");
const [silverCoinName,setSilverCoinName] = useState("");


  useEffect(() => {

    axios.get('https://api.dremerz.net/api/kg999StockName/')
        .then((response) => {   
            var   a = response.data.filter((item) => item.stockname == 'Gold Bar');
            a.sort((a, b) => parseFloat(a.name) - parseFloat(b.name));
            setGoldName(a);
            var  b = response.data.filter((item) => item.stockname == 'Silver Bar');
            b.sort((a, b) => parseFloat(a.name) - parseFloat(b.name));
            setSilverName(b);
            var   c= response.data.filter((item) => item.stockname == 'Gold Coin');
            c.sort((a, b) => parseFloat(a.name) - parseFloat(b.name));
            setGoldCoinName(c);
            var  d = response.data.filter((item) => item.stockname == 'Silver Coin');
            d.sort((a, b) => parseFloat(a.name) - parseFloat(b.name));
            setSilverCoinName(d);
            var  e = response.data.filter((item) => item.stockname == 'Gold Coin 916');
            e.sort((a, b) => parseFloat(a.name) - parseFloat(b.name));
            setGoldCoin916Name(e);


        })
        .catch((error) => {
            console.error('Error fetching data from the API:', error);
        });


    axios.get('https://api.dremerz.net/api/kg999StockList/')
        .then((response) => {
          var   a = response.data.filter((item) => item.stockkey == 'Gold Bar');
           
            setgoldbar(a);
           var  b = response.data.filter((item) => item.stockkey == 'Gold Coin');
            setgoldcoin(b);
          var   c= response.data.filter((item) => item.stockkey == 'Silver Bar');
            setsilverbar(c);
          var  d = response.data.filter((item) => item.stockkey == 'Silver Coin');
            setsilvercoin(d);
            var  e = response.data.filter((item) => item.stockkey == 'Gold Coin 916');
            setgoldcoin916(e);

        })
        .catch((error) => {
            console.error('Error fetching data from the API:', error);
        });


 
  }, []);

  return (
    <>
        <div>

            <span className="btn btn-primary mr-2" onClick={() => {
                navigate("/stockSummary")
            }
            }>
                Print Stock Data
                </span>
            <br />
            <br />
            <br />




  <table style={{ width: '100%', borderCollapse: 'collapse' }}>
  <thead>
    <tr>
      <th colSpan="4" style={{ ...tableHeaderStyle, textAlign: 'center', color: 'black', fontWeight: 'bold' }}>
        GOLD BAR STOCK TABLE
      </th>
    </tr>
    <tr>
      <th style={tableHeaderStyle}>STOCK ITEM</th>
      <th style={tableHeaderStyle}>TOTAL STOCK</th>
      <th style={tableHeaderStyle}>SOLD STOCK</th>
      <th style={tableHeaderStyle}>AVAILABLE STOCK</th>
    </tr>
  </thead>

  <tbody>
    {goldName.length > 0 &&
      goldName.map((goldItem) => {
        const matchingBarItem = goldbar.find((barItem) => barItem.grams == goldItem.name);

        if (matchingBarItem) {
          const totalStock = goldbar.reduce((sum, item) => (item.grams == goldItem.name && item.quantity !== '') ? sum + parseInt(item.quantity) : sum, 0);

          const totalSold = goldbar.reduce((sum, item) => (item.grams == goldItem.name && item.sold !== '') ? sum + parseInt(item.sold) : sum, 0);

          const totalAvailable = totalStock - totalSold;

          return (
            <tr key={goldItem.name}>
              <td style={tableHeaderStyle}>{goldItem.name} GRAM</td>
              <td style={{ ...tableCellStyle, color: 'black' }}>{totalStock}</td>
              <td style={{ ...tableCellStyle, color: 'black' }}>{totalSold}</td>
              <td style={{ ...tableCellStyle, color: 'black' }}>{totalAvailable}</td>
            </tr>
          );
        }

        return null;
      })}
  </tbody>
</table>

<br />
<br />
<br />

<table style={{ width: '100%', borderCollapse: 'collapse' }}>
    <thead>
        <tr>
            <th colSpan="4" style={{ ...tableHeaderStyle, textAlign: 'center', color: 'black', fontWeight: 'bold' }}>
                SILVER BAR STOCK TABLE
            </th>
        </tr>
        <tr>
            <th style={tableHeaderStyle}>STOCK ITEM</th>
            <th style={tableHeaderStyle}>TOTAL STOCK</th>
            <th style={tableHeaderStyle}>SOLD STOCK</th>
            <th style={tableHeaderStyle}>AVAILABLE STOCK</th>
        </tr>
    </thead>

    <tbody>
        {silverName.length > 0 &&
            silverName.map((silverItem) => {
                const matchingBarItem = silverbar.find((barItem) => barItem.grams == silverItem.name);

                if (matchingBarItem)    {
                    const totalStock = silverbar.reduce((sum, item) => (item.grams == silverItem.name && item.quantity !== '') ? sum + parseInt(item.quantity) : sum, 0);

                    const totalSold = silverbar.reduce((sum, item) => (item.grams == silverItem.name && item.sold !== '') ? sum + parseInt(item.sold) : sum, 0);

                    const totalAvailable = totalStock - totalSold;

                    return (
                        <tr key={silverItem.name}>
                            <td style={tableHeaderStyle}>{silverItem.name} GRAM</td>
                            <td style={{ ...tableCellStyle, color: 'black' }}>{totalStock}</td>
                            <td style={{ ...tableCellStyle, color: 'black' }}>{totalSold}</td>
                            <td style={{ ...tableCellStyle, color: 'black' }}>{totalAvailable}</td>
                        </tr>
                    );
                }

                return null;
            })}
    </tbody>
    
</table>

<br />
<br />
<br />

<table style={{ width: '100%', borderCollapse: 'collapse' }}>
    <thead>
        <tr>
            <th colSpan="4" style={{ ...tableHeaderStyle, textAlign: 'center', color: 'black', fontWeight: 'bold' }}>
                GOLD COIN STOCK TABLE
            </th>
        </tr>
        <tr>
            <th style={tableHeaderStyle}>STOCK ITEM</th>
            <th style={tableHeaderStyle}>TOTAL STOCK</th>
            <th style={tableHeaderStyle}>SOLD STOCK</th>
            <th style={tableHeaderStyle}>AVAILABLE STOCK</th>
        </tr>
    </thead>
            
    <tbody>
        {goldCoinName.length > 0 &&
            goldCoinName.map((goldCoinItem) => {
                const matchingBarItem = goldcoin.find((barItem) => barItem.grams == goldCoinItem.name);

                if (matchingBarItem) {
                    const totalStock = goldcoin.reduce((sum, item) => (item.grams == goldCoinItem.name && item.quantity !== '') ? sum + parseInt(item.quantity) : sum, 0);

                    const totalSold = goldcoin.reduce((sum, item) => (item.grams == goldCoinItem.name && item.sold !== '') ? sum + parseInt(item.sold) : sum, 0);

                    const totalAvailable = totalStock - totalSold;

                    return (
                        <tr key={goldCoinItem.name}>
                            <td style={tableHeaderStyle}>{goldCoinItem.name} GRAM</td>
                            <td style={{ ...tableCellStyle, color: 'black' }}>{totalStock}</td>
                            <td style={{ ...tableCellStyle, color: 'black' }}>{totalSold}</td>
                            <td style={{ ...tableCellStyle, color: 'black' }}>{totalAvailable}</td>
                        </tr>
                    );
                }

                return null;
            })}
    </tbody>

</table>




<br />
<br />
<br />


<table style={{ width: '100%', borderCollapse: 'collapse' }}>
    <thead>
        <tr>
            <th colSpan="4" style={{ ...tableHeaderStyle, textAlign: 'center', color: 'black', fontWeight: 'bold' }}>
                GOLD COIN 916 STOCK TABLE
            </th>
        </tr>
        <tr>
            <th style={tableHeaderStyle}>STOCK ITEM</th>
            <th style={tableHeaderStyle}>TOTAL STOCK</th>
            <th style={tableHeaderStyle}>SOLD STOCK</th>
            <th style={tableHeaderStyle}>AVAILABLE STOCK</th>
        </tr>
    </thead>
            
    <tbody>
        {goldCoin916Name.length > 0 &&
            goldCoin916Name.map((goldCoinItem) => {
                const matchingBarItem = goldcoin916.find((barItem) => barItem.grams == goldCoinItem.name);

                if (matchingBarItem) {
                    const totalStock = goldcoin916.reduce((sum, item) => (item.grams == goldCoinItem.name && item.quantity !== '') ? sum + parseInt(item.quantity) : sum, 0);

                    const totalSold = goldcoin916.reduce((sum, item) => (item.grams == goldCoinItem.name && item.sold !== '') ? sum + parseInt(item.sold) : sum, 0);

                    const totalAvailable = totalStock - totalSold;

                    return (
                        <tr key={goldCoinItem.name}>
                            <td style={tableHeaderStyle}>{goldCoinItem.name} GRAM</td>
                            <td style={{ ...tableCellStyle, color: 'black' }}>{totalStock}</td>
                            <td style={{ ...tableCellStyle, color: 'black' }}>{totalSold}</td>
                            <td style={{ ...tableCellStyle, color: 'black' }}>{totalAvailable}</td>
                        </tr>
                    );
                }

                return null;
            })}
    </tbody>

</table>

<br/>
<br/>
<br/>

<table style={{ width: '100%', borderCollapse: 'collapse' }}>
    <thead>
        <tr>
            <th colSpan="4" style={{ ...tableHeaderStyle, textAlign: 'center', color: 'black', fontWeight: 'bold' }}>
                SILVER COIN STOCK TABLE
            </th>
        </tr>
        <tr>
            <th style={tableHeaderStyle}>STOCK ITEM</th>
            <th style={tableHeaderStyle}>TOTAL STOCK</th>
            <th style={tableHeaderStyle}>SOLD STOCK</th>
            <th style={tableHeaderStyle}>AVAILABLE STOCK</th>
        </tr>
    </thead>

    <tbody>
        {silverCoinName.length > 0 &&
            silverCoinName.map((silverCoinItem) => {
                const matchingBarItem = silvercoin.find((barItem) => barItem.grams == silverCoinItem.name);

                if (matchingBarItem) {
                    const totalStock = silvercoin.reduce((sum, item) => (item.grams == silverCoinItem.name && item.quantity !== '') ? sum + parseInt(item.quantity) : sum, 0);

                    const totalSold = silvercoin.reduce((sum, item) => (item.grams == silverCoinItem.name && item.sold !== '') ? sum + parseInt(item.sold) : sum, 0);

                    const totalAvailable = totalStock - totalSold;

                    return (
                        <tr key={silverCoinItem.name}>
                            <td style={tableHeaderStyle}>{silverCoinItem.name} GRAM</td>
                            <td style={{ ...tableCellStyle, color: 'black' }}>{totalStock}</td>
                            <td style={{ ...tableCellStyle, color: 'black' }}>{totalSold}</td>
                            <td style={{ ...tableCellStyle, color: 'black' }}>{totalAvailable}</td>
                        </tr>
                    );
                }

                return null;
            })}
    </tbody>
</table>






<br/>
<br/>
<br/>






                    </div>
                    </>
 
  );
}
