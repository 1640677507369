import React, { useState, useEffect } from 'react';
import axios from 'axios';

export default function NewStockName
 () {


    // set the date should be uin the format of "dd/mm/yyyy"

const [formData, setFormData] = useState({
    "name": '',
    "stockname": 'Gold Bar',
    "number": '',

    

    "date": new Date().toLocaleString("en-US", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: true
      }),
      "cost": "",
      
}
);

const [tableData, setTableData] = useState([]);
  const apiUrl = 'https://api.dremerz.net/api/kg999StockName/';



useEffect(() => {
   
    axios.get(apiUrl)
        .then((response) => {
            setTableData(response.data,
        );
        })
        .catch((error) => {
        console.error('Error fetching data from the API:', error);
        });
    }

    , [formData]);

  const handleSubmit = () => {
    const isConfirmed = window.confirm('Are you sure you want to update the data?');

    if(formData.name == "" || formData.number == ""){
        alert("Please fill all the fields")
        return;
    }

    if (isConfirmed) {
      // User confirmed, proceed with the update
      // Send the updated data to the API
      axios.post(apiUrl, formData)
        .then((response) => {
          // Handle the response, e.g., show a success message
          alert('Data updated successfully');
            setFormData({
                "name": "",
                "stockname": "Gold Bar",
                "number": "",
                "cost": "",

            });
        })
        .catch((error) => {
            alert('Error updating data in the API:');
            console.error('Error updating data in the API:', error.response);
        });
    } else {
      // User canceled the update
      console.log('Data update canceled');
    }
  };

  return (


      <div className="col-md-6 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
    
            
            <form className="forms-sample">
                <div className="form-group">
                    <label htmlFor="exampleInputUsername1">
                        QUANTITY
                    </label>
                    <input
                    
                    style={{width:"100%"}}
                    type="text"
                    className="form-control"
                    id="exampleInputUsername1"
                    placeholder="QUANTITY"
                    name="name"
                    value={formData.name}
                
                    onChange={(event)=>{
                        setFormData({
                            ...formData,
                            name: event.target.value,
                        });
                    }}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">STOCK NAME</label>
                    <select
                    style={{width:"100%"}}
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="Stock Name"
                    name="stockname"
                    value={formData.stockname}
                    onChange={(event)=>{
                        setFormData({
                            ...formData,
                            stockname: event.target.value,
                        });
                }}
                    >
                        <option  value={"Gold Bar"}  >Gold Bar</option>
                        <option  value={"Silver Bar"}  >Silver Bar</option>
                        <option  value={"Gold Coin"}  >Gold Coin</option>
                        <option  value={"Silver Coin"}  >Silver Coin</option>
                        <option  value={"Gold Coin 916"}  >Gold Coin 916</option>
                    </select>

                </div>
                <div className="form-group">
                    <label htmlFor="exampleInputPassword1">HSN / SAC CODE</label>
                    <input
                    type="text"
                    style={{width:"100%"}}
                    className="form-control"
                    id="exampleInputPassword1"
                    placeholder="HSN / SAC CODE"
                    name="number"
                    value={formData.number}
                    onChange={
                        (event)=>{
                            setFormData({
                                ...formData,
                                number: event.target.value,
                            });
                        }
                    }
                    />
                </div>
                <span type="submit" className="btn btn-primary mr-2" onClick={handleSubmit}>
                    Submit
                </span>
              
            </form>


          </div>
        </div>

        <div className="card">

        <table className="table table-striped">
    <thead>
        <tr>
            <th>NAME</th>
            <th>STOCK NAME</th>
            <th>HSN / SAC CODE</th>
            <th>UPDATE</th>
            <th>DELETE</th>
        </tr>
    </thead>
    <tbody>
            {
                tableData.length > 0 && tableData.map((item, index) => (
                    <tr key={index}>
                        <td>{item.name}</td>
                        <td>{item.stockname}</td>
                        <td>
  <input
    type="text"
    style={{ width: "100%" }}
    className="form-control"
    id="exampleInputPassword1"
    placeholder="HSN / SAC CODE"
    name="number"
    value={tableData[index].number}
    onChange={(event) => {
      setTableData(() =>
      tableData.map((item2, index2) =>
          index2 == index
            ? {
                ...item2,
                number: event.target.value,
              }
            : item2
        )
      );
    }}
  />
</td>


                            <td>
                            <span
                        onClick={()=>{
                            axios.put(apiUrl+item.id+"/",item)
                            .then((response) => {
                                alert('Data updated successfully');
                            })
                            .catch((error) => {
                                alert('Error updating data in the API:');
                            });
                        }
                        }
                        className="btn btn-primary">UPDATE</span>

                            </td>
                        <td>
                            
                            <span 
                        onClick={()=>{
                            axios.delete(apiUrl+item.id+"/")
                            .then((response) => {
                                alert('Data deleted successfully');
                                window.location.reload();
                            })
                            .catch((error) => {
                                alert('Error deleting data in the API:');
                            });
                        }}  
                        className="btn btn-danger">DELETE</span></td>
                    </tr>

               
                ))
            }   
    </tbody>
</table>
</div>
      </div>
    

  );
}
