import React, { useEffect, useState } from 'react';
import AddGoldBardStock from '../Components/AddGoldBardStock';
import AddGoldCoinStocks from '../Components/AddGoldCoinStocks';
import AddSilverBarStock from '../Components/AddSilverBarStock';
import AddSilverCoinStock from '../Components/AddSilverCoinStock';
import { useNavigate } from 'react-router-dom';
import AddGoldCoinStock916 from '../Components/AddGoldCoin916Stock';
import axios from 'axios';
import Select from "react-select";

export default function AddStock() {
  const navigate = useNavigate();
  const [date, setDate] = useState();

  const formatFromInputDate = (isoDateString) => {
    const [year, month, day] = isoDateString.split('-');
    return `${day}/${month}/${year}`;
  };

  const [vendorData, setVendorData] = useState([]);

  const [singleVendorData, setsingleVendorData] = useState({
    name: "",
    number: "",
    address: ""
  });

  const handleVendorChange = (selectedOption) => {
    if (selectedOption === null) {
      setsingleVendorData({
        name: "",
        number: "",
        address: ""
      });
    } else {
      const selectedVendor = vendorData.find((vendor) => vendor.name === selectedOption.value);
      if (selectedVendor === undefined) {
        setsingleVendorData({
          name: "",
          number: "",
          address: ""
        });
      } else {
        setsingleVendorData(selectedVendor);
      }
    }
  };

  const vendorOptions = [{ value: "", label: "SELECT VENDOR" }, ...vendorData.map(vendor => ({ value: vendor.name, label: vendor.name }))];



  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === 'F2' || event.key === '-') {
        navigate('/newSale');
      }
    }

    window.addEventListener('keydown', handleKeyDown);

   
  }, []);



  useEffect(()=>{
    
      axios.get('https://api.dremerz.net/api/kg999StockList/')
      .then((response) => {
        // get unique vendor names
        const uniqueVendorNames = Array.from(new Set(response.data.map((item) => item.name)));
        const uniqueVendorData = uniqueVendorNames.map((name) => {
          return response.data.find((item) => item.name === name);
        });
        setVendorData(uniqueVendorData);
      })
      .catch((error) => {
        console.error('Error fetching data from the API:', error);
      });
  },[])


  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h1 style={{ color: 'black', fontWeight: 'bolder' }}>ADD STOCK DATA!</h1>


      </div>

      {/* <!-- create a form to get data of existing vendor --> */}
      <div style={{ display: "flex", justifyContent: "center", flexDirection: "row", gap: '50px' }}>

      <div className="form-group" style={{width:"250px"}}>
              <label htmlFor="date"> SELECT DATE</label>
              <input

                style={
                  { width: "80%" }}
                type="date"
                className="form-control"
                onChange={(e) => {
                  setDate(formatFromInputDate(e.target.value));
                }}
                
              />
            </div>
      <div className="form-group" style={{width:"350px"}}>
      <label htmlFor="name">VENDOR NAME</label>
      <Select
      
        value={{ value: singleVendorData.name, label: singleVendorData.name }}
        onChange={handleVendorChange}
        options={vendorOptions}
        placeholder="Select vendor..."
        isSearchable
      />
    </div>


    </div>

    <br />

      <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
        {/* Pass date as a prop to the components */}
        <AddGoldBardStock date={date} vendorData={singleVendorData}></AddGoldBardStock>
        <AddSilverBarStock date={date} vendorData={singleVendorData}></AddSilverBarStock>
        <AddGoldCoinStocks date={date} vendorData={singleVendorData}></AddGoldCoinStocks>
        <AddSilverCoinStock date={date} vendorData={singleVendorData}></AddSilverCoinStock>
        <AddGoldCoinStock916 date={date} vendorData={singleVendorData}></AddGoldCoinStock916>

      </div>
    </div>
  );
}
