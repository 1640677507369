import React, { useEffect, useState } from 'react';
import axios, { all } from 'axios';
import { useNavigate } from 'react-router-dom';

export default function SalePage() {
    const [stockData, setStockData] = useState();
    const [filteredstockData, setfilteredStockData] = useState();
    const[salesuess,setSalesuess]=useState(false);

  const [apiUrl, setApiUrl] = useState('https://api.dremerz.net/api/kg999GoldBarStockList/');

  const [paid,setPaid]=useState(0);
  const [balance,setBalance]=useState(0);

  const [invoiceNumber,setInvoiceNumber]=useState(0);
  const [formData, setFormData] = useState({
    date: new Date().toLocaleDateString(),
    invoice: 0,
    paymentMode: 'Cash',
    name: '',
    address: '',
    aadharotpan: '',
    number: '',
    quantity: 0,
    grams: '0.5',
    byuDate: '',
    stockType: 'gold', // Default to 'gold'
    amount: 0,
    discount: 0,
    totalAmount: 0,
    previousAmount: 0,
    soldBy:"Admin"
  });
  const [goldstockKey, setGoldStockKey] = useState('half_stock');
  const [goldsoldKey, setGoldSoldKey] = useState('half_sold');
  const [goldpriceKey, setGoldPriceKey] = useState('half_price');
  const [silverstockKey, setSilverStockKey] = useState('ten_stock');
  const [silversoldKey, setSilverSoldKey] = useState('ten_sold');
  const [silverpriceKey, setSilverPriceKey] = useState('ten_price');

const[GoldBarPrice,setGoldBarPrice]=useState();
const[SilverBarPrice,setSilverBarPrice]=useState();
const[GoldCoinPrice,setGoldCoinPrice]=useState();
const[SilverCoinPrice,setSilverCoinPrice]=useState();  


//   const handleSubmit = (e) => {
//     e.preventDefault();

//     // Send the formData to your API endpoint using Axios or any other HTTP library.
//     axios
//       .post('https://api.dremerz.net/api/kg999ClientList/', formData)
//       .then((response) => {
//         // Handle success, e.g., show a success message to the user.
//         console.log('Data submitted successfully:', response.data);
//       })
//       .catch((error) => {
//         // Handle error, e.g., show an error message to the user.
//         console.error('Error submitting data:', error);
//       });
//   };
const [error,setError]=useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

//   {
//     "half": "",
//     "one": "0",
//     "two": "0",
//     "five": "0",
//     "ten": "0",
//     "twenty": "0",
//     "thirty": "0",
//     "fifty": "00",
//     "hundred": "0",
// }
// {
//     "ten": "0",
//     "twenty": "0",
//     "fifty": "0",
//     "hundred": "00",
//     "twofifty": "00",
//     "fivehundred": "0",
//     "thousand": "0",
// }
const [goldCostKey, setGoldCostKey] = useState('half');
const [silverCostKey, setSilverCostKey] = useState('ten');


  const  silverStock = (data)=>
    {
        switch (data) {
            case 'type3':
              setSilverStockKey('ten_stock');
              setSilverSoldKey('ten_sold');
              setSilverPriceKey('ten_price');
              setSilverCostKey('ten');
              setFormData({ ...formData, grams: '10',quantity:0 });
              break;
            case 'type4':
              setSilverStockKey('twenty_stock');
              setSilverSoldKey('twenty_sold');
              setSilverPriceKey('twenty_price');
              setSilverCostKey('twenty');
              setFormData({ ...formData, grams: '20' ,quantity:0 });
              break;
            case 'type5':
              setSilverStockKey('fifty_stock');
              setSilverSoldKey('fifty_sold');
              setSilverPriceKey('fifty_price');
              setSilverCostKey('fifty');
              setFormData({ ...formData, grams: '50' ,quantity:0 });
              break;
            case 'type6':
              setSilverStockKey('hundred_stock');
              setSilverSoldKey('hundred_sold');
              setSilverPriceKey('hundred_price');
              setSilverCostKey('hundred');
              setFormData({ ...formData, grams: '100' ,quantity:0 });
              break;
            case 'type7':
              setSilverStockKey('twofifty_stock');
              setSilverSoldKey('twofifty_sold');
              setSilverPriceKey('twofifty_price');
              setSilverCostKey('twofifty');
              setFormData({ ...formData, grams: '250' ,quantity:0 });
              break;
            case 'type8':
              setSilverStockKey('fivehundred_stock');
              setSilverSoldKey('fivehundred_sold');
              setSilverPriceKey('fivehundred_price');
              setSilverCostKey('fivehundred');
              setFormData({ ...formData, grams: '500' ,quantity:0 });
              break;
            case 'type9':
              setSilverStockKey('thousand_stock');
              setSilverSoldKey('thousand_sold');
              setSilverPriceKey('thousand_price');
              setSilverCostKey('thousand');
              setFormData({ ...formData, grams: '1000' ,quantity:0 });
              break;
          }
          

    }
  const  goldStock = (data)=>{



    switch (data) {
        case 'type2':
          setGoldStockKey('half_stock');
          setGoldSoldKey('half_sold');
          setGoldPriceKey('half_price');
          setGoldCostKey('half');
          setFormData({ ...formData, grams: '0.5' ,quantity:0 });
          break;
        case 'type3':
          setGoldStockKey('one_stock');
          setGoldSoldKey('one_sold');
          setGoldPriceKey('one_price');
          setGoldCostKey('one');
          setFormData({ ...formData, grams: '1' ,quantity:0 });
          break;
        case 'type4':
          setGoldStockKey('two_stock');
          setGoldSoldKey('two_sold');
          setGoldPriceKey('two_price');
          setGoldCostKey('two');
          setFormData({ ...formData, grams: '2' ,quantity:0 });
          break;
        case 'type5':
          setGoldStockKey('five_stock');
          setGoldSoldKey('five_sold');
          setGoldPriceKey('five_price');
          setGoldCostKey('five');
          setFormData({ ...formData, grams: '5' ,quantity:0 });
          break;
        case 'type6':
          setGoldStockKey('ten_stock');
          setGoldSoldKey('ten_sold');
          setGoldPriceKey('ten_price');
          setGoldCostKey('ten');
          setFormData({ ...formData, grams: '10' ,quantity:0 });
          break;
        case 'type7':
          setGoldStockKey('twenty_stock');
          setGoldSoldKey('twenty_sold');
          setGoldPriceKey('twenty_price');
          setGoldCostKey('twenty');
          setFormData({ ...formData, grams: '20' ,quantity:0 });
          break;
        case 'type8':
          setGoldStockKey('thirty_stock');
          setGoldSoldKey('thirty_sold');
          setGoldPriceKey('thirty_price');
          setGoldCostKey('thirty');
          setFormData({ ...formData, grams: '30' ,quantity:0 });
          break;
        case 'type9':
          setGoldStockKey('fifty_stock');
          setGoldSoldKey('fifty_sold');
          setGoldPriceKey('fifty_price');
          setGoldCostKey('fifty');
          setFormData({ ...formData, grams: '50' ,quantity:0 });
          break;
        case 'type10':
          setGoldStockKey('hundred_stock');
          setGoldSoldKey('hundred_sold');
          setGoldPriceKey('hundred_price');
          setGoldCostKey('hundred');
          setFormData({ ...formData, grams: '100' ,quantity:0 });
          break;
      }
      




  }




  const quantitychange = () => {
    let amount = 0;
  
    if (formData.stockType == 'gold') {
      amount = (GoldBarPrice[goldCostKey] * formData.quantity);
      console.log("gold", goldCostKey, amount, "yes");
    } else if (formData.stockType == 'gold-coin') {
      amount = (GoldCoinPrice[goldCostKey] * formData.quantity);
      console.log("gold-coin", goldCostKey, amount);
    } else if (formData.stockType == 'silver') {
      amount = (SilverBarPrice[silverCostKey] * formData.quantity);
      console.log("silver", silverCostKey, amount);
    } else if (formData.stockType == 'silver-coin') {
      amount = (SilverCoinPrice[silverCostKey] * formData.quantity);
      console.log("silver-coin", silverCostKey, amount);
    }
  
  
    setFormData({ ...formData, amount });

  }
const callback = (data,quantity)=>{
   
        
    
    for (let index = 0; index < data.length; index++) {
        const element = data[index];

       
        if (formData.stockType == 'gold' || formData.stockType == 'gold-coin') {

            if (((parseInt(element[goldstockKey]) - (parseInt(element[goldsoldKey])))) >= parseInt(quantity)) {

                element[goldsoldKey] = (parseInt(element[goldsoldKey]) + parseInt(quantity));
                formData.previousAmount = (formData.previousAmount +((parseInt(element[goldpriceKey]) / parseFloat(element[goldstockKey])) * parseInt(quantity)));
                setSalesuess(true);
                console.log("element",element.date);
                formData.byuDate = element.date;
                index=data.length;
              

            }
            else  if (((parseInt(element[goldstockKey]) - (parseInt(element[goldsoldKey])))) != 0) {

                var q = quantity - (parseInt(element[goldstockKey]) - parseInt(element[goldsoldKey]));
                element[goldsoldKey] = parseInt(element[goldstockKey]);

                formData.previousAmount = (formData.previousAmount +((parseInt(element[goldpriceKey]) / parseFloat(element[goldstockKey])) * parseInt(element[goldstockKey])));
                
                callback(data,q);
                index=data.length;

            }
        }
        else if (formData.stockType == 'silver' || formData.stockType == 'silver-coin') {
          
            if (((parseInt(element[silverstockKey]) - (parseInt(element[silversoldKey])))) >= parseInt(quantity)) {

                element[silversoldKey] = (parseInt(element[silversoldKey]) + parseInt(quantity));
                formData.previousAmount = (formData.previousAmount +((parseInt(element[silverpriceKey]) / parseFloat(element[silverstockKey])) * parseInt(quantity)));
                setSalesuess(true);
                formData.byuDate = element.date;

                index=data.length;
              

            }
            else  if (((parseInt(element[silverstockKey]) - (parseInt(element[silversoldKey])))) != 0) {

                var q = quantity - (parseInt(element[silverstockKey]) - parseInt(element[silversoldKey]));
                element[silversoldKey] = parseInt(element[silverstockKey]);

                formData.previousAmount = (formData.previousAmount +((parseInt(element[silverpriceKey]) / parseFloat(element[silverstockKey])) * parseInt(element[silverstockKey])));

                callback(data,q);
                index=data.length;

            }

        }
       
    }
    data.map((item)=>{
      let urll;
      if (formData.stockType == 'gold') {
        urll='https://api.dremerz.net/api/kg999GoldBarStockList/';
      } else if (formData.stockType == 'gold-coin') {
        urll='https://api.dremerz.net/api/kg999GoldCoinStockList/';
      }
      else if (formData.stockType == 'silver') {
        urll='https://api.dremerz.net/api/kg999SilverBarStockList/';
      }
      else if (formData.stockType == 'silver-coin') {
        urll='https://api.dremerz.net/api/kg999SilverCoinStockList/';
      }
        axios.put(urll+item.id+'/',item)
    })
    const totalAmount = ((formData.amount - formData.discount) * 0.03 + formData.amount - formData.discount).toFixed(2);
                   
    
        console.log("formData",formData);
        axios.put('https://api.dremerz.net/api/kg999SquareOffAmount/1/',{invoice:formData.invoice})
        
        axios.post('https://api.dremerz.net/api/kg999ClientList/', formData)
   
        setPaid(0)
        setBalance(0)
setFormData({...formData,amount:0,discount:0,totalAmount:0,previousAmount:0,quantity:0,invoice:(parseInt(formData.invoice)+1)});
        alert("sale successfull");
  
}
  const   postData = () => {

    setFormData({...formData, previousAmount:0});

setError('');    
setSalesuess(false);


if (formData.stockType == 'gold') {
  axios.get('https://api.dremerz.net/api/kg999GoldBarStockList/')
  .then((response) => {
    console.log(response.data)
    console.log(goldstockKey)
     var filteredData = response.data.filter((item)=>{

        if (formData.stockType == 'gold' || formData.stockType == 'gold-coin') {
       
        
            return (
              !(
                ( parseInt( item[goldstockKey] ) - parseInt( item[goldsoldKey])) < 0  ||
                item[goldstockKey] == '' ||
                  item[goldsoldKey] == '' ||
                  item[goldpriceKey] == ''
                  )
                
            );
          
        }
        
        else if (formData.stockType == 'silver' || formData.stockType == 'silver-coin') {
         
          
            return (
              !(
               ( parseInt(item[silverstockKey]) -  parseInt(item[silversoldKey])) < 0  ||
                item[silverstockKey] == '' ||
                  item[silversoldKey] == '' ||
                  item[silverpriceKey] == '')
                
            );
        
        }
       
       
        }   
      )
      

      console.log("filteredData",filteredData)

var totalstock=0;

       filteredData.map((item)=>{
        if (formData.stockType=='gold' || formData.stockType=='gold-coin') {
            
            totalstock=(totalstock+(parseInt(item[goldstockKey])-parseInt(item[goldsoldKey])));
            

        }
        else if (formData.stockType=='silver' || formData.stockType=='silver-coin')
        {
            
            totalstock=(totalstock+(parseInt(item[silverstockKey])-parseInt(item[silversoldKey])));
        }
        

       })
      setStockData(filteredData); 




      if (totalstock >= formData.quantity) {
        console.log("totalstock -1",totalstock)

        callback(filteredData,formData.quantity)
      }
      else{
        setError("not enough stock");
setSalesuess(false);

        console.log("totalstock -2",totalstock)

            alert("not enough stock")

      }
     



      console.log("filteredData1",filteredData)
    })
    .catch((error) => {
      console.error('Error fetching data from the API:', error);
    });
  
}

else if (formData.stockType == 'gold-coin') {
  axios.get('https://api.dremerz.net/api/kg999GoldCoinStockList/')
  .then((response) => {
    console.log(response.data)
    console.log(goldstockKey)
     var filteredData = response.data.filter((item)=>{

        if (formData.stockType == 'gold' || formData.stockType == 'gold-coin') {
       
        
            return (
              !(
                ( parseInt( item[goldstockKey] ) - parseInt( item[goldsoldKey])) < 0  ||
                item[goldstockKey] == '' ||
                  item[goldsoldKey] == '' ||
                  item[goldpriceKey] == ''
                  )
                
            );
          
        }
        
        else if (formData.stockType == 'silver' || formData.stockType == 'silver-coin') {
         
          
            return (
              !(
               ( parseInt(item[silverstockKey]) -  parseInt(item[silversoldKey])) < 0  ||
                item[silverstockKey] == '' ||
                  item[silversoldKey] == '' ||
                  item[silverpriceKey] == '')
                
            );
        
        }
       
       
        }   
      )
      

      console.log("filteredData",filteredData)

var totalstock=0;

       filteredData.map((item)=>{
        if (formData.stockType=='gold' || formData.stockType=='gold-coin') {
            
            totalstock=(totalstock+(parseInt(item[goldstockKey])-parseInt(item[goldsoldKey])));
            

        }
        else if (formData.stockType=='silver' || formData.stockType=='silver-coin')
        {
            
            totalstock=(totalstock+(parseInt(item[silverstockKey])-parseInt(item[silversoldKey])));
        }
        

       })
      setStockData(filteredData); 




      if (totalstock >= formData.quantity) {
        console.log("totalstock -1",totalstock)

        callback(filteredData,formData.quantity)
      }
      else{
        setError("not enough stock");
setSalesuess(false);

        console.log("totalstock -2",totalstock)

            alert("not enough stock")

      }
     



      console.log("filteredData1",filteredData)
    })
    .catch((error) => {
      console.error('Error fetching data from the API:', error);
    });
}

else if (formData.stockType == 'silver') {
  axios.get('https://api.dremerz.net/api/kg999SilverBarStockList/')
  .then((response) => {
    console.log(response.data)
    console.log(goldstockKey)
     var filteredData = response.data.filter((item)=>{

        if (formData.stockType == 'gold' || formData.stockType == 'gold-coin') {
       
        
            return (
              !(
                ( parseInt( item[goldstockKey] ) - parseInt( item[goldsoldKey])) < 0  ||
                item[goldstockKey] == '' ||
                  item[goldsoldKey] == '' ||
                  item[goldpriceKey] == ''
                  )
                
            );
          
        }
        
        else if (formData.stockType == 'silver' || formData.stockType == 'silver-coin') {
         
          
            return (
              !(
               ( parseInt(item[silverstockKey]) -  parseInt(item[silversoldKey])) < 0  ||
                item[silverstockKey] == '' ||
                  item[silversoldKey] == '' ||
                  item[silverpriceKey] == '')
                
            );
        
        }
       
       
        }   
      )
      

      console.log("filteredData",filteredData)

var totalstock=0;

       filteredData.map((item)=>{
        if (formData.stockType=='gold' || formData.stockType=='gold-coin') {
            
            totalstock=(totalstock+(parseInt(item[goldstockKey])-parseInt(item[goldsoldKey])));
            

        }
        else if (formData.stockType=='silver' || formData.stockType=='silver-coin')
        {
            
            totalstock=(totalstock+(parseInt(item[silverstockKey])-parseInt(item[silversoldKey])));
        }
        

       })
      setStockData(filteredData); 




      if (totalstock >= formData.quantity) {
        console.log("totalstock -1",totalstock)

        callback(filteredData,formData.quantity)
      }
      else{
        setError("not enough stock");
setSalesuess(false);

        console.log("totalstock -2",totalstock)

            alert("not enough stock")

      }
     



      console.log("filteredData1",filteredData)
    })
    .catch((error) => {
      console.error('Error fetching data from the API:', error);
    });
}

else if (formData.stockType == 'silver-coin') {
  axios.get('https://api.dremerz.net/api/kg999SilverCoinStockList/')
  .then((response) => {
    console.log(response.data)
    console.log(goldstockKey)
     var filteredData = response.data.filter((item)=>{

        if (formData.stockType == 'gold' || formData.stockType == 'gold-coin') {
       
            return (
              !(
                ( parseInt( item[goldstockKey] ) - parseInt( item[goldsoldKey])) < 0  ||
                item[goldstockKey] == '' ||
                  item[goldsoldKey] == '' ||
                  item[goldpriceKey] == ''
                  )
                
            );
          
        }
        
        else if (formData.stockType == 'silver' || formData.stockType == 'silver-coin') {
         
          
            return (
              !(
               ( parseInt(item[silverstockKey]) -  parseInt(item[silversoldKey])) < 0  ||
                item[silverstockKey] == '' ||
                  item[silversoldKey] == '' ||
                  item[silverpriceKey] == '')
                
            );
        
        }
       
       
        }   
      )
      

      console.log("filteredData",filteredData)

var totalstock=0;

       filteredData.map((item)=>{
        if (formData.stockType=='gold' || formData.stockType=='gold-coin') {
            
            totalstock=(totalstock+(parseInt(item[goldstockKey])-parseInt(item[goldsoldKey])));
            

        }
        else if (formData.stockType=='silver' || formData.stockType=='silver-coin')
        {
            
            totalstock=(totalstock+(parseInt(item[silverstockKey])-parseInt(item[silversoldKey])));
        }
        

       })
      setStockData(filteredData); 




      if (totalstock >= formData.quantity) {
        console.log("totalstock -1",totalstock)

        callback(filteredData,formData.quantity)
      }
      else{
        setError("not enough stock");
setSalesuess(false);

        console.log("totalstock -2",totalstock)

            alert("not enough stock")

      }
     



      console.log("filteredData1",filteredData)
    })
    .catch((error) => {
      console.error('Error fetching data from the API:', error);
    });
}






        

       
    }




    const navigate = useNavigate();

    useEffect(()=>{
      function handleKeyDown(event) {
        if (event.key == 'F2' || event.key == '-') {
          navigate('/newSale');
        }
      }
      window.addEventListener('keydown', handleKeyDown);
   
      axios.get('https://api.dremerz.net/api/kg999SquareOffAmount/1/')
      .then((response) => {
        setFormData({ ...formData,invoice: (parseInt(response.data.invoice)+1 )});
      })


        axios.get('https://api.dremerz.net/api/kg999GoldBarCost/1/')
        .then((response) => {
            setGoldBarPrice(response.data)

        })
        .catch((error) => {
          console.error('Error fetching data from the API:', error);
        });

        axios.get('https://api.dremerz.net/api/kg999GoldCoinCost/1/')
        .then((response) => {
            setGoldCoinPrice(response.data)
        })
        .catch((error) => {
          console.error('Error fetching data from the API:', error);
        });

        axios.get('https://api.dremerz.net/api/kg999SilverBarCost/1/')
        .then((response) => {
            setSilverBarPrice(response.data)
            console.log("SilverBarPrice",response.data)
        })
        .catch((error) => {
          console.error('Error fetching data from the API:', error);
        });

        axios.get('https://api.dremerz.net/api/kg999SilverCoinCost/1/')
        .then((response) => {
            setSilverCoinPrice(response.data)
        })
        .catch((error) => {
          console.error('Error fetching data from the API:', error);
        });





        if (formData.stockType == 'gold') {
          setApiUrl('https://api.dremerz.net/api/kg999GoldBarStockList/');
        
        } 
        
        else if (formData.barCoinType == 'gold-coin') {
          setApiUrl('https://api.dremerz.net/api/kg999GoldCoinStockList/');
        }
  
         else if (formData.barCoinType == 'silver') {
          setApiUrl('https://api.dremerz.net/api/kg999SilverBarStockList/');
        }
           else if (formData.barCoinType == 'silver-coin') {
            setApiUrl('https://api.dremerz.net/api/kg999SilverCoinStockList/');
          
        }
    },
     [formData]);

  return (
    <div>
      <h1 style={{ color: 'black', fontWeight: 'bold' }}>CREATE NEW SALE</h1>
      <br />

      <div className="col-md-6 grid-margin stretch-card" style={{minWidth:"100%"}}>
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">SALE FORM</h4>
            <form style={{display:"flex",justifyContent:"center",flexWrap:"wrap",gap:20}} >
              <div className="form-group" style={{width:"250px"}}>
                <label  style={{color:'black'}} htmlFor="name">Name</label>
                <input style={{color:"black"}}
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </div>
      
              <div className="form-group" style={{width:"250px"}}>
                <label  style={{color:'black'}} htmlFor="address">Address</label>
                <input style={{color:"black"}}
                  type="text"
                  className="form-control"
                  id="address"
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group" style={{width:"250px"}}>
                <label  style={{color:'black'}} htmlFor="aadharotpan">Aadhar / PAN ( OPTIONAL )</label>
                <input style={{color:"black"}}
                  type="text"
                  className="form-control"
                  id="aadharotpan"
                  name="aadharotpan"
                  value={formData.aadharotpan}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group" style={{width:"250px"}}>
                <label  style={{color:'black'}} htmlFor="number">Mobile Number</label>
                <input style={{color:"black"}}
                  type="text"
                  className="form-control"
                  id="number"
                  name="number"
                  value={formData.number}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group" style={{ width: "250px" }}>
  <label style={{ color: 'black' }} htmlFor="stockType">
    Stock Type
  </label>
  <select
    style={{ color: 'black' }}
    className="form-control"
    id="stockType"
    name="stockType"
    value={formData.stockType}
    onChange={(e) => setFormData({ ...formData, stockType: e.target.value, quantity:0 })}
  >
    <option value="gold">Gold Bar</option>
    <option value="silver">Silver Bar</option>
    <option value="gold-coin">Gold Coin</option>
    <option value="silver-coin">Silver Coin</option>
  </select>
</div>


{
    formData.stockType == 'gold' && (
        <div className="form-group" style={{width:"250px"}}>
        <label  style={{color:'black'}} htmlFor="goldBarType">GOLD BAR ( GRAM )</label>
        <select style={{color:"black"}}
          className="form-control"
          id="goldBarType"
          name="goldBarType"
          onChange={(e)=>goldStock(e.target.value)}

        >
          <option value="type1">SELECT GOLD BAR ( GRAM )</option>
            <option value="type2">HALF GRAM</option>
          <option value="type3">ONE GRAM</option>
          <option value="type4">TWO GRAM</option>
          <option value="type5">FIVE GRAM</option>
          <option value="type6">TEN GRAM</option>
          <option value="type7">TWENTY GRAM</option>
          <option value="type8">THIRTY GRAM</option>
          <option value="type9">FIFTY GRAM</option>
          <option value="type10">HUNDRED GRAM</option>
        </select>
      </div>
    )
}

{
    formData.stockType == 'gold-coin' && (
        <div className="form-group" style={{width:"250px"}}>
        <label  style={{color:'black'}} htmlFor="goldBarType">GOLD GOLD COIN ( GRAM )</label>
        <select style={{color:"black"}}
          className="form-control"
          id="goldBarType" 
          name="goldBarType"
          onChange={(e)=>goldStock(e.target.value)}
        >
          <option value="type1">SELECT COIN ( GRAM )</option>
            <option value="type2">HALF GRAM</option>
          <option value="type3">ONE GRAM</option>
          <option value="type4">TWO GRAM</option>
          <option value="type5">FIVE GRAM</option>
          <option value="type6">TEN GRAM</option>
          <option value="type7">TWENTY GRAM</option>
          <option value="type8">THIRTY GRAM</option>
          <option value="type9">FIFTY GRAM</option>
          <option value="type10">HUNDRED GRAM</option>
        </select>
      </div>
    )
}

{
    formData.stockType == 'silver' && (
        <div className="form-group" style={{width:"250px"}}>
        <label  style={{color:'black'}} htmlFor="goldBarType">SILVER BAR ( GRAM )</label>
        <select style={{color:"black"}}
          className="form-control"
          id="goldBarType"
          name="goldBarType"
          onChange={(e)=>silverStock(e.target.value)}
        >
          <option value="type1">SELECT SILVER BAR ( GRAM )</option>
          <option value="type3">TEN GRAM</option>
            <option value="type4">TWENTY GRAM</option>
            <option value="type5">FIFTY GRAM</option>
            <option value="type6">HUNDRED GRAM</option>
            <option value="type7">TWO FIFTY GRAM</option>
            <option value="type8">FIVE HUNDRED GRAM</option>
            <option value="type9">ONE KG</option>
        </select>
      </div>
    )
}
           

{
    formData.stockType == 'silver-coin' && (
        <div className="form-group" style={{width:"250px"}}>
        <label  style={{color:'black'}} htmlFor="goldBarType">SILVER COIN ( GRAM )</label>
        <select style={{color:"black"}}
          className="form-control"
          id="goldBarType"
          name="goldBarType"
          onChange={(e)=>silverStock(e.target.value)}
        >
          <option value="type1">SELECT SILVER COIN ( GRAM )</option>
          <option value="type3">TEN GRAM</option>
            <option value="type4">TWENTY GRAM</option>
            <option value="type5">FIFTY GRAM</option>
            <option value="type6">HUNDRED GRAM</option>
            <option value="type7">TWO FIFTY GRAM</option>
            <option value="type8">FIVE HUNDRED GRAM</option>
            <option value="type9">ONE KG</option>
        </select>
      </div>
    )
}
       

              <div className="form-group" style={{width:"250px"}}>
                <label  style={{color:'black'}} htmlFor="quantity">Quantity</label>
                <input style={{color:"black"}}
                  type="number"
                  className="form-control"
                  id="quantity"
                  name="quantity"
                  value={formData.quantity}
                  onChange={(e)=>{
                    formData.quantity=e.target.value;
                    quantitychange()
                  }}
                />
              </div>




    
              <div className="form-group" style={{width:"250px"}}>
                <label  style={{color:'black'}} htmlFor="amount">Gross Amount</label>
                <input style={{color:"black"}}
                  type="number"
                  className="form-control"
                  disabled
                  id="amount"
                  name="amount"
                  value={formData.amount}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group" style={{width:"250px"}}>
                <label  style={{color:'black'}} htmlFor="discount">Discount</label>
                <input style={{color:"black"}}
                  type="number"
                  className="form-control"
                  id="discount"
                  name="discount"
                  onChange={handleInputChange}
                />
              </div>

              <div className="form-group" style={{width:"250px"}}>
                <label  style={{color:'black'}} htmlFor="discount">Total Amount</label>
                <input style={{color:"black"}}
                  type="number"
                  className="form-control"
                  id="discount"
                  name="discount"
                  disabled
                  value={formData.amount- formData.discount}
                />
              </div>
              <div className="form-group" style={{width:"250px"}}>
                <label  style={{color:'black'}} htmlFor="totalAmount">CGST ( 1.5% )</label>
                <input style={{color:"black"}}
                disabled
                  type="number"
                  className="form-control"
                  id="totalAmount"
                  
                  name="totalAmount"
                  value={((formData.amount- formData.discount )*0.015 ).toFixed(2)}
                />
              </div>

              <div className="form-group" style={{width:"250px"}}>
                <label  style={{color:'black'}} htmlFor="totalAmount">SGST ( 1.5% )</label>
                <input style={{color:"black"}}
                  type="number"
                  className="form-control"
                  id="totalAmount"
                  name="totalAmount"
                  disabled
                  value={((formData.amount- formData.discount )*0.015 ).toFixed(2)}
                />
              </div>


              <div className="form-group" style={{width:"250px"}}>
                <label  style={{color:'black'}} htmlFor="totalAmount">Net Amount</label>
                <input style={{color:"black"}}
                  type="number"
                  className="form-control"
                  id="totalAmount"
                  disabled
                  name="totalAmount"
                  onChange={(e) => {
                    const totalAmount = ((formData.amount - formData.discount) * 0.03 + formData.amount - formData.discount).toFixed(0);
                    setFormData({ ...formData, totalAmount });
                  }}
                  
                  value={(((formData.amount- formData.discount )*0.03 + (formData.amount- formData.discount)).toFixed(0))}
                />
              </div>

              <div className="form-group" style={{width:"250px"}}>
                <label  style={{color:'black'}} htmlFor="totalAmount">Amount Paid</label>
                <input style={{color:"black"}}
                  type="number"
                  className="form-control"
                  id="paid"
                  name="pad"
                  onChange={(e) => {
                    setPaid(e.target.value);
                    
                  }}
                  
                 
                />
              </div>

              <div className="form-group" style={{width:"250px"}}>
                <label  style={{color:'black'}} htmlFor="totalAmount">Balance</label>
                <input style={{color:"black"}}
                  type="balance"
                  className="form-control"
                  id="balance"
                  disabled
                  name="balance"
                  value={
                    paid !== 0?((formData.amount- formData.discount )*0.03 + (formData.amount- formData.discount)).toFixed(0) - paid:0
                  }
                />
              </div>


              
      
           
            </form>

            <br></br>
            <div className="form-group" style={{width:"250px"}}>
                <label  style={{color:'black'}} htmlFor="paymentMode">Payment Mode</label>
                <select style={{color:"black"}}
          className="form-control"
          id="paymentMode"
          name="paymentMode"
          onChange={(e)=>setFormData({...formData,paymentMode:e.target.value})}
        >
          <option value="Cash">Cash</option>
          <option value="GPay">GPay</option>
          <option value="Paytm">Paytm</option>
          <option value="PhonePe">PhonePe</option>

        </select>
              </div>

            <br></br>
            {
            error!=""&&( <div style={{color:"red",marginLeft:"10px"}}>{error}</div>)
          }
          {
            salesuess&&( <div style={{color:"green",marginLeft:"10px"}}>sale successfull</div>)
          }
          
            <br></br>
            <span onClick={()=>{
                postData()
              }} className="btn btn-primary mr-2">
                Submit
              </span>
              <span type="reset" className="btn btn-light">
                Reset
              </span>
          </div>
         
        </div>
      </div>
    </div>
  );
}
