import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

export default function AddNewUser() {
    const [name,setName] = useState("");
    const [password,setPassword] = useState ("");
    const navigate = useNavigate();
    useEffect(()=>{
      function handleKeyDown(event) {
        if (event.key == 'F2' || event.key == '-') {
          navigate('/newSale');
        }
      }
      window.addEventListener('keydown', handleKeyDown);
    },[])
  return (
    <div style={{backgroundColor:"",width:"100%",height:"100%",display:"flex",justifyContent:"center",padding:"20px"}}>
 <div className="col-md-6 grid-margin stretch-card" style={{height:"350px"}}>
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">ADD NEW USER!</h4>

            <form className="forms-sample" style={{color:"black",fontWeight:"bolder"}}>
              <div className="form-group" style={{width:"100"}}>
                <label htmlFor="name">USER NAME</label>
                <input
                style={{width:"100%",}}
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  value={name}
                 onChange={(e)=>setName(e.target.value)}

                  placeholder="USER NAME"
                />
              </div>

              <div className="form-group" style={{width:"100"}}>
                <label htmlFor="name">PASSWORD</label>
                <input
                style={{width:"100%"}}
                  type="text"
                  className="form-control"
                  id="name"

                  name="name"
                    value={password}
                    onChange={(e)=>setPassword(e.target.value)}

                  placeholder="PASSWORD"
                />
              </div>
       


              <span type="submit" className="btn btn-primary mr-2" 
              onClick={()=>{
                    if(name!='' && password!='')
                    {
                        axios.post('https://api.dremerz.net/api/kg999LoginData/',{name:name,password:password,auth:""})
                        .then((res)=>{
                            console.log(res)
                            alert("User Added Successfully")
                        setName('')
                        setPassword('')
                        })
                        .catch((err)=>{
                            console.log(err)
                            alert("Error Occured")
                        })
                    }
                    else{
                        alert("Please fill all the fields")
                    }
              }}
              >
                Submit
              </span>
             
             
            </form>
          </div>
        </div>
      </div>
    </div>
   
  )
}
