import React, { useEffect,useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';





export default function ViewLose() {

    function formatDate(inputDate) {
        const dateParts = inputDate.split('-');
        if (dateParts.length == 3) {
          const year = dateParts[0];
          const month = dateParts[1];
          const day = dateParts[2];
          return `${day}/${month}/${year}`;
        } else {
          return "Invalid date format";
        }
      }
    
    const [ data, setData ] = useState([]);
    const [uniqueMonths, setUniqueMonths] = useState([]); // Extract unique months
    const [uniqueYears, setUniqueYears] = useState([]); // Extract unique years
    const [ number,setNumber ] = useState('');
  
    const [selectedMonth, setSelectedMonth] = useState(''); // State for selected month
    const [selectedYear, setSelectedYear] = useState(''); // State for selected year
    const [selectedDate, setSelectedDate] = useState(''); // State for selected date







    const handleDelete = (index) => {
   
        axios
          .delete(`${"https://api.dremerz.net/api/kg999ClientList/"}${index}/`)
          .then((response) => {
            alert('Stock data deleted successfully!');
            setData(response.data);
          })
          .catch((error) => {
            alert('Error deleting the row:', error);
            console.error('Error deleting the row:', error);
          });
      };
    
    const [formData, setFormData] = useState({
        stockType: '',
        barCoinType: '',
        goldBarType: '',
        goldCoinType: '',
        silverBarType: '',
        silverCoinType: '',
        type: '',
      });

    const tableHeaderStyle = {
        background: '#f2f2f2',
        border: '1px solid #dddddd',
        padding: '8px',
        color: 'black',
        textAlign: 'left',
        fontWeight: 'bold',
      };
    
      const tableCellStyle = {
        border: '1px solid #dddddd',
        color: 'black',
        padding: '8px',
        textAlign: 'left',
      };
const [goldKey, setGoldKey ] = useState('');
const [silverKey, setSilverKey ] = useState('');
const [squareOffAmount,setSquareOffAmount] = useState(0);
const navigate = useNavigate();
const [stockName, setStockName] = useState([]);

useEffect(()=>{


  
  function handleKeyDown(event) {
    if (event.key == 'F2' || event.key == '-') {
      navigate('/newSale');
    }
  }
  window.addEventListener('keydown', handleKeyDown);
  axios.get('https://api.dremerz.net/api/kg999SquareOffAmount/1/')
  .then((response) => {
    setSquareOffAmount(response.data.amount);
  });
  axios
  .get('https://api.dremerz.net/api/kg999StockName/')
  .then((response) => {
    setStockName(response.data);
    console.log('Stock names fetched from the API:', response.data)
  })
  .catch((error) => {
    console.error('Error fetching stock names from the API:', error);
  });


  axios
  .get('https://api.dremerz.net/api/kg999ClientList/')
  .then((response) => {
   

    console.log("square off amount",squareOffAmount)
    let filteredData = response.data.filter((item) => (((parseInt(item.amount) - parseInt(item.discount))- parseInt(item.previousAmount) ) < 0) )
    

    if (formData.stockType == 'gold' ) {
      filteredData = filteredData.filter((item) => item.stockType == 'Gold Bar');
    } else if (formData.stockType == 'gold-coin' ) {
      filteredData = filteredData.filter((item) => item.stockType == 'Gold Coin');
    } 

    else if (formData.stockType == 'gold-coin916' ) {
      filteredData = filteredData.filter((item) => item.stockType == 'Gold Coin 916');
    } 
    
    
    else if (formData.stockType == 'silver' ) {
      filteredData = filteredData.filter((item) => item.stockType == 'Silver Bar');
    } else if (formData.stockType == 'silver-coin' ) {
      filteredData = filteredData.filter((item) => item.stockType == 'Silver Coin');
    } else {
      filteredData = filteredData;
    }

    if (formData.type !== '') {
      filteredData = filteredData.filter((item) => item.grams == formData.type);
    }
    if (selectedMonth !== '') {
      const separator = filteredData[0]?.date.includes('/') ? '/' : '-';
      filteredData = filteredData.filter((item) => item.date.split(separator)[1] == selectedMonth);
    }
   

    if (selectedYear !== '') {
      const separator = filteredData[0]?.date.includes('/') ? '/' : '-';
      filteredData = filteredData.filter((item) => item.date.split(separator)[2] == selectedYear);
    }

    if (number !== '') {
      filteredData = filteredData.filter((item) => item.number.toLowerCase().includes(number.toLowerCase()));
    }

    const months = filteredData.map((item) => item.date.split('/')[1]);
    const uniqueMonths = [...new Set(months)];
    setUniqueMonths(uniqueMonths);

    const years = filteredData.map((item) => item.date.split('/')[2]);
    const uniqueYears = [...new Set(years)];
    setUniqueYears(uniqueYears);

    setData(filteredData);
    console.log('Stock data fetched from the API:', filteredData)
  })
  .catch((error) => {
   
    console.error('Error fetching stock data from the API:', error);
  });

 

  
},[formData,selectedMonth,selectedYear,number,squareOffAmount])



  return (
    <div>
        <h1 style={{color:"black",fontWeight:"bolder"}}>VIEW LOSS!</h1>
        <br />



        <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 30 }}>
        <div className="form-group" style={{ width: '250px' }}>
          <label htmlFor="stockType" style={{ color: 'black' }}>
            SELECT STOCK ( GOLD / SILVER )
          </label>
          <select
            className="form-control"
            id="stockType"
            name="stockType"
            value={formData.stockType}
            onChange={(e) => setFormData({ ...formData, stockType: e.target.value })}
          >
            <option value="">ALL</option>

            <option value="gold">GOLD BAR</option>
            <option value="silver">SILVER BAR</option>
            <option value="gold-coin">GOLD COIN</option>
            <option value="gold-coin916">GOLD COIN 916</option>
            <option value="silver-coin">SILVER COIN</option>
          </select>
        </div>



        {formData.stockType == 'gold' &&
          (
          <div className="form-group" style={{ width: '250px' }}>
            <label htmlFor="goldBarType" style={{ color: 'black' }}>
              SELECT GOLD BAR ( GRAM )
            </label>
            <select
              className="form-control"
              id="goldBarType"
              name="goldBarType"
              value={formData.type}
              onChange={(e) => setFormData({ ...formData, type: e.target.value })}
            >
              <option value="">ALL</option>

             {
                stockName.filter((item) => item.stockname == 'Gold Bar').map((item) => (
                  <option value={item.name} key={item.id}>{item.name} GRAM</option>
                ))

             }
            </select>
          </div>
        )}


{formData.stockType == 'gold-coin' &&  
          (
          <div className="form-group" style={{ width: '250px' }}>
            <label htmlFor="goldCoinType" style={{ color: 'black' }}>
              SELECT GOLD COIN ( GRAM )
            </label>
            <select
              className="form-control"
              id="goldCoinType"
              name="goldCoinType"
              value={formData.type}
              onChange={(e) => setFormData({ ...formData, type: e.target.value })}
            >
              <option value="">ALL</option>

             {
                stockName.filter((item) => item.stockname == 'Gold Coin').map((item) => (
                  <option value={item.name} key={item.id}>{item.name} GRAM</option>
                ))

             }
            </select>
          </div>
        )}


{formData.stockType == 'gold-coin916' &&  
          (
          <div className="form-group" style={{ width: '250px' }}>
            <label htmlFor="goldCoinType" style={{ color: 'black' }}>
              SELECT GOLD COIN 916 ( GRAM )
            </label>
            <select
              className="form-control"
              id="goldCoinType"
              name="goldCoinType"
              value={formData.type}
              onChange={(e) => setFormData({ ...formData, type: e.target.value })}
            >
              <option value="">ALL</option>

             {
                stockName.filter((item) => item.stockname == 'Gold Coin 916').map((item) => (
                  <option value={item.name} key={item.id}>{item.name} GRAM</option>
                ))

             }
            </select>
          </div>
        )}

{formData.stockType == 'silver' && 
        (
          <div className="form-group" style={{ width: '300px' }}>
            <label htmlFor="silverBarType" style={{ color: 'black' }}>
              SELECT SILVER  BAR ( GRAM )
            </label>
            <select
              className="form-control"
              id="silverBarType"
              name="silverBarType"
              value={formData.type}
              onChange={(e) => setFormData({ ...formData, type: e.target.value })}
            >
              <option value="">ALL</option>

              {
                stockName.filter((item) => item.stockname == 'Silver Bar').map((item) => (
                  <option value={item.name} key={item.id}>{item.name} GRAM</option>
                ))

             }
            </select>
          </div>
        )}

{formData.stockType == 'silver-coin' && 
        (
          <div className="form-group" style={{ width: '300px' }}>
            <label htmlFor="silverCoinType" style={{ color: 'black' }}>
              SELECT SILVER  COIN ( GRAM )
            </label>
            <select
              className="form-control"
              id="silverCoinType"
              name="silverCoinType"
              value={formData.type}
              onChange={(e) => setFormData({ ...formData, type: e.target.value })}
            >
              <option value="">ALL</option>

              {
                stockName.filter((item) => item.stockname == 'Silver Coin').map((item) => (
                  <option value={item.name} key={item.id}>{item.name} GRAM</option>
                ))

             }
            </select>
          </div>
        )}
       
<div className="form-group" style={{ width: '200px' }}>
  <label htmlFor="monthFilter" style={{ color: 'black' }}>
    Filter by Month
  </label>
  <select
    className="form-control"
    id="monthFilter"
    name="monthFilter"
    value={selectedMonth}
    onChange={(e) => setSelectedMonth(e.target.value)}
  >
    <option value="">All</option>
    {uniqueMonths.map((month) => (
      <option key={month} value={month}>
        {month}
      </option>
    ))}
  </select>
</div>


<div className="form-group" style={{ width: '200px' }}>
  <label htmlFor="yearFilter" style={{ color: 'black' }}>
    Filter by Year
  </label>
  <select
    className="form-control"
    id="yearFilter"
    name="yearFilter"
    value={selectedYear}
    onChange={(e) => setSelectedYear(e.target.value)}
  >
    <option value="">All</option>
    {uniqueYears.map((year) => (
      <option key={year} value={year}>
        {year}
      </option>
  )  )}
  </select>
</div>


{/* <div className="form-group" style={{ width: '200px' }}>
  <label htmlFor="dateFilter" style={{ color: 'black' }}>
    Filter by Date
  </label>
  <input style={{height:33}}
    type="date"
    className="form-control"
    id="dateFilter"
    name="dateFilter"
    value={selectedDate}
    onChange={(e) => setSelectedDate(e.target.value)}
  />
</div> */}

      </div>








<br />

<div style={{display:"flex",flexWrap:"wrap",justifyContent:"space-between"}}>

<table style={{ width: '47%', borderCollapse: 'collapse' }}>
    <thead>
      <tr>
        <th style={tableHeaderStyle}>Buy Date</th>
        <th style={tableHeaderStyle}>Stock Type</th>
        <th style={tableHeaderStyle}>Stock Grams</th>
        <th style={tableHeaderStyle}>Stock Quantity</th>
        <th style={tableHeaderStyle}>Total Stock Grams</th>

        {/* <th style={tableHeaderStyle}>CGST + SGST</th> */}
        <th style={tableHeaderStyle}>Buy Amount</th>
      </tr>
    </thead>
  
  {

    data.length == 0 ?  <tbody><tr><td colSpan="7" style={{...tableCellStyle,textAlign:"center",color:"black",fontWeight:"bolder"}}>NO DATA FOUND</td></tr></tbody> :  
    <tbody>
    {data.slice().reverse().map((item, index) => (
    <tr key={index}>
      <td style={tableCellStyle}>{item.date}</td>
      <td style={tableCellStyle}>{item.stockType == 'Gold Bar'? 'GOLD BAR' : item.stockType == 'Gold Coin' ? 'GOLD COIN' : item.stockType == 'Silver Bar' ? 'SILVER BAR' : 'SILVER COIN'
      }</td>

      <td style={tableCellStyle}>{parseFloat(item.grams ).toFixed(3)+ ' GRAMS'}</td>
      <td style={tableCellStyle}>{item.quantity}</td>
      <td style={tableCellStyle}>{(parseFloat(item.grams)* parseFloat(item.quantity)).toFixed(3) + ' GRAMS'}</td>

      {/* <td style={tableCellStyle}>{((parseFloat(item.amount)-parseFloat(item.discount))*0.03).toFixed(2)}</td> */}

<td style={tableCellStyle}>{parseFloat(item.previousAmount).toFixed(2)}</td>




     </tr>
    ))}







<tr>
        <td colSpan="5" style={{...tableCellStyle,textAlign:"center",color:"black",fontWeight:"bolder"}}><span style={{color:"violet"}}>AVERAGE</span> </td>








<td style={{ ...tableCellStyle, color: "violet" }}>
  {(data.reduce((sum, item) => {
    const currentAmount =
        parseFloat(item.previousAmount) ;
    return sum + currentAmount;
  },0)
  /
  data.reduce((sum, item) => {
    return sum + parseInt(item.quantity)

  },0)).toFixed(2)

  }
</td>

      </tr>

      <tr>
        <td colSpan="5" style={{...tableCellStyle,textAlign:"center",color:"black",fontWeight:"bolder"}}><span style={{color:"green"}}> TOTAL BUY AMOUNT</span></td>








<td style={{ ...tableCellStyle, color: "green" }}>
  {data.reduce((sum, item) => {
    const currentAmount =
        parseFloat(item.previousAmount) ;
    return sum + currentAmount;
  },0).toFixed(2)
 

  }
</td>

      </tr>

    </tbody>
  }
  </table>

        <table style={{ width: '47%', borderCollapse: 'collapse' }}>
    <thead>
      <tr>
        <th style={tableHeaderStyle}>Sold Date</th>
        <th style={tableHeaderStyle}>Stock Type</th>
        <th style={tableHeaderStyle}>Stock Grams</th>
        <th style={tableHeaderStyle}>Stock Quantity</th>
        <th style={tableHeaderStyle}>Total Stock Grams</th>

        {/* <th style={tableHeaderStyle}>CGST + SGST</th> */}
        <th style={tableHeaderStyle}>Sold Amount</th>
        <th style={tableHeaderStyle}>TOTAL LOSS</th>
      </tr>
    </thead>
    
    {
      data.length == 0 ?  <tbody><tr><td colSpan="7" style={{...tableCellStyle,textAlign:"center",color:"black",fontWeight:"bolder"}}>NO DATA FOUND</td></tr></tbody> :  
      <tbody>
    {data.slice().reverse().map((item, index) => (
    <tr key={index}>
      <td style={tableCellStyle}>{item.date}</td>
      <td style={tableCellStyle}>{item.stockType == 'Gold Bar'? 'GOLD BAR' : item.stockType == 'Gold Coin' ? 'GOLD COIN' :  item.stockType =="Gold Coin 916"?"GOLD COIN 916": item.stockType == 'Silver Bar' ? 'SILVER BAR' : 'SILVER COIN'
      }</td> <td style={tableCellStyle}>{parseFloat(item.grams ).toFixed(3)+ ' GRAM'}</td>
      <td style={tableCellStyle}>{item.quantity}</td>
      <td style={tableCellStyle}>{(parseFloat(item.grams)* parseFloat(item.quantity)).toFixed(3) + ' GRAM'}</td>

      {/* <td style={tableCellStyle}>{((parseFloat(item.amount)-parseFloat(item.discount))*0.03).toFixed(2)}</td> */}

<td style={tableCellStyle}>
  {(
    (parseFloat(item.amount) - parseFloat(item.discount)) 
  ).toFixed(2)}
</td>


<td style={tableCellStyle}>
    
  {(((
    (parseFloat(item.amount) - parseFloat(item.discount)) 
  ).toFixed(2)) - (parseFloat(item.previousAmount)).toFixed(2)).toFixed(2)
  
  }
</td>


     </tr>
    ))}

<tr>
        <td colSpan="5" style={{...tableCellStyle,textAlign:"center",color:"black",fontWeight:"bolder"}}><span style={{color:"violet"}}>AVERAGE</span> </td>











<td style={{ ...tableCellStyle, color: "violet" }}>
  {(data.reduce((sum, item) => {
    const currentAmount =
      ((parseFloat(item.amount) - parseFloat(item.discount)) ) ;
    return sum + currentAmount;
  }
  , 0).toFixed(2)
  /
  data.reduce((sum, item) => {
    return sum + parseInt(item.quantity)

  },0)).toFixed(0)
  

  }
</td>


<td style={{ ...tableCellStyle, color: "violet" }}>
  {(data.reduce((sum, item) => {
    const currentAmount =
      (((((parseFloat(item.amount) - parseFloat(item.discount)) ) /
        parseInt(item.quantity)).toFixed(2) -
      parseFloat(item.previousAmount) / parseInt(item.quantity)) * parseInt(item.quantity).toFixed(2)) ;
    return sum + currentAmount;
  }, 0).toFixed(2)
  
  
  
  /
  
  data.reduce((sum, item) => {
    return sum + parseInt(item.quantity)

  },0)).toFixed(0)}
</td>



      </tr>



      <tr>
        <td colSpan="5" style={{...tableCellStyle,textAlign:"center",color:"black",fontWeight:"bolder"}}><span style={{color:"green"}}> TOTAL LOSS</span></td>










<td style={{ ...tableCellStyle, color: "green" }}>
  {(data.reduce((sum, item) => {
    const currentAmount =
      ((parseFloat(item.amount) - parseFloat(item.discount)) ) ;
    return sum + currentAmount;
  }
  , 0)).toFixed(2)

  

  }
</td>


<td style={{ ...tableCellStyle, color: "red" }}>
  {(data.reduce((sum, item) => {
    const currentAmount =
      (((((parseFloat(item.amount) - parseFloat(item.discount)) ) /
        parseInt(item.quantity)).toFixed(2) -
      parseFloat(item.previousAmount) / parseInt(item.quantity)) * parseInt(item.quantity).toFixed(2)) ;
    return sum + currentAmount;
  }, 0)).toFixed(2)
  
  
}
</td>



      </tr>
    </tbody>
    }
  </table>

</div>

<br />
<br />
<br />

    </div>
  )
}



// parseInt(
       
//   ((((
//       (parseFloat(item.amount) - parseFloat(item.discount)) 
//     ).toFixed(2)))/ parseInt(item.quantity)).toFixed(2 )) 

//     - parseInt((parseFloat(item.previousAmount)/parseInt(item.quantity)).toFixed(2))< parseInt(squareOffAmount) ||
// (selectedDate && item.date !== formatDate(selectedDate)) 
// )

// );