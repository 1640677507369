import React, { useEffect, useState } from 'react';
import axios from 'axios';

export default function AddGoldBarStock(props) {
  const [formData, setFormData] = useState({
    name: props.vendorData.name ,
    number: props.vendorData.number ,
    address: props.vendorData.address ,
    date: props.date,
    totalcost: "",
    quantity: "",
    grams: "",
    sold: "0",
    invoice: "",
    stockkey: "Gold Bar"
  });

  console.log('formData venor data',props.vendorData)
  
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const [data, setData] = useState([])
  const [onep, setOnep] = useState(0)
  const apiUrl2 = 'https://api.dremerz.net/api/kg999StockName/';

  useEffect(() => {
    axios.get(apiUrl2)
      .then((response) => {
        response.data = response.data.filter((item) => item.stockname == 'Gold Bar');
        setData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data from the API:', error);
      });

  }, []);


  useEffect(()=>
  {

    setFormData({
      ...formData,
      name: props.vendorData.name ,
      number: props.vendorData.number ,
      address: props.vendorData.address ,

    })

  },[props.vendorData])


  useEffect(()=>
  {

    setFormData({
      ...formData,
      date: props.date,

    })

  },[props.date])

  const handleSubmit = () => {

    if(props.date ==  undefined || props.date == '' || props.date == null){
      alert('Please select the date');
      return;
    }
    if (formData.name == '' || formData.number == '' || formData.address == '' || formData.totalcost == '' || formData.quantity == '' || formData.grams == '') {
      alert('Please enter all the field values.');
      return;
    }
        const apiUrl = 'https://api.dremerz.net/api/kg999StockList/';

  formData.date=props.date;
  formData.name=props.vendorData.name;
  formData.number=props.vendorData.number;
  formData.address=props.vendorData.address;

        axios.post(apiUrl, formData)
          .then((response) => {
            setFormData({
              name: props.vendorData.name ,
              number: props.vendorData.number ,
              address: props.vendorData.address ,
              date: props.date,
              totalcost: "",
              quantity: "",
              grams: "",
              sold: "0",
              stockkey: "Gold Bar"
            });
            setOnep(0);
            alert('Data added successfully');

            // window.location.reload();
          })
          .catch((error) => {
            alert('Error adding data to the API');
          });
  };

  return (

    <div className="col-md-6 grid-margin stretch-card">
      <div className="card" >
        <div className="card-body">
          <h4 className="card-title">ADD GOLD BAR STOCK</h4>
          <form className="forms-sample" style={{color:"black"}}>

            <div className="form-group">
              <label htmlFor="name">VENDOR NAME</label>
              <input
                style={{ width: "100%" }}
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={formData.name ||""}
                onChange={handleInputChange}
                placeholder="NAME"
              />
            </div>
            <div className="form-group">
              <label htmlFor="number">VENDOR NUMBER</label>
              <input
                style={{ width: "100%" }}
                type="text"
                className="form-control"
                id="number"
                name="number"
                value={formData.number ||""}
                onChange={handleInputChange}
                placeholder="NUMBER"
              />
            </div>
            <div className="form-group">
              <label htmlFor="address">VENDOR ADDRESS</label>
              <input
                style={{ width: "100%" }}
                type="text"
                className="form-control"
                id="address"
                name="address"
                value={formData.address ||""}
                onChange={handleInputChange}
                placeholder="ADDRESS"
              />
            </div>
         

            <div className="form-group">
              <label htmlFor="invoice">VENDOR INVOICE NO</label>
              <input
                style={{ width: "100%" }}
                type="text"
                className="form-control"
                id="invoice"
                name="invoice"
                value={formData.invoice ||""}
                onChange={handleInputChange}
                placeholder="VENDOR INVOICE NO"
              />
            </div>

            <div className="form-group">
              <label htmlFor="grams">GOLD BAR ( GRAMS )</label>
              <select style={{ width: "100%" }}
                className="form-control"
                id="grams"
                name="grams"
                value={formData.grams}
                onChange={(event) => {
                  setFormData({
                    ...formData,
                    grams: event.target.value,
                  });
                }}
              >
                <option>QUANTITY</option>
                {data.map((item) => (
                  <option value={item.name} key={item.id}>{item.name} GRAM</option>
                ))}
              </select>

            </div>


            <div className="form-group">
              <label htmlFor="quantity">GOLD BAR ( QUANTITY )</label>
              <input
                style={{ width: "100%" }}
                type="text"
                className="form-control"
                id="quantity"
                name="quantity"
                value={formData.quantity}
                onChange={(event) => {
                  setFormData({
                    ...formData,
                    quantity: event.target.value,
                    totalcost: Math.round(parseFloat(event.target.value) * (onep)),
                  });
                }}
                placeholder="QUANTITY"
              />
            </div>

            <div className="form-group">
              <label htmlFor="totalcost">1 PIECE COST</label>
              <input
                style={{ width: "100%" }}
                type="text"
                className="form-control"
                id="totalcost"
                name="totalcost"
                value={onep}
                onChange={(event) => {
                  setOnep(event.target.value);
                  setFormData({
                    ...formData,
                    totalcost: Math.round(parseFloat(formData.quantity) * parseFloat(event.target.value)),
                  });
                }}
                placeholder="TOTAL COST"
              />
            </div>


            <div className="form-group">
              <label htmlFor="totalcost">TOTAL COST</label>
              <input
                style={{ width: "100%" }}
                type="text"
                disabled
                className="form-control"
                id="totalcost"
                name="totalcost"
                value={formData.totalcost || 0}
                placeholder="TOTAL COST"
              />
            </div>

            <div className="form-group">
              <label htmlFor="totalcost">DATE</label>
              <input
                style={{ width: "100%" }}
                type="text"
                disabled
                className="form-control"
                id="totalcost"
                name="totalcost"
                value={props.date || "DATE"}
                placeholder="TOTAL COST"
              />
            </div>
            <span className="btn btn-primary mr-2" onClick={handleSubmit}>
              Submit
            </span>
          </form>
        </div>
      </div>
    </div>


  );
}





