import React, { useEffect,useState } from 'react';
import axios from 'axios';

import { useLocation, useNavigate } from 'react-router-dom';


import { Document, View, Text, StyleSheet, Image, Font,Page, PDFViewer } from '@react-pdf/renderer';





export default function SalesDetails() {
const navigate = useNavigate();

  useEffect(()=>{
    function handleKeyDown(event) {
      if (event.key == 'F2' || event.key == '-') {
        navigate('/newSale');
      }
    }
    window.addEventListener('keydown', handleKeyDown);
  },[])
    function sliceString(str, numParts) {
        let partLength = Math.ceil(str.length / numParts);
        let parts = [];
        
        for(let i = 0; i < str.length; i += partLength) {
          parts.push(str.slice(i, i + partLength));
        }
        
        return parts;
      }
    const tableHeaderStyle = {
        background: '#f2f2f2',
        border: '1px solid #dddddd',
        padding: '8px',
        color: 'black',
        textAlign: 'left',
        fontWeight: 'bold',
      };
    
      const tableCellStyle = {
        border: '1px solid #dddddd',
        color: 'black',
        padding: '8px',
        textAlign: 'left',
      };
    const location = useLocation();
    
    const [data,setData] = useState(location.state.data);

 
   
    const styles = StyleSheet.create({
        View: {
            padding: 20,
            width: '100%',

            height: '100%',
            backgroundColor: 'white',
            },
            table: {
                display: "table",
                width: "auto",
                borderStyle: "solid",
                borderWidth: 1,
                borderRightWidth: 0,
                borderBottomWidth: 0
                },
                tableRow: {
                margin: "auto",
                flexDirection: "row"
                },
                tableColHeader: {
                width: "5.88%",
                overflowWrap: "break-word",
                borderStyle: "solid",
                borderWidth: 1,
                borderLeftWidth: 0,
                borderTopWidth: 0
                },
                tableCol: {
                minWidth: "5.88%",
                maxWidth: "5.88%",
                borderStyle: "solid",
                overflowWrap: "break-word",
                wordBreak: "break-all",

                borderWidth: 1,
                borderLeftWidth: 0,
                borderTopWidth: 0
                },
                tableCellHeader: {
                margin: "auto",
                marginTop: 5,
                fontSize: 10,
                fontWeight: 500
                },
                tableCell: { 
                    margin: "auto", 
                    marginTop: 5, 
                    fontSize: 10, 
                    overflowWrap: "break-word",
                    wordBreak: "break-all"
                },
               
                tableCellTotal: {
                margin: "auto",
                marginTop: 5,
                fontSize: 10,
                fontWeight: 500
                },
                tableCellTotalValue: {
                margin: "auto",
                marginTop: 5,
                fontSize: 10,
                fontWeight: 500,
                color: "blue"
                }
    });




      const MyDocument = () => (
        <Document>
          <Page size="A4" style={styles.View} orientation="landscape">
           
              {
                // create a printable pdf so that it camn have the entore data exactlky lkike the bellow having table and all
              }
            <View style={styles.table}>
                <View style={styles.tableRow}>
                    <View style={styles.tableColHeader}> <Text style={styles.tableCellHeader}>Invoice No</Text></View>
                    <View style={styles.tableColHeader}>  <Text style={styles.tableCellHeader}>Date</Text>  </View>
                    <View style={styles.tableColHeader}>  <Text style={styles.tableCellHeader}>Name</Text>  </View>
                    <View style={styles.tableColHeader}>  <Text style={styles.tableCellHeader}>Number</Text>  </View>
                    <View style={styles.tableColHeader}>  <Text style={styles.tableCellHeader}>Aadhar / PAN  / GST</Text>  </View>
                    <View style={styles.tableColHeader}>  <Text style={styles.tableCellHeader}>Address</Text>  </View>
                    <View style={styles.tableColHeader}>  <Text style={styles.tableCellHeader}>Stock Type</Text>  </View>
                    <View style={styles.tableColHeader}>  <Text style={styles.tableCellHeader}>Stock Grams</Text>  </View>
                    <View style={styles.tableColHeader}>  <Text style={styles.tableCellHeader}>Stock Quantity</Text>  </View>
                    <View style={styles.tableColHeader}>  <Text style={styles.tableCellHeader}>Total Stock Grams</Text>  </View>
                    <View style={styles.tableColHeader}>  <Text style={styles.tableCellHeader}>Amount</Text>  </View>
                    <View style={styles.tableColHeader}>  <Text style={styles.tableCellHeader}>Discount</Text>  </View>
                    <View style={styles.tableColHeader}>  <Text style={styles.tableCellHeader}>Total Amount</Text>  </View>
                    <View style={styles.tableColHeader}>  <Text style={styles.tableCellHeader}>CGST 1.5% </Text>  </View>
                    <View style={styles.tableColHeader}>  <Text style={styles.tableCellHeader}>SGST 1.5% </Text>  </View>
                    <View style={styles.tableColHeader}>  <Text style={styles.tableCellHeader}>TAX 3% </Text>  </View>
                    <View style={styles.tableColHeader}>  <Text style={styles.tableCellHeader}>Net Amount</Text>  </View>
                </View>
                {
                    data.slice().reverse().map((item, index) => (
                        <View style={styles.tableRow} key={index}>
                            <View style={styles.tableCol}>  <Text style={styles.tableCell}>{item.invoice}</Text>   </View>
                            <View style={styles.tableCol}>  <Text style={styles.tableCell}>{item.date}</Text>   </View>
                            <View style={styles.tableCol}>  <Text style={styles.tableCell}>{item.name}</Text>   </View>
                            <View style={styles.tableCol}>  <Text style={styles.tableCell}>{sliceString(item.number,2)}</Text>   </View>
                            <View style={styles.tableCol}>  <Text style={styles.tableCell}>{ item.aadharotpan ?sliceString(item.aadharotpan,3):"-"}</Text>   </View>
                            <View style={styles.tableCol}>  <Text style={styles.tableCell}>{item.address}</Text>   </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{item.stockType == 'Gold Bar'? 'GOLD BAR' : item.stockType == 'Gold Coin' ? 'GOLD COIN' : item.stockType =="Gold Coin 916"?"GOLD COIN 916": item.stockType == 'Silver Bar' ? 'SILVER BAR' : 'SILVER COIN'
      }</Text>
                            </View>
                            <View style={styles.tableCol}>  <Text style={styles.tableCell}>{item.grams} GM</Text>   </View>
                            <View style={styles.tableCol}>  <Text style={styles.tableCell}>{item.quantity}</Text>   </View>
                            <View style={styles.tableCol}>  <Text style={styles.tableCell}>{(parseFloat(item.grams)* parseFloat(item.quantity)).toFixed(2)} GM</Text>   </View>
                            <View style={styles.tableCol}>  <Text style={styles.tableCell}>{item.amount}</Text>   </View>
                            <View style={styles.tableCol}>  <Text style={styles.tableCell}>{item.discount}</Text>   </View>
                            <View style={styles.tableCol}>  <Text style={styles.tableCell}>{(parseFloat(item.amount) - parseFloat(item.discount)).toFixed(2)}</Text>   </View>
                            <View style={styles.tableCol}>  <Text style={styles.tableCell}>{((parseFloat(item.amount) - parseFloat(item.discount))*0.015).toFixed(2)}</Text>   </View>
                            <View style={styles.tableCol}>  <Text style={styles.tableCell}>{((parseFloat(item.amount) - parseFloat(item.discount))*0.015).toFixed(2)}</Text>   </View>
                            <View style={styles.tableCol}>  <Text style={styles.tableCell}>{((parseFloat(item.amount) - parseFloat(item.discount))*0.03).toFixed(2)}</Text>   </View>


                            <View style={styles.tableCol}>  <Text style={styles.tableCell}>{((parseFloat(item.amount) - parseFloat(item.discount)) + ((parseFloat(item.amount) - parseFloat(item.discount))*0.03)).toFixed(0)}</Text>   </View>
                    
                        </View>
                    ))
                }
                <View style={styles.tableRow}>
                    <View style={styles.tableCol}> <Text style={styles.tableCell}>Total</Text>  </View>
                    <View style={styles.tableCol}>  <Text style={styles.tableCell}></Text>  </View>
                    <View style={styles.tableCol}>  <Text style={styles.tableCell}></Text>  </View>
                    <View style={styles.tableCol}>  <Text style={styles.tableCell}></Text>  </View>
                    <View style={styles.tableCol}>  <Text style={styles.tableCell}></Text>  </View>
                    <View style={styles.tableCol}>  <Text style={styles.tableCell}></Text>  </View>
                    <View style={styles.tableCol}>  <Text style={styles.tableCell}></Text>  </View>
                    <View style={styles.tableCol}>  <Text style={styles.tableCell}></Text>  </View>
                      <View style={styles.tableCol}>  <Text style={styles.tableCell}>{data.reduce((a, b) => a + parseFloat(b.quantity), 0)}</Text>  </View>
                      <View style={styles.tableCol}>
  <Text style={styles.tableCell}>
    {parseFloat(
      data.reduce((a, b) => a + parseFloat(b.grams) * parseFloat(b.quantity), 0)
    ).toFixed(3)} GM
  </Text>
</View>
<View style={styles.tableCol}>  <Text style={styles.tableCell}>{data.reduce((a, b) => a + parseFloat(b.amount), 0).toFixed(2)}</Text>  </View>
                    <View style={styles.tableCol}>  <Text style={styles.tableCell}>{data.reduce((a, b) => a + parseFloat(b.discount), 0).toFixed(2)}</Text>  </View>
                    <View style={styles.tableCol}>  <Text style={styles.tableCell}>{(data.reduce((a, b) => a + (parseFloat(b.amount) - parseFloat(b.discount)), 0)).toFixed(2)}</Text>  </View>
                    <View style={styles.tableCol}>  <Text style={styles.tableCell}>{(data.reduce((a, b) => a + ((parseFloat(b.amount) - parseFloat(b.discount))*0.015), 0)).toFixed(2)}</Text>  </View>
                    <View style={styles.tableCol}>  <Text style={styles.tableCell}>{(data.reduce((a, b) => a + ((parseFloat(b.amount) - parseFloat(b.discount))*0.015), 0)).toFixed(2)}</Text>  </View>
                    <View style={styles.tableCol}>  <Text style={styles.tableCell}>{(data.reduce((a, b) => a + ((parseFloat(b.amount) - parseFloat(b.discount))*0.03), 0)).toFixed(2)}</Text>  </View>
                    <View style={styles.tableCol}>  <Text style={styles.tableCell}>{(data.reduce((a, b) => a + ((parseFloat(b.amount) - parseFloat(b.discount)) + ((parseFloat(b.amount) - parseFloat(b.discount))*0.03)), 0)).toFixed(0)}</Text>  
                    </View>
</View>
                    
            </View>



</Page>
</Document>
      );
  return (
    <div>

        <h1 style={{color:"black",fontWeight:"bolder"}}>SALES SUMMARY!</h1>
        <br />
    
 <PDFViewer style={{width:"100%",height:"100vh"}}>
                <MyDocument />
 </PDFViewer>
            
  <br /><br /><br /><br />
    </div>
  )
}
