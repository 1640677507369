import React from 'react';
import { Outlet, Link } from 'react-router-dom';

export default function Sidebar() {
  const isAuthenticated = localStorage.getItem('auth');
  const userPermissions = isAuthenticated ? isAuthenticated.split(',') : [];

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        {userPermissions.includes('ADD NEW USER') && (
          <li className="nav-item">
            <Link to="/addNewUser" className="nav-link">
              <i className="mdi mdi-account-plus menu-icon" />
              <span className="menu-title">ADD NEW USER</span>
            </Link>
          </li>
        )}
        {userPermissions.includes('VIEW / EDIT USER') && (
          <li className="nav-item">
            <Link to="/viewUser" className="nav-link">
              <i className="mdi mdi-account-edit menu-icon" />
              <span className="menu-title">VIEW / EDIT USER</span>
            </Link>
          </li>
        )}
        {userPermissions.includes('ADD STOCK QUANTITY') && (
          <li className="nav-item">
            <Link to="/addStockQuantity" className="nav-link">
              <i className="mdi mdi-plus-circle menu-icon" />
              <span className="menu-title">ADD STOCK QUANTITY</span>
            </Link>
          </li>
        )}


        {userPermissions.includes('FIX PRICE DETAILS') && (
          <li className="nav-item">
            <Link to="/fixprice" className="nav-link">
              <i className="mdi mdi-currency-inr menu-icon" />
              <span className="menu-title">FIX PRICE DETAILS</span>
            </Link>
          </li>
        )}
        {userPermissions.includes('ADD - STOCK') && (
          <li className="nav-item">
            <Link to="/addstocks" className="nav-link">
              <i className="mdi mdi-package menu-icon" />
              <span className="menu-title">ADD - STOCK</span>
            </Link>
          </li>
        )}
        {userPermissions.includes('VIEW - STOCK') && (
          <li className="nav-item">
            <Link to="/viewstocks" className="nav-link">
              <i className="mdi mdi-view-list menu-icon" />
              <span className="menu-title">VIEW - STOCK</span>
            </Link>
          </li>
        )}
        {userPermissions.includes('VIEW - STOCK SUMMARY') && (
          <li className="nav-item">
            <Link to="/stockCostTable" className="nav-link">
              <i className="mdi mdi-chart-bar menu-icon" />
              <span className="menu-title">VIEW - STOCK SUMMARY</span>
            </Link>
          </li>
        )}






      {(userPermissions.includes('CREATE NEW SALE') ||
          userPermissions.includes('VIEW SALE SUMMARY') ||
          userPermissions.includes('VVIEW SALE DETAILS')) && (
          <li className="nav-item">
            <span
              className="nav-link"
              data-toggle="collapse"
              href="#charts"
              aria-expanded="false"
              aria-controls="charts"
            >
              <i className="mdi mdi-sale menu-icon" />
              <span className="menu-title">SALES</span>
              <i className="menu-arrow" />
            </span>
            <div className="collapse" id="charts">
              <ul className="nav flex-column sub-menu">
              {userPermissions.includes('CREATE NEW SALE') && (
          <li className="nav-item">
            <Link to="/newSale" className="nav-link">
             
              <span className="menu-title">NEW SALE</span>
            </Link>
          </li>
        )}
        {userPermissions.includes('VIEW SALE SUMMARY') && (
          <li className="nav-item">
            <Link to="/viewsalesSummary" className="nav-link">
             
              <span className="menu-title">SALES SUMMARY</span>
            </Link>
          </li>
        )}
        {userPermissions.includes('VIEW SALE DETAILS') && (
          <li className="nav-item">
            <Link to="/viewsales" className="nav-link">
            
              <span className="menu-title">SALES DETAILS</span>
            </Link>
          </li>
        )}
                
              </ul>
            </div>
          </li>
        )}






        {(userPermissions.includes('VIEW - PROFIT') ||
          userPermissions.includes('VIEW - NON PROFIT') ||
          userPermissions.includes('VIEW - LOSS')) && (
          <li className="nav-item">
            <span
              className="nav-link"
              data-toggle="collapse"
              href="#ui-basic"
              aria-expanded="false"
              aria-controls="ui-basic"
            >
              <i className="mdi mdi-cash-usd menu-icon" />
              <span className="menu-title">ACCOUNTS</span>
              <i className="menu-arrow" />
            </span>
            <div className="collapse" id="ui-basic">
              <ul className="nav flex-column sub-menu">
                {userPermissions.includes('VIEW - PROFIT') && (
                  <li className="nav-item">
                    <Link to="/profit" className="nav-link">
                      VIEW PROFIT
                    </Link>
                  </li>
                )}
                {userPermissions.includes('VIEW - NON PROFIT') && (
                  <li className="nav-item">
                    <Link to="/non-profit" className="nav-link">
                      VIEW NON - PROFIT
                    </Link>
                  </li>
                )}
                {userPermissions.includes('VIEW - LOSS') && (
                  <li className="nav-item">
                    <Link to="/loss" className="nav-link">
                      VIEW LOSS
                    </Link>
                  </li>
                )}
                
              </ul>
            </div>
          </li>
        )}
      </ul>
    </nav>
  );
}
