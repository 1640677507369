import React, { useEffect } from 'react'
import GoldBarPrice from '../Components/GoldBarPrice'
import SilverBarPrice from '../Components/SilverBarPrice'
import GoldCoinPrice from '../Components/GoldCoinPrice'
import SilverCoinPrice from '../Components/SilverCoinPrice'
import SquareOffPage from '../Components/SquareOffPage'
import { useNavigate } from 'react-router-dom'
import NewStockName from '../Components/NewStockName'
import GoldCoin916Price from '../Components/GoldCoin916Price'
import axios from 'axios'

export default function PricePage() {

  const navigate = useNavigate();
  const [price, setPrice] = React.useState(0);

  useEffect(()=>{

    function handleKeyDown(event) {
      if (event.key == 'F2' || event.key == '-') {
        navigate('/newSale');
      }
    }
    window.addEventListener('keydown', handleKeyDown);
  },[])

  useEffect(()=>{
    axios.get('https://api.dremerz.net/api/kg999SquareOffAmount/2/')
    .then(res=>
      {
        console.log("lol",res.data.amount)
        setPrice(res.data.amount)
      })
    .catch(err=>
      {
        console.log(err)
      })
  },[])
  return (
    <>

    <div style={{display:"flex",justifyContent:"space-between",flexWrap:"wrap"}}>
    
    <h1 style={{color:"black",fontWeight:"bolder"}}>PRICE PAGE!</h1>
   

   <div>
   <input type="text" placeholder="" value={
    price
   } 
   onChange={(e)=>
  {
    setPrice(e.target.value)
  }}
   style={{width:"100px",height:"30px"}} />

   %
   &nbsp;
   &nbsp;
   &nbsp;
   <button
    onClick={()=>
    {
      // it should show count down in allert unitill the price is updated
        alert("Updating Price!")
      axios.put('https://api.dremerz.net/api/kg999SquareOffAmount/2/',{
        amount:price
      })
      .then(res=>
        {
          console.log(res.data)
          axios.get('https://kg999price.dremerz.net/updatePriceForce')
    .then(res=>
      {
        console.log(res.data)

   
        alert("Price Updated!")
      })
    .catch(err=>
      {
        console.log(err)
        alert("Error in Updating Price!")
      
      })
          
        })
      .catch(err=>
        {
          console.log(err)
          alert("Error in Updating Price!")
        })
    }}

   >
      update
   </button>
   </div>

   <button onClick={()=>
  {

         // it should show count down in allert unitill the price is updated
        alert("Fetching Price!")
        

    axios.get('https://kg999price.dremerz.net/updatePriceForce')
    .then(res=>
      {
        console.log(res.data)

   
        alert("Price Updated!")
      })
    .catch(err=>
      {
        console.log(err)
        alert("Error in Updating Price!")
      
      })
  }}
  >
    Fetch Price
  </button>

    </div>


    <br />


<div style={{display:"flex",justifyContent:"center",flexWrap:"wrap"}}>

        <GoldBarPrice></GoldBarPrice>
        <SilverBarPrice></SilverBarPrice>
        <GoldCoinPrice></GoldCoinPrice>
        <SilverCoinPrice></SilverCoinPrice>
        <GoldCoin916Price></GoldCoin916Price>
        <SquareOffPage></SquareOffPage>
        
    </div>
    <br />
        <br /><br /><br />
    </>
   
  )
}
