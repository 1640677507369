
import React,{useEffect, useState} from 'react';
import { Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import Logo from "../logo.png";
import  CustomFont from "../times.ttf";
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
export default function Bill1(props) {
    Font.register({ family: 'CustomFont', src: CustomFont });
    const location = useLocation();

    const ones = ['Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
    const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

    function NumberToWords(number) {
      if (number == 0) return 'Zero';
  
      let words = [];
  
      const crore = Math.floor(number / 10000000);
      number -= crore * 10000000;
      const lakh = Math.floor(number / 100000);
      number -= lakh * 100000;
      const thousand = Math.floor(number / 1000);
      number -= thousand * 1000;
      const hundred = Math.floor(number / 100);
      number -= hundred * 100;
  
      if (crore > 0) words.push(convertBelowThousand(crore) + ' Crore');
      if (lakh > 0) words.push(convertBelowThousand(lakh) + ' Lakh');
      if (thousand > 0) words.push(convertBelowThousand(thousand) + ' Thousand');
      if (hundred > 0) words.push(convertBelowThousand(hundred) + ' Hundred');
  
      if (number > 0) words.push(convertBelowHundred(number));
  
      return words.join(' ').trim();
  }
  
  function convertBelowHundred(number) {
 
      if (number < 20) return ones[number];
      const digit = number % 10;
      if (number < 100) return tens[Math.floor(number / 10)] + (digit ? ' ' + ones[digit] : '');
      return '';
  }
  
  function convertBelowThousand(number) {
      if (number < 100) return convertBelowHundred(number);
      const rest = number % 100;
      const hundredPart = Math.floor(number / 100);
      return ones[hundredPart] + ' Hundred' + (rest ? ' ' + convertBelowHundred(rest) : '');
  } 
    
    

const [data1, setData1] = useState(location.state?.data || null);

const [data,setData] = useState([]);  

const navigate = useNavigate();

useEffect(() => {


  function handleKeyDown(event) {
    if (event.key == 'F2' || event.key == '-') {
      navigate('/newSale');
    }
  }
  window.addEventListener('keydown', handleKeyDown);
    // console.log(data1)
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://api.dremerz.net/api/kg999ClientList/`);
        // console.log(response.data);

        if (data1) {
          const filteredData = response.data.filter((item) => item.invoice == data1);
          // console.log(filteredData);
          setData(filteredData);
          // console.log(filteredData)
        } else {
          setData([
            {
                "id": 21,
                "date": "11/11/2023",
                "invoice": "4609",
                "name": "sai aravind",
                "address": "2/14 kaveri (nagar koladi road)  thiruverkadu chennai-77 in TAMILNADU",
                "aadharotpan": "aadhar",
                "number": "+919840841840",
                "quantity": "1",
                "grams": "0.5",
                "stockType": "gold",
                "amount": "3472",
                "discount": "0",
                "totalAmount": "0",
                "previousAmount": "2312",
                "byuDate": "10/11/2023",
                "soldBy": "Admin",
                "paymentMode1": "cash",
                "paymentMode2": "",
                "paymentMode1Amount": "3576",
                "paymentMode2Amount": "0"
            },
          ]);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);



  const styles = StyleSheet.create({
  
    section: {
       
    },
    table: {
      display: 'table',
      width: '100%',
      // borderWidth: 0.5,
      // borderColor: '#000',
    },
    tableRow: {
      flexDirection: 'row',
      borderBottomWidth: 0.5,
      borderBottomColor: '#000',
      borderTopWidth: 0.5,
      borderTopColor: '#000',
      borderLeftWidth: 0.5,
      borderLeftColor: '#000',
      borderRightWidth: 0.5,
      borderRightColor: '#000',

    },   
     tableRowA: {
      flexDirection: 'row',
      // borderWidth: 0.5,
      // borderColor : '#000',
      borderLeftWidth: 0.5,
      borderRightWidth: 0.5,
      borderColor: '#000',
      
    },
    tableRowAA: {
      flexDirection: 'row',
      // borderWidth: 0.5,
      // borderColor : '#000',
      borderLeftWidth: 0.5,
      borderRightWidth: 0.5,
      borderBottomWidth: 0.5,
      borderColor: '#000',
      
    },
    tableCell: {
      width: '15%',
      borderStyle: 'solid',
      padding: 8,
      fontSize:11,
      fontFamily:"CustomFont",
      fontWeight:"extrabold",
      alignContent:"center",
      textAlign:"right",
      borderLeftWidth: 0.5,
      borderLeftColor: '#000',


    },


    tableCellWW: {
      width: '15%',
      borderStyle: 'solid',
      padding: 8,
      fontSize:11,
      fontFamily:"CustomFont",
      fontWeight:"extrabold",
      alignContent:"center",
      textAlign:"right",


    },

    tableCellW: {
      width: '85%',
      borderStyle: 'solid',
      padding: 8,
      fontSize:11,
      fontFamily:"CustomFont",
      fontWeight:"extrabold",
      alignContent:"center",
      textAlign:"left",


    },
    tableCellA: {
      width: '10%',
      borderStyle: 'solid',
      padding: 8,
      fontSize:11,
      fontFamily:"CustomFont",
      fontWeight:"extrabold",
      alignContent:"left",
      textAlign:"left",
    },
    tableCellB: {
      width: '35%',
      borderStyle: 'solid',
      padding: 8,
      alignContent:"center",
      textAlign:"center",
      fontSize:11,
      fontFamily:"CustomFont",
      fontWeight:"extrabold", alignContent:"left",
      textAlign:"left",
      borderLeftWidth: 0.5,
      borderLeftColor: '#000',
    },

    tableCellBW: {
      width: '25%',
      borderStyle: 'solid',
      padding: 8,
      alignContent:"center",
      textAlign:"center",
      fontSize:11,
      fontFamily:"CustomFont",
      fontWeight:"extrabold", alignContent:"left",
      textAlign:"left",
    },
    tableCell1: {
        width: '20%',
        borderStyle: 'solid',
        padding: 8,
        fontSize:10,
        fontFamily:"CustomFont", alignContent:"center",
        textAlign:"center",
      },
  });
  const tax = (parseFloat(((data.reduce((a,b)=>a+parseFloat(b.amount),0)).toFixed(2)) - (((data.reduce((a,b)=>a+parseFloat(b.discount),0))).toFixed(2))) * 0.03).toFixed(2);

  const amount  = (parseFloat(((data.reduce((a,b)=>a+parseFloat(b.amount),0)).toFixed(2)) - (((data.reduce((a,b)=>a+parseFloat(b.discount),0))).toFixed(2)))).toFixed(2);
  
  const grandTotal = (parseFloat(amount) + parseFloat(tax)).toFixed(0);
  let discountDisplayed = false;
  const MyDocument = () => (

    data &&
      data.length > 0?
      <Document>
      <Page size="A4" style={{ flex: 1, padding: 15 }}>
        <View style={{ borderWidth: 0.5, padding: 10, height: "100%", width: "100%", borderColor: "black", borderRadius: 7 }}>
  
          <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
  
            <View style={{ backgroundColor: "#5E17EB", borderRadius: 0 ,}}>
              <Text style={{ color: "white", fontSize: 9, paddingHorizontal: 3, paddingVertical: 3, fontFamily:"CustomFont" }}>TAX INVOICE</Text>
            </View>
            <View>
              <Text style={{ fontSize: 10, color: "#FF914D", textAlign: "right",  width: "40%",fontFamily:"CustomFont" }}>GST IN : 37BKOPS7684H2Z5C</Text>
            </View>
          </View>
  
          <View style={{ flexDirection: "row", justifyContent: "center", marginTop: 20, alignItems: "center" }}>
            <Image src={Logo} style={{ width: 350, height: 120 }}></Image>
          </View>
          <View style={{ marginTop: 10 }}></View>
  
  
  
          <View style={{ borderTopWidth: 0.5, width: "100%", borderColor: "black", borderRadius: 7 }}></View>
          <View style={{ marginTop: 10 }}></View>
  
  <View style={{flexDirection:"row",marginTop:10,width:"100%"}}>
  
  <View style={{width:"100%",flexDirection:"row",gap:15,justifyContent:"space-between"}}>
  
  <Text style={{fontFamily:"CustomFont",fontSize:11,textAlign:"left",flex:1,}}>NAME</Text>
  <Text style={{fontFamily:"CustomFont",fontSize:11,textAlign:"left"}}>:</Text>
    <Text style={{fontFamily:"CustomFont",fontSize:10,textAlign:"left",flex:3}}>{data[0].name.toUpperCase()}</Text>
  </View>
  
  <View style={{width:"100%",flexDirection:"row",backgroundColor:"",gap:15,justifyContent:"space-between"}}>
  
  <Text style={{fontFamily:"CustomFont",fontSize:11,textAlign:"right",flex:5,}}>DATE</Text>
  <Text style={{fontFamily:"CustomFont",fontSize:11,textAlign:"left"}}>:</Text>
  
    <Text style={{fontFamily:"CustomFont",fontSize:10,textAlign:"right",flex:1}}>{data[0].date}</Text>
  </View>
  
    </View>
  
  
  
  
    <View style={{flexDirection:"row",marginTop:10,width:"100%"}}>
  
  <View style={{width:"100%",flexDirection:"row",gap:15,justifyContent:"space-between"}}>
  
  <Text style={{fontFamily:"CustomFont",fontSize:11,textAlign:"left",flex:1}}>ADDRESS</Text>
  <Text style={{fontFamily:"CustomFont",fontSize:11,textAlign:"left"}}>:</Text>
  
    <Text style={{fontFamily:"CustomFont",fontSize:10,textAlign:"left",flex:3}}>{data[0].address.toUpperCase()}</Text>
  </View>
  
  <View style={{width:"100%",flexDirection:"row",backgroundColor:"",gap:15,justifyContent:"space-between"}}>
  
  <Text style={{fontFamily:"CustomFont",fontSize:11,textAlign:"right",flex:5,}}>INVOICE NO</Text>
  <Text style={{fontFamily:"CustomFont",fontSize:11,textAlign:"left",}}>:</Text>
  
    <Text style={{fontFamily:"CustomFont",fontSize:10,textAlign:"right",flex:1}}>{data[0].invoice}</Text>
  </View>
  
    </View>
  
  
    <View style={{flexDirection:"row",marginTop:10,width:"100%"}}>
  
  <View style={{width:"100%",flexDirection:"row",gap:15,justifyContent:"space-between"}}>
  
  <Text style={{fontFamily:"CustomFont",fontSize:11,textAlign:"left",flex:1}}>NUMBER</Text>
  <Text style={{fontFamily:"CustomFont",fontSize:11,textAlign:"left"}}>:</Text>
  
    <Text style={{fontFamily:"CustomFont",fontSize:10,textAlign:"left",flex:3}}>{data[0].number}</Text>
  </View>
  




    {
      data[0].aadharotpan.length>=15?    <View style={{width:"100%",flexDirection:"row",backgroundColor:"",gap:15,}}>
      <Text style={{fontFamily:"CustomFont",fontSize:11,textAlign:"right",flex:1,}}>GST IN</Text>
      <Text style={{fontFamily:"CustomFont",fontSize:11,textAlign:"left"}}>:</Text>
      
        <Text style={{fontFamily:"CustomFont",fontSize:10,textAlign:"left",}}>{data[0].aadharotpan}</Text>
      </View>:

<View style={{width:"100%",flexDirection:"row",backgroundColor:"",gap:15,}}>
<Text style={{fontFamily:"CustomFont",fontSize:11,textAlign:"right",flex:1,}}></Text>
<Text style={{fontFamily:"CustomFont",fontSize:11,textAlign:"left"}}></Text>

  <Text style={{fontFamily:"CustomFont",fontSize:10,textAlign:"left",}}></Text>
</View>
    }

  
    </View>
  
    <View style={{marginTop:10}}></View>
  
    {/* <View style={{ borderTopWidth: 0.5, width: "100%", borderColor: "black", borderRadius: 7 }}></View> */}
  
  
  
    <View style={{marginTop:10}}></View>
    <View style={{ marginTop: 10 }}></View>
  
  
    <View style={styles.section}>
        
        <View style={styles.table}>
          {/* Table Header */}
          <View style={styles.tableRow}>
            <View style={styles.tableCellA}>
              <Text>S.N.O</Text>
            </View>
            <View style={styles.tableCellB}>
              <Text>DESCRIPTION</Text>
            </View>
            <View style={styles.tableCell}>
              <Text>HSN / SAC CODE</Text>
            </View> 
            <View style={styles.tableCell}>
              <Text>UNITS</Text>
            </View>
            <View style={styles.tableCell}>
              <Text>GRAMS</Text>
            </View>
            <View style={styles.tableCell}>
              <Text>RATE PER UNIT</Text>
            </View>
            <View style={styles.tableCell}>
              <Text>AMOUNT</Text>
            </View>
          </View>
  
  
  
  {
  data.map((item,index)=>(
    <View style={styles.tableRowA}>
    <View style={styles.tableCellA}>
      <Text>{index+1}</Text>
    </View>
    <View style={styles.tableCellB}>
      <Text>{item.stockType.toUpperCase()}  {item.grams} GRAM</Text>
    </View>
    <View style={styles.tableCell}>
      <Text>{item.code}</Text>
    </View> 
    <View style={styles.tableCell}>
      <Text>{item.quantity}</Text>
    </View>
    <View style={styles.tableCell}>
      <Text>{item.grams}</Text>
    </View>
    <View style={styles.tableCell}>
      <Text>{(parseFloat(item.amount)/ parseInt(item.quantity)).toFixed(2)}</Text>
    </View>
    <View style={styles.tableCell}>
      <Text>{((parseFloat(item.amount)/ parseInt(item.quantity))* parseFloat(item.quantity)).toFixed(2)}</Text>
    </View>
  </View>
  ))
  }
  
  {

  data.map((item, index) => {
    if ((item.discount != "0" || item.discount != 0) && !discountDisplayed) {
      discountDisplayed = true;

      return (
        <View key={index} style={styles.tableRowA}>
          <View style={styles.tableCellA}>
            <Text></Text>
          </View>
          <View style={styles.tableCellB}>
            <Text>DISCOUNT</Text>
          </View>
          <View style={styles.tableCell}>
            <Text></Text>
          </View>
          <View style={styles.tableCell}>
            <Text></Text>
          </View>
          <View style={styles.tableCell}>
            <Text></Text>
          </View>
          <View style={styles.tableCell}>
            <Text></Text>
          </View>
          <View style={styles.tableCell}>
            <Text>-{((data.reduce((a, b) => a + parseFloat(b.discount), 0))).toFixed(2)}</Text>
          </View>
        </View>
      );
    }
    return null;
  })
}



  
  <View style={styles.tableRowA}>
  <View style={styles.tableCellA}>
    <Text></Text>
  </View>
  <View style={styles.tableCellB}>
    <Text></Text>
  </View>
  <View style={styles.tableCell}>
    <Text></Text>
  </View>
  <View style={styles.tableCell}>
    <Text></Text>
  </View>
  <View style={styles.tableCell}>
    <Text></Text>
  </View>
  <View style={styles.tableCell}>
    <Text></Text>
  </View>
  <View style={styles.tableCell}>
    <Text></Text>
  </View>
  </View>
  <View style={styles.tableRowA}>
  <View style={styles.tableCellA}>
    <Text></Text>
  </View>
  <View style={styles.tableCellB}>
    <Text>GROSS AMOUNT</Text>
  </View>
  <View style={styles.tableCell}>
    <Text></Text>
  </View>
  <View style={styles.tableCell}>
    <Text></Text>
  </View>
  <View style={styles.tableCell}>
    <Text></Text>
  </View>
  <View style={styles.tableCell}>
    <Text></Text>
  </View>
  <View style={styles.tableCell}>
  <Text>
  {
  ((  (((
  
  (data.reduce((a,b)=>a+parseFloat(b.amount),0))).toFixed(2)))
  - (((data.reduce((a,b)=>a+parseFloat(b.discount),0))).toFixed(2))).toFixed(2))
  }
  
  </Text>
  
  </View>
  </View>
  
  
  
  
  
  <View style={styles.tableRowA}>
  <View style={styles.tableCellA}>
    <Text></Text>
  </View>
  <View style={styles.tableCellB}>
    <Text>CGST</Text>
  </View>
  <View style={styles.tableCell}>
    <Text></Text>
  </View>
  <View style={styles.tableCell}>
    <Text></Text>
  </View>
  <View style={styles.tableCell}>
    <Text></Text>
  </View>
  <View style={styles.tableCell}>
    <Text>( 1.5% )</Text>
  </View>
  <View style={styles.tableCell}>
  
  <Text>  {
  ((  (((
  
  (data.reduce((a,b)=>a+parseFloat(b.amount),0)) ).toFixed(2)))
  - (((data.reduce((a,b)=>a+parseFloat(b.discount),0))).toFixed(2)))*0.015).toFixed(2)
  }</Text>
  
  </View>
  </View>
  
  <View style={styles.tableRowA}>
  <View style={styles.tableCellA}>
    <Text></Text>
  </View>
  <View style={styles.tableCellB}>
    <Text>SGST</Text>
  </View>
  <View style={styles.tableCell}>
    <Text></Text>
  </View>
  <View style={styles.tableCell}>
    <Text></Text>
  </View>
  <View style={styles.tableCell}>
    <Text></Text>
  </View>
  <View style={styles.tableCell}>
    <Text>( 1.5% )</Text>
  </View>
  <View style={styles.tableCell}>
  <Text>  {
  ((  (((
  
  (data.reduce((a,b)=>a+parseFloat(b.amount),0)) ).toFixed(2) ))
  - (((data.reduce((a,b)=>a+parseFloat(b.discount),0))).toFixed(2)))*0.015).toFixed(2)
  }</Text>
   </View>
  </View>







 <View style={styles.tableRowA}>
  <View style={[styles.tableCellA,{borderBottomWidth:0.5,borderBottomColor:"grey"}]}>
    <Text></Text>
  </View>
  <View style={[styles.tableCellB,{borderBottomWidth:0.5,borderBottomColor:"grey"}]}>
    <Text></Text>
  </View>
  <View style={[styles.tableCell,{borderBottomWidth:0.5,borderBottomColor:"grey"}]}>
    <Text></Text>
  </View>
  <View style={[styles.tableCell,{borderBottomWidth:0.5,borderBottomColor:"grey"}]}>
    <Text></Text>
  </View>
  <View style={[styles.tableCell,{borderBottomWidth:0.5,borderBottomColor:"grey"}]}>
    <Text></Text>
  </View>
  <View style={[styles.tableCell,{borderBottomWidth:0.5,borderBottomColor:"grey"}]}>
    <Text></Text>
  </View>
  <View style={[styles.tableCell,{borderBottomWidth:0.5,borderBottomColor:"grey"}]}>
    <Text></Text>
  </View>
  </View>





  <View style={styles.tableRowAA}>

  <View style={styles.tableCellBW}>
    <Text>TOTAL AMOUNT</Text>
  </View>
  <View style={[styles.tableCellW]}>
    <Text style={{fontWeight:"extrabold"}}>{NumberToWords( parseFloat(grandTotal).toFixed(0)
    ).toUpperCase()} RUPEES</Text>
  </View>

  <View style={styles.tableCellWW}>
  <Text>
  {
  grandTotal
  }.00
  </Text>
    </View>
  </View>
  
  




  
  
  
  
          <View style={{marginTop:10}}></View>
  
  
  
  
        </View>
  
  
  
      </View>
  
          {/* <View style={{ borderTopWidth: 0.5, width: "100%", borderColor: "black", borderRadius: 7 }}></View> */}
          <View style={{marginTop:10}}></View>
          {/* <View style={{ borderTopWidth: 0.5, width: "100%", borderColor: "black", borderRadius: 7 }}></View> */}
  
  
  
  
  
  {/* END OF TABLE */}    
  {/*   
  <View style={{flexDirection:"row",justifyContent:"space-between",padding:10}}>
  <View style={{width:"40%"}}>
  
    <Text style={{fontFamily:"CustomFont",fontSize:11}}>PAYMENT MODES</Text>
  
    <View style={{marginTop:10}}></View>
    <View style={{marginTop:10}}></View>
  
  {
  data[0].paymentMode1 !==""?
  <View style={{flexDirection:"row",gap:15,justifyContent:"space-between",}}>
  <Text style={{fontFamily:"CustomFont",fontSize:10,textAlign:"left",flex:1}}>{data[0].paymentMode1.toUpperCase()}</Text>
  <Text style={{fontFamily:"CustomFont",fontSize:10,textAlign:"  ",flex:1}}>:</Text>
  <Text style={{fontFamily:"CustomFont",fontSize:10,textAlign:"right",flex:1}}>{data[0].paymentMode1Amount}.00</Text>
  </View>
  :null
  }
  {
  data[0].paymentMode2 !==""?
  <View style={{marginTop:10}}></View>
  :null
  
  }
  {
  data[0].paymentMode2 !==""?
  <View style={{flexDirection:"row",gap:15,justifyContent:"space-between",}}>
  <Text style={{fontFamily:"CustomFont",fontSize:10,textAlign:"left",flex:1}}>{data[0].paymentMode2.toUpperCase()}</Text>
  <Text style={{fontFamily:"CustomFont",fontSize:10,textAlign:"  ",flex:1}}>:</Text>
  <Text style={{fontFamily:"CustomFont",fontSize:10,textAlign:"right",flex:1}}>{data[0].paymentMode2Amount}.00</Text>
  </View>
  :null
  
  }
  
  
    <View style={{marginTop:5}}></View>
  
  
  
  <View style={{marginTop:10}}></View>
  <View style={{marginTop:10}}></View>
  
  
  {
  data[0].paymentMode1 !==""?
  <View style={{flexDirection:"row",gap:15,justifyContent:"space-between",}}>
  <Text style={{fontFamily:"CustomFont",fontSize:10,textAlign:"left",flex:1}}>TOTAL PAID</Text>
  <Text style={{fontFamily:"CustomFont",fontSize:10,textAlign:"  ",flex:1}}>:</Text>
  <Text style={{fontFamily:"CustomFont",fontSize:10,flex:1,textAlign:"right"}}>{parseInt(data[0].paymentMode1Amount) + parseInt(data[0].paymentMode2Amount)
  }.00</Text>
  </View>
  :null
  }
  
  
  
  </View>
  
  
  
  <View style={{width:"40%"}}>
  
    <Text style={{fontFamily:"CustomFont",fontSize:11}}>PAYMENT SUMMARY</Text>
  
    <View style={{marginTop:10}}></View>
    <View style={{marginTop:10}}></View>
  
  <View style={{flexDirection:"row",gap:15,justifyContent:"space-between",}}>
  
    <Text style={{fontFamily:"CustomFont",fontSize:10,textAlign:"left",flex:1.7}}>AMOUNT</Text>
    <Text style={{fontFamily:"CustomFont",fontSize:10,textAlign:"  ",flex:1}}>:</Text>
    <Text style={{fontFamily:"CustomFont",fontSize:10,textAlign:"right",flex:1}}>
      {
        (data.reduce((a,b)=>a+parseFloat(b.amount),0)).toFixed(2)
      }
    </Text>
    </View>
  
  
  
    <View style={{marginTop:5}}></View>
    
  
   {
      data[0].discount !=="0"?
      <View style={{flexDirection:"row",gap:15,justifyContent:"space-between",}}>
      <Text style={{fontFamily:"CustomFont",fontSize:10,textAlign:"left",flex:1.7}}>DISCOUNT</Text>
      <Text style={{fontFamily:"CustomFont",fontSize:10,textAlign:"  ",flex:1}}>:</Text>
      <Text style={{fontFamily:"CustomFont",fontSize:10,textAlign:"right",flex:1}}>
        {
          ((data.reduce((a,b)=>a+parseFloat(b.discount),0))).toFixed(2)
        }
      </Text>
      </View>
      :null
  
   }
  
  
    <View style={{marginTop:5}}></View>
  
  
  {
      data[0].amount !=="0"?
  
      <View style={{flexDirection:"row",gap:15,justifyContent:"space-between",}}>
      <Text style={{fontFamily:"CustomFont",fontSize:10,textAlign:"left",flex:1.7}}>GROSS AMOUNT</Text>
      <Text style={{fontFamily:"CustomFont",fontSize:10,textAlign:"  ",flex:1}}>:</Text>
      <Text style={{fontFamily:"CustomFont",fontSize:10,textAlign:"right",flex:1}}>
        {
         ( ((data.reduce((a,b)=>a+parseFloat(b.amount),0)).toFixed(2)) - (((data.reduce((a,b)=>a+parseFloat(b.discount),0))).toFixed(2))).toFixed(2)
        }
      </Text>
      </View>
      :null
  }
  
  
  
    <View style={{marginTop:5}}></View>
  
  
  {
      data[0].amount !=="0"?
  
      <View style={{flexDirection:"row",gap:15,justifyContent:"space-between",}}>
      <Text style={{fontFamily:"CustomFont",fontSize:10,textAlign:"left",flex:1.7}}>CGST ( 1.5% )</Text>
      <Text style={{fontFamily:"CustomFont",fontSize:10,textAlign:"  ",flex:1}}>:</Text>
      <Text style={{fontFamily:"CustomFont",fontSize:10,textAlign:"right",flex:1}}>
       
       {
         (( ((data.reduce((a,b)=>a+parseFloat(b.amount),0)).toFixed(2)) - (((data.reduce((a,b)=>a+parseFloat(b.discount),0))).toFixed(2)))*0.015).toFixed(2)
       }
      </Text>
      </View>
      :null
  
  }
  
  
  <View style={{marginTop:5}}></View>
  
  
  {
      data[0].amount !=="0"?
  
      <View style={{flexDirection:"row",gap:15,justifyContent:"space-between",}}>
      <Text style={{fontFamily:"CustomFont",fontSize:10,textAlign:"left",flex:1.7}}>SGST ( 1.5% )</Text>
      <Text style={{fontFamily:"CustomFont",fontSize:10,textAlign:"  ",flex:1}}>:</Text>
      <Text style={{fontFamily:"CustomFont",fontSize:10,textAlign:"right",flex:1}}>
        {
                 (( ((data.reduce((a,b)=>a+parseFloat(b.amount),0)).toFixed(2)) - (((data.reduce((a,b)=>a+parseFloat(b.discount),0))).toFixed(2)))*0.015).toFixed(2)
  
        }
      </Text>
      </View>
      :null
  
  }
  
  
  
  <View style={{marginTop:10}}></View>
  <View style={{marginTop:10}}></View>
  
  
  {
  
  data[0].amount !=="0"?
  
  <View style={{flexDirection:"row",gap:15,justifyContent:"space-between",}}>
  <Text style={{fontFamily:"CustomFont",fontSize:10,textAlign:"left",flex:1.7}}>TOTAL AMOUNT</Text>
  <Text style={{fontFamily:"CustomFont",fontSize:10,textAlign:"  ",flex:1}}>:</Text>
  <Text style={{fontFamily:"CustomFont",fontSize:10,textAlign:"right",flex:1}}>
    
    {
      (
        parseFloat(((data.reduce((a,b)=>a+parseFloat(b.amount),0)).toFixed(2)) - (((data.reduce((a,b)=>a+parseFloat(b.discount),0))).toFixed(2))) + parseFloat(((( ((data.reduce((a,b)=>a+parseFloat(b.amount),0)).toFixed(2)) - (((data.reduce((a,b)=>a+parseFloat(b.discount),0))).toFixed(2)))*0.015).toFixed(2)) *2)
      ).toFixed(0)
    }.00
  </Text>
  </View>
  :null
  
  }
  
  
  
  
  </View>
  
  </View> */}
  
  {/* <View style={{marginTop:10}}></View>
  
  
  <View style={{display:"flex",flexDirection:"row",gap:10,paddingLeft:10,alignItems:"center",justifyContent:"center"}}>
    <Text style={{fontFamily:"CustomFont",fontSize:10,textAlign:"center"}}>TOTAL AMOUNT IN WORDS</Text>
    <Text style={{fontFamily:"CustomFont",fontSize:10,textAlign:"center"}}>:</Text>
    <Text style={{fontFamily:"CustomFont",fontSize:9,textAlign:"center"}}> ( {(NumberToWords(parseInt(
      (
        parseFloat(((data.reduce((a,b)=>a+parseFloat(b.amount),0)).toFixed(2)) - (((data.reduce((a,b)=>a+parseFloat(b.discount),0))).toFixed(2))) + parseFloat(((( ((data.reduce((a,b)=>a+parseFloat(b.amount),0)).toFixed(2)) - (((data.reduce((a,b)=>a+parseFloat(b.discount),0))).toFixed(2)))*0.015).toFixed(2)) *2)
      ).toFixed(0)
    
    ))).toUpperCase()} )</Text>
  </View> */}
  
  
  
  <View style={{position:"absolute",bottom:70,width:"100%"}}>
  <View style={{flexDirection:"row",justifyContent:"space-between"}}> 
    <Text style={{fontFamily:"CustomFont",fontSize:11,textAlign:"center",paddingLeft:20}}>SIGNATURE OF THE PARTY</Text>
    <Text style={{fontFamily:"CustomFont",fontSize:11}}>KG999 AUTHORIZED SIGNATURE</Text>
  </View>
  </View>
  
  
  <View style={{position:"absolute",bottom:10,width:"100%"}}>
    <Text style={{fontFamily:"CustomFont",fontSize:9,textAlign:"center",color:"#800000",paddingLeft:15}}>DOOR NO 27-1-97/2, RAMJI NAGAR, NEAR MORE SUPER MARKET, CHILDRENS PARK ROAD, NELLORE 524002, ANDHRA PRADESH</Text>
    <Text style={{fontFamily:"CustomFont",fontSize:9,textAlign:"center",color:"#800000",paddingLeft:15}}>CONTACT NUMBER : +91 888 555 8228 </Text>
  
  </View>
  
        </View>
      </Page>
    </Document>
      :
      <div>
        <h1>NO DATA FOUND!</h1>
      </div>
    
  
  
  );
  // let pdfUrl =data[0].downloadURL ; 
  var url;
 if (data.length > 0) {
  let pdfUrl =data[0].downloadURL; 
  let phoneNumber = data[0].number;
  let greetingMessage = "Thanks for buying from us at KOPPAVARAPU GOLD! Here is your invoice.    ";

   url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(greetingMessage + pdfUrl)}`;
 }

  return (
<>
{
  data.length > 0?
<>
<a  href={url} target="_blank"  className="btn btn-primary mr-2" style={{color:"white"}} >Send PDF to Client</a>
<br />
<br />

</>
:null
}

  

    <PDFViewer width="100%" height="100%">
<MyDocument></MyDocument>
</PDFViewer>

</>
  )
}
