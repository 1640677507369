import React, { useEffect } from 'react'
import StockCostTable from '../Components/StockCostTable'
import { useNavigate } from 'react-router-dom';
export default function StockSummaryData() {
  const navigate = useNavigate();


  useEffect(()=>{
    function handleKeyDown(event) {
      if (event.key == 'F2' || event.key == '-') {
        navigate('/newSale');
      }
    }
    window.addEventListener('keydown', handleKeyDown);
  },[])
  return (
    <div>
        <h1 style={{textAlign:"center",color:"black"}}>STOCK SUMMARY!</h1>
        <br />
    <StockCostTable />
    </div>
  )
}
