import React, { useEffect, useState } from "react";
import Sidebar from './Components/Sidebar'

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Logo from "./Components/LOGO COLOR - 2.png";
import Logo1 from "./Components/LOGO COLOR - 1.png";
import { useNavigate } from 'react-router-dom';

import PricePage from "./Pages/PricePage";
import AddStock from "./Pages/AddStock";
import ViewStock from "./Pages/ViewStock";
import SaledPage from "./Pages/SalePage";
import ViewSale from "./Pages/ViewSales";
import ViewProfit from "./Pages/ViewProfit";
import ViewNonProfit from "./Pages/ViewNonProfit";
import ViewLoss from "./Pages/ViewLosePage";
import ViewSalesSummary from "./Pages/ViewSalesSummary";
import Bill from "./Pages/Bill";
import ExtraSale from "./Pages/ExtraSale";
import NewSale from "./Pages/NewSale";
import StockSummary from "./Pages/StockSummary";
import SalesSummary from "./Pages/SalesSummary";
import Bill1 from "./Pages/Bil1";
import SalesDetails from "./Pages/SalesDetails";
import axios from "axios";
import AddNewUser from "./Pages/AddNewUser";
import ViewUser from "./Pages/ViewUser";
import StockCostTable from "./Components/StockCostTable";
import StockSummaryData from "./Pages/StockSummaryData";
import AddStockQuantity from "./Pages/AddStockQuantity";
import Test from "./Pages/Test";

export default function App() {
  // const navigate = useNavigate();
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
 const [changePassword, setChangePassword] = useState(false);
 const [newPassword, setNewPassword] = useState('');
 const [newPassword1, setNewPassword1] = useState('');
  const [isFullScreen, setIsFullScreen] = useState(false);
const [authUserName, setAuthUserName] = useState('');
  const enterFullScreen = () => {
    const element = document.documentElement;

    if (element.requestFullscreen) {
      element.requestFullscreen().then(() => {
        setIsFullScreen(true);
      });
    }
  };

  const exitFullScreen = () => {
    if (document.exitFullscreen && isFullScreen) {
      document.exitFullscreen().then(() => {
        setIsFullScreen(false);
      });
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const [authenticated, setAuthenticated] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isHovered, setIsHovered] = useState(false);
const [auth,setAuth]=useState();


  const handleSubmit = (event) => {
    event.preventDefault();

    // Replace this with your actual authentication logic

    user.map((item)=>{
      if (username == item.name && password == item.password) {
        setAuthenticated(true);
        // Store the authentication status in localStorage
        localStorage.setItem("authenticated", "true");
        localStorage.setItem("authUser", item.name);
        localStorage.setItem("auth", item.auth);
        localStorage.setItem("id", item.id);
        
      } 
      
    }
    )

if  ((!(localStorage.getItem("authenticated"))) || (localStorage.getItem("authenticated")) ==null) {
      alert("Invalid username or password.");
}
    
  };



  const handleLogout = () => {
   
    setAuthenticated(false);
    // Remove the authentication status from localStorage
    localStorage.removeItem("authenticated");
    // Optionally, you can clear the username and password from state as well
    setUsername("");
    setPassword("");
  };
  const [user, setUser] = useState([]);

  useEffect(() => {
    axios.get("https://api.dremerz.net/api/kg999LoginData/")
    .then((res)=>{
      setUser(res.data)
      console.log(localStorage.getItem("auth"))
    })
    .catch((err)=>{
      alert("Something Went Wrong")
    })

    const isUserAuthenticated = localStorage.getItem("authenticated");
    const authUser = localStorage.getItem("authUser");
    setAuthUserName(authUser)
    if (isUserAuthenticated == "true") {
      setAuthenticated(true);
    }
  }, []);

  
  return (
    <>
    <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
<div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-between">
<a className="navbar-brand brand-logo" >
  <img style={{width:"60px",height:"60px"}}
    src={Logo}
    alt="logo"
  />
</a>
<a className="navbar-brand brand-logo-mini" >
  <img  style={{minWidth:"30px",height:"30px"}}
    src={Logo1}
    alt="logo"
  />
</a>
<div className="notification">
  {" "}
  {/* <i className="mdi mdi-bell-outline" /> <span className="indicator" /> */}
</div>
</div>
<div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">
<div className="d-none d-lg-flex">
  <button
    className="navbar-toggler navbar-toggler align-self-center"
    type="button"
    data-toggle="minimize"
  >
    <span className="mdi mdi-menu" />
  </button>
  
</div>

<ul className="navbar-nav navbar-nav-right">
 
 
  <li className="nav-item nav-profile dropdown">
    <a
      className="nav-link"
   
      data-toggle="dropdown"
      id="screenActionDropdown"
    >
      <i className="mdi mdi-view-grid text-info mr-0" />
    </a>
    <div
      className="dropdown-menu dropdown-menu-right navbar-dropdown"
      aria-labelledby="screenActionDropdown"
    >
      <a className="dropdown-item"  id="fullscreen-button" onClick={enterFullScreen}>
        <i className="mdi mdi-fullscreen text-primary" />
        Fullscreen
      </a>
      <a className="dropdown-item" id="exit-fullscreen-button" onClick={exitFullScreen}>
        <i className="mdi mdi-fullscreen-exit text-primary" />
        Exit Fullscreen
      </a>
    </div>
  </li>
  <li className="nav-item nav-profile dropdown">
    <div className="nav-link" data-toggle="dropdown" id="profileDropdown">
      <span>
        <span className="nav-profile-name">{authUserName=='kg999'?"ADMIN":authUserName}</span>
      </span>
      <span className="user-icon">KG</span>
    </div>
    <div
      className="dropdown-menu dropdown-menu-right navbar-dropdown user-profile-action"
      aria-labelledby="profileDropdown"
    >
    
      <div className="p-3">
    
        <div role="separator" className="dropdown-divider" />
       
     
        <a  onClick={handleLogout}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
          className="dropdown-item py-1  px-2 d-flex align-items-center justify-content-between"
         
        >
          <span>Log Out</span>
          <i className="mdi mdi-logout ml-1" />
        </a>
      </div>

        
      <div className="p-3">
    
        <div role="separator" className="dropdown-divider" />
       
     
        <a  onClick={()=>{
          setChangePassword(true)
        }}
          className="dropdown-item py-1  px-2 d-flex align-items-center justify-content-between"
         
        >
          <span>Change Password</span>
          <i className="mdi mdi-logout ml-1" />
        </a>
      </div>


    </div>
  </li>
</ul>
<button
  className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
  type="button"
  data-toggle="offcanvas"
>
  <span className="mdi mdi-menu" />
</button>
</div>
</nav>





    {authenticated ? 


    changePassword ?
   
      
       
           <div className="container-scroller">
           <div className="container-fluid page-body-wrapper full-page-wrapper">
             <div className="content-wrapper d-flex align-items-center auth px-0">
               <div className="row w-100 mx-0">
                 <div className="col-lg-4 mx-auto">
                   <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                     <div className="brand-logo" style={{ display:"flex",justifyContent:"center",alignItems:"center" }}>
                       <img
                         src={Logo}
                         alt="logo"
                       />
                       
                     </div>
                     <h1 style={{color:"black",textAlign:"center"}}>CHANGE PASSWORD</h1>  
                   <br></br>
                     <form className="pt-3">
                       <div className="form-group">
                       <label>Enter New Password</label>  <input
                       style={{minWidth:"100%"}}
                       type="text"
                       placeholder="Enter username"
                       value={newPassword}
                       onChange={(e) => setNewPassword(e.target.value)}
         
                           className="form-control form-control-lg"
                           id="exampleInputEmail1"
                         />
                       </div>
                       <div className="form-group">
                       <label>Re Enter New Password</label>   <input
                          style={{minWidth:"100%"}}
                           className="form-control form-control-lg"
                           id="exampleInputPassword1"
                           type="password"
                           placeholder="Enter password"
                           value={newPassword1}
                           onChange={(e) => setNewPassword1(e.target.value)}
                         />
                       </div>
                       <div className="mt-3">
                         <span  type="submit" 
                           className="btn btn-block btn-primary btn-lg font-weight-medium "
                          onClick={()=>{
                            if (newPassword == newPassword1) {

                            axios.put("https://api.dremerz.net/api/kg999LoginData/"+localStorage.getItem("id")+'/',{"password":newPassword})
                            .then((res)=>{
                              alert("Password Changed")
                              localStorage.removeItem("authenticated");
                              setNewPassword("")
                              setNewPassword1("")
                              setChangePassword(false)
                              setAuthenticated(false)

                              
                            })
                            .catch((err)=>{
                              alert("Something Went Wrong")
                            })
                              
                            }
                            else{
                              alert("Password Not Matched")
                            }
                          }}
                         >
                           CHANGE
                         </span>


                         <span  type="submit"  onClick={()=>{
                           setChangePassword(false)
                         }}
                           className="btn btn-block btn-primary btn-lg font-weight-medium ">BACK</span>
                       </div>
                       
                     
                     </form>
                   </div>
                 </div>
               </div>
             </div>
             {/* content-wrapper ends */}
           </div>
         </div>
          
          :
<BrowserRouter>
  
   
<div className="container-scroller">

<div className="container-fluid page-body-wrapper">

<Sidebar></Sidebar>
<div className="main-panel" >
<div className="content-wrapper" >
  
 




  
<Routes>

    <Route index element={<NewSale></NewSale>}  />

    <Route path="test" element={<Test></Test>} />

    <Route path="fixprice" element={<PricePage></PricePage>} />
    <Route path="addstocks" element={<AddStock></AddStock>} />
    <Route path="viewstocks" element={<ViewStock></ViewStock>} />
    <Route path="addsales" element={<SaledPage></SaledPage>} />
    <Route path="viewsales" element={<ViewSale></ViewSale>} />
    <Route path="viewsalesSummary" element={<ViewSalesSummary></ViewSalesSummary>} />
    <Route path="profit" element={<ViewProfit></ViewProfit>} />
    <Route path="non-profit" element={<ViewNonProfit></ViewNonProfit>} />
    <Route path="loss" element={<ViewLoss></ViewLoss>} />
    <Route path="bill" element={<Bill></Bill>} />
    <Route path="bill1" element={<Bill1></Bill1>} />
    <Route path="newSale" element={<NewSale></NewSale>} />
    <Route path="stocksummary" element={<StockSummary></StockSummary>} />
    <Route path="saleSummary" element={<SalesSummary></SalesSummary>} />
    <Route path="saleDetails" element={<SalesDetails></SalesDetails>} />


    <Route path="addNewUser" element={<AddNewUser></AddNewUser>} />
    <Route path="viewUser" element={<ViewUser></ViewUser>} />
    <Route path="stockCostTable" element={<StockSummaryData></StockSummaryData>} />
    <Route path="AddStockQuantity" element={<AddStockQuantity></AddStockQuantity>} />

  
</Routes>
</div>


</div>
</div>
</div>
</BrowserRouter>
     : 

 
     <div className="container-scroller">
     <div className="container-fluid page-body-wrapper full-page-wrapper">
       <div className="content-wrapper d-flex align-items-center auth px-0">
         <div className="row w-100 mx-0">
           <div className="col-lg-4 mx-auto">
             <div className="auth-form-light text-left py-5 px-4 px-sm-5">
             <div className="brand-logo" style={{ display:"flex",justifyContent:"center",alignItems:"center" }}>
                       <img
                         src={Logo}
                         alt="logo"
                       />
                    
                     </div>
                   <h1 style={{color:"black",textAlign:"center"}}>LOGIN</h1>  
                   <br></br>
               <form onSubmit={handleSubmit} className="pt-3">
                 <div className="form-group">
                 <label>Username</label>  <input
                 style={{minWidth:"100%"}}
                 type="text"
                 placeholder="Enter username"
                 value={username}
                 onChange={(e) => setUsername(e.target.value)}
   
                     className="form-control form-control-lg"
                     id="exampleInputEmail1"
                   />
                 </div>
                 <div className="form-group">
                 <label>Password</label>   <input
                    style={{minWidth:"100%"}}
                     className="form-control form-control-lg"
                     id="exampleInputPassword1"
                     type="password"
                     placeholder="Enter password"
                     value={password}
                     onChange={(e) => setPassword(e.target.value)}
                   />
                 </div>
                 <div className="mt-3">
                   <button  type="submit" 
                     className="btn btn-block btn-primary btn-lg font-weight-medium "
                    
                   >
                     SIGN IN
                   </button>
                 </div>
                 
               
               </form>
             </div>
           </div>
         </div>
       </div>
       {/* content-wrapper ends */}
     </div>
   </div>
    }
    </>

  )
}
