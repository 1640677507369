import React, { useState, useEffect } from 'react';
import axios from 'axios';
import StockCostTable from '../Components/StockCostTable';
import { useNavigate } from 'react-router-dom';

export default function ViewStock() {
  const [name, setName] = useState('');
  const [formData, setFormData] = useState({
    stockType: 'gold',
    barCoinType: 'bar',
    type: '',
  });

  const tableHeaderStyle = {
    background: '#f2f2f2',
    border: '1px solid #dddddd',
    padding: '8px',
    color: 'black',
    textAlign: 'left',
    fontWeight: 'bold',
  };

  const tableCellStyle = {
    border: '1px solid #dddddd',
    color: 'black',
    padding: '8px',
    textAlign: 'left',
  };

  function formatDate(inputDate) {
    const dateParts = inputDate.split('-');
    if (dateParts.length == 3) {
      const year = dateParts[0];
      const month = dateParts[1];
      const day = dateParts[2];
      return `${day}/${month}/${year}`;
    } else {
      return "Invalid date format";
    }
  }

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [uniqueMonths, setUniqueMonths] = useState([]); // Extract unique months
  const [uniqueYears, setUniqueYears] = useState([]); // Extract unique years
  const [selectedMonth, setSelectedMonth] = useState(''); // State for selected month
  const [selectedYear, setSelectedYear] = useState(''); // State for selected year

  const [data, setData] = useState([]);
  const [stockName, setStockName] = useState([]);

  const navigate = useNavigate();
  const handleDelete = (index) => {
    setData(data.filter((item) => item.id !== index));
    axios
      .delete(`https://api.dremerz.net/api/kg999StockList/${index}/`)
      .then((response) => {
        alert('Stock data deleted successfully!');
       
      })
      .catch((error) => {
        alert('Error deleting the row:', error);
        console.error('Error deleting the row:', error);
      });
  };
  
  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key == 'F2' || event.key == '-') {
        navigate('/newSale');
      }
    }

    window.addEventListener('keydown', handleKeyDown);

    axios
      .get('https://api.dremerz.net/api/kg999StockName/')
      .then((response) => {
        setStockName(response.data);
      })
      .catch((error) => {
        console.error('Error fetching stock names from the API:', error);
      });

    axios
      .get('https://api.dremerz.net/api/kg999StockList/')
      .then((response) => {
        setLoading(true);

        let filteredData = response.data;
        if (formData.stockType == 'gold' && formData.barCoinType == 'bar') {
          filteredData = response.data.filter((item) => item.stockkey == 'Gold Bar');
        } else if (formData.stockType == 'gold' && formData.barCoinType == 'coin') {
          filteredData = response.data.filter((item) => item.stockkey == 'Gold Coin');
        }
        else if (formData.stockType == 'gold' && formData.barCoinType == 'coin 916') {
          filteredData = response.data.filter((item) => item.stockkey == 'Gold Coin 916');
        }
         else if (formData.stockType == 'silver' && formData.barCoinType == 'bar') {
          filteredData = response.data.filter((item) => item.stockkey == 'Silver Bar');
        } else if (formData.stockType == 'silver' && formData.barCoinType == 'coin') {
          filteredData = response.data.filter((item) => item.stockkey == 'Silver Coin');
        }
        if (formData.type !== '') {
          filteredData = filteredData.filter((item) => item.grams == formData.type);
        }

        if (selectedMonth !== '') {
          const separator = filteredData[0]?.date.includes('/') ? '/' : '-';
          filteredData = filteredData.filter((item) => item.date.split(separator)[1] == selectedMonth);
        }

        if (selectedYear !== '') {
          const separator = filteredData[0]?.date.includes('/') ? '/' : '-';
          filteredData = filteredData.filter((item) => item.date.split(separator)[2] == selectedYear);
        }

        if (name !== '') {
          filteredData = filteredData.filter((item) => item.name.toLowerCase().includes(name.toLowerCase()));
        }

        const months = filteredData.map((item) => item.date.split('/')[1]);
        const uniqueMonths = [...new Set(months)];
        setUniqueMonths(uniqueMonths);

        const years = filteredData.map((item) => item.date.split('/')[2]);
        const uniqueYears = [...new Set(years)];
        setUniqueYears(uniqueYears);

        setData(filteredData);
        setLoading(false);
        console.log('Stock data fetched from the API:', filteredData)
      })
      .catch((error) => {
        setError(error);
        console.error('Error fetching stock data from the API:', error);
      });

  }, [name, selectedMonth, selectedYear, formData]);



  return (
    <div>
      <h1 style={{ color: 'black', fontWeight: 'bolder' }}>VIEW STOCK DATA!</h1>
      <br />

      <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 30 }}>
        <div className="form-group" style={{ width: '250px' }}>
          <label htmlFor="stockType" style={{ color: 'black' }}>
            SELECT STOCK ( GOLD / SILVER )
          </label>
          <select
            className="form-control"
            id="stockType"
            name="stockType"
            value={formData.stockType}
            onChange={(e) => setFormData({ ...formData, stockType: e.target.value })}
          >
            <option value="gold">GOLD</option>
            <option value="silver">SILVER</option>
            <option value="all">SUMMARY</option>
          </select>
        </div>


{
  formData.stockType !== 'all'
  &&
<>
<div className="form-group" style={{ width: '250px' }}>
          <label htmlFor="barCoinType" style={{ color: 'black' }}>
            SELECT STOCK TYPE ( BAR / COIN )
          </label>
          <select
            className="form-control"
            id="barCoinType"
            name="barCoinType"
            value={formData.barCoinType}
            onChange={(e) => setFormData({ ...formData, barCoinType: e.target.value })}
          >
            <option value="bar">BAR</option>
            <option value="coin">COIN</option>
            <option value="coin 916">COIN 916</option>
          </select>
        </div>

        {formData.stockType == 'gold' &&  formData.barCoinType == 'bar'&&
          (
          <div className="form-group" style={{ width: '250px' }}>
            <label htmlFor="goldBarType" style={{ color: 'black' }}>
              SELECT GOLD BAR ( GRAM )
            </label>
            <select
              className="form-control"
              id="goldBarType"
              name="goldBarType"
              value={formData.type}
              onChange={(e) => setFormData({ ...formData, type: e.target.value })}
            >
              <option value="">ALL</option>

             {
                stockName.filter((item) => item.stockname == 'Gold Bar').map((item) => (
                  <option value={item.name} key={item.id}>{item.name} GRAM</option>
                ))

             }
            </select>
          </div>
        )}



{formData.stockType == 'gold' &&  formData.barCoinType == 'coin'&&
          (
          <div className="form-group" style={{ width: '250px' }}>
            <label htmlFor="goldCoinType" style={{ color: 'black' }}>
              SELECT GOLD COIN ( GRAM )
            </label>
            <select
              className="form-control"
              id="goldCoinType"
              name="goldCoinType"
              value={formData.type}
              onChange={(e) => setFormData({ ...formData, type: e.target.value })}
            >
              <option value="">ALL</option>

             {
                stockName.filter((item) => item.stockname == 'Gold Coin').map((item) => (
                  <option value={item.name} key={item.id}>{item.name} GRAM</option>
                ))

             }
            </select>
          </div>
        )}

   
        {formData.stockType == 'silver' && formData.barCoinType == 'bar'&&
        (
          <div className="form-group" style={{ width: '300px' }}>
            <label htmlFor="silverBarType" style={{ color: 'black' }}>
              SELECT SILVER  BAR ( GRAM )
            </label>
            <select
              className="form-control"
              id="silverBarType"
              name="silverBarType"
              value={formData.type}
              onChange={(e) => setFormData({ ...formData, type: e.target.value })}
            >
              <option value="">ALL</option>

              {
                stockName.filter((item) => item.stockname == 'Silver Bar').map((item) => (
                  <option value={item.name} key={item.id}>{item.name} GRAM</option>
                ))

             }
            </select>
          </div>
        )}


{formData.stockType == 'silver' && formData.barCoinType == 'coin'&&
        (
          <div className="form-group" style={{ width: '300px' }}>
            <label htmlFor="silverCoinType" style={{ color: 'black' }}>
              SELECT SILVER  COIN ( GRAM )
            </label>
            <select
              className="form-control"
              id="silverCoinType"
              name="silverCoinType"
              value={formData.type}
              onChange={(e) => setFormData({ ...formData, type: e.target.value })}
            >
              <option value="">ALL</option>

              {
                stockName.filter((item) => item.stockname == 'Silver Coin').map((item) => (
                  <option value={item.name} key={item.id}>{item.name} GRAM</option>
                ))

             }
            </select>
          </div>
        )}



       
<div className="form-group" style={{ width: '200px' }}>
  <label htmlFor="monthFilter" style={{ color: 'black' }}>
    Filter by Month
  </label>
  <select
    className="form-control"
    id="monthFilter"
    name="monthFilter"
    value={selectedMonth}
    onChange={(e) => setSelectedMonth(e.target.value)}
  >
    <option value="">All</option>
    {uniqueMonths.map((month) => (
      <option key={month} value={month}>
        {month}
      </option>
    ))}
  </select>
</div>


<div className="form-group" style={{ width: '200px' }}>
  <label htmlFor="yearFilter" style={{ color: 'black' }}>
    Filter by Year
  </label>
  <select
    className="form-control"
    id="yearFilter"
    name="yearFilter"
    value={selectedYear}
    onChange={(e) => setSelectedYear(e.target.value)}
  >
    <option value="">All</option>
    {uniqueYears.map((year) => (
      <option key={year} value={year}>
        {year}
      </option>
  )  )}
  </select>
</div>

<div className="form-group" style={{ width: '200px' }}>
  <label htmlFor="dateFilter" style={{ color: 'black' }}>
    Filter by Name 
  </label>
  <input style={{height:33}}
    type="text"
    className="form-control"
    id="nameFilter"
    name="nameFilter"
    value={name}
    onChange={(e) => setName(e.target.value)}
  />
</div>

</>
}


     

     
</div>


      <br />
      <br />

        {
  formData.stockType == 'all'
?
 
  <StockCostTable></StockCostTable>
 
 :
 <>



    
      {loading ? (
  <p>Loading...</p>
) : error ? (
  <p>Error: {error.message}</p>
) : (


  

<div style={{width:"100%",overflowX:"scroll",}}> 
<table style={{borderCollapse: 'collapse',overflowX:"scroll" }}>
    <thead>
      <tr>
        <th style={tableHeaderStyle}>Date</th>
        <th style={tableHeaderStyle}>Name</th>
        <th style={tableHeaderStyle}>Number</th>
        <th style={tableHeaderStyle}>Address</th>
        <th style={tableHeaderStyle}>Invoice No</th>
        <th style={tableHeaderStyle}>Stock Gram</th>
        <th style={tableHeaderStyle}>Total Stock</th>
        <th style={tableHeaderStyle}>Sold Stock</th>
        <th style={tableHeaderStyle}>Available Stock</th>
        <th style={tableHeaderStyle}>1 Piece Cost</th>
        <th style={tableHeaderStyle}>Total Cost</th>
        <th style={tableHeaderStyle}>Total TAX</th>
        <th style={tableHeaderStyle}>Actions</th>
      </tr>
    </thead>
 
 {
  data.length == 0 ? 
  <tbody><tr><td colSpan  = "12"
     style={{...tableCellStyle,textAlign:"center",color:"black",fontWeight:"bolder"}}>NO DATA FOUND</td></tr></tbody> 
    :

     <tbody>
    {data.slice().reverse().map((item, index) => (
    <tr key={index}>
      <td style={{...tableCellStyle}}>
       <input type="text" value={item.date} onChange={(e) => {
          const value = e.target.value;
          const newData = data.map((item2) => {
            if (item2.id == item.id) {
              return { ...item2, date: value };
            }
            return item2;
          }
          );
          setData(newData);
        }
        } style={{...tableCellStyle,maxWidth:"110px"}} />
        </td>
      <td style={tableCellStyle}>
        <input type="text" value={item.name} onChange={(e) => {
          const value = e.target.value;
          const newData = data.map((item2) => {
            if (item2.id == item.id) {
              return { ...item2, name: value };
            }
            return item2;
          }
          );
          setData(newData);
        }
        } style={{...tableCellStyle}} />
      </td>
      <td style={tableCellStyle}>
        <input type="text" value={item.number} onChange={(e) => {
          const value = e.target.value;
          const newData = data.map((item2) => {
            if (item2.id == item.id) {
              return { ...item2, number: value };
            }
            return item2;
          }
          );
          setData(newData);
        }
        }  style={{...tableCellStyle,maxWidth:"120px"}} />
      </td>
      <td style={tableCellStyle}>
        <input type="text" value={item.address} onChange={(e) => {
          const value = e.target.value;
          const newData = data.map((item2) => {
            if (item2.id == item.id) {
              return { ...item2, address: value };
            }
            return item2;
          }
          );
          setData(newData);
        }
        } style={{...tableCellStyle,maxWidth:"110px"}} />
      </td>
      <td style={tableCellStyle}>
        <input type="text" value={item.invoice} onChange={(e) => {
          const value = e.target.value;
          const newData = data.map((item2) => {
            if (item2.id == item.id) {
              return { ...item2, invoice: value };
            }
            return item2;
          }
          );
          setData(newData);
        }
        } style={{...tableCellStyle,maxWidth:"110px"}} />
      </td>
      <td style={tableCellStyle}>
        <input type="number" value={item.grams} onChange={(e) => {
          const value = e.target.value;
          const newData = data.map((item2) => {
            if (item2.id == item.id) {
              return { ...item2, grams: value };
            }
            return item2;
          }
          );
          setData(newData);
        }
        } style={{...tableCellStyle,maxWidth:"70px"}} />
      </td>
      <td style={tableCellStyle}>
        <input type="number" value={item.quantity} onChange={(e) => {
          const value = e.target.value;
          const newData = data.map((item2) => {
            if (item2.id == item.id) {
              return { ...item2, quantity: value };
            }
            return item2;
          }
          );
          setData(newData);
        }
        } style={{...tableCellStyle,maxWidth:"70px"}} />
      </td>
      <td style={tableCellStyle}>
        <input type="number" value={item.sold} onChange={(e) => {
          const value = e.target.value;
          const newData = data.map((item2) => {
            if (item2.id == item.id) {
              return { ...item2, sold: value };
            }
            return item2;
          }
          );
          setData(newData);
        }} style={{...tableCellStyle,maxWidth:"70px"}} />
      </td>
      <td style={tableCellStyle}>{parseInt  (item.quantity) - parseInt(item.sold)}</td>
   
       
      <td style={tableCellStyle}>{ (parseInt(item.totalcost) / parseInt(item.quantity)).toFixed(2)}</td>

      <td style={tableCellStyle}>
        <input type="text" value={item.totalcost} onChange={(e) => {
          const value = e.target.value;
          const newData = data.map((item2) => {
            if (item2.id == item.id) {
              return { ...item2, totalcost: value };
            }
            return item2;
          }
          );
          setData(newData);
        }
        } style={{...tableCellStyle,maxWidth:"100px"}} />
      </td>
      <td style={tableCellStyle}>{  (parseInt(item.totalcost) * 0.03).toFixed(2) }</td>

      <td style={tableCellStyle}>
          <div style={{display:"flex",gap:20,justifyContent:"center"}}>
        <button className='btn btn-primary' 
        onClick={
          () => {
            axios
            .put(`https://api.dremerz.net/api/kg999StockList/${item.id}/`, {
              date: item.date,
              name: item.name,
              number: item.number,
              address: item.address,
              grams: item.grams,
              quantity: item.quantity,
              sold: item.sold,
              totalcost: item.totalcost,
              invoice: item.invoice,
            })
            .then((response) => {
              alert('Stock data updated successfully!');
              console.log('Stock data updated successfully!', response.data);
            })
            .catch((error) => {
              alert('Error updating the row:', error);
              console.error('Error updating the row:', error);
            });
          }
        }

        >Update</button>
                  
           <button  className='btn btn-danger' onClick={() => handleDelete(item.id)}>Delete</button>
          </div>
                  
                </td>
    </tr>
    
  ))}
  

      <tr>
        <td colSpan="5" style={{ ...tableHeaderStyle, textAlign: 'center', color: 'black', fontWeight: 'bold' }}>
          TOTAL
        </td>
        <td  colSpan={"2"} style={{ ...tableCellStyle, color: 'black', fontWeight: 'bold', textAlign: 'center'}}>
          {(  data.reduce((sum, item) => sum + (parseFloat(item.grams) * parseFloat(item.quantity)), 0) ).toFixed(3)}
          &nbsp; GM   </td>
       
        {/* <td style={{ ...tableCellStyle, color: 'black', fontWeight: 'bold' }}>
          {data.reduce((sum, item) => sum + parseInt(item.quantity), 0)}
        </td> */}
       <td  style={{ ...tableCellStyle, color: 'black', fontWeight: 'bold', textAlign: 'center'}}>
          {(data.reduce((sum, item) => sum + (parseFloat(item.grams) * parseFloat(item.sold)), 0) ).toFixed(3) }
          &nbsp; GM   </td>

<td  style={{ ...tableCellStyle, color: 'black', fontWeight: 'bold', textAlign: 'center'}}>
          {((  data.reduce((sum, item) => sum + (parseFloat(item.grams) * parseFloat(item.quantity)), 0) ).toFixed(3) - (data.reduce((sum, item) => sum + (parseFloat(item.grams) * parseFloat(item.sold)), 0) ).toFixed(3)).toFixed(3) }
     &nbsp; GM  </td>

 
{/* 

        <td style={{ ...tableCellStyle, color: 'black', fontWeight: 'bold' }}>
          {data.reduce((sum, item) => sum + parseInt(item.quantity), 0) - data.reduce((sum, item) => sum + parseInt(item.sold), 0)}
        </td> */}


        {/* <td style={{ ...tableCellStyle, color: 'black', fontWeight: 'bold' }}>
          {data.reduce((sum, item) => sum + parseInt(item.sold), 0)}
        </td> */}
        <td style={{ ...tableCellStyle, color: 'black', fontWeight: 'bold' }}></td>
        <td style={{ ...tableCellStyle, color: 'black', fontWeight: 'bold' }}>
          {data.reduce((sum, item) => sum + parseInt(item.totalcost), 0)}
        </td>
        <td style={{ ...tableCellStyle, color: 'black', fontWeight: 'bold' }}>
          {(data.reduce((sum, item) => sum + parseInt(item.totalcost), 0) * 0.03).toFixed(2)}
        </td>
      </tr>

    </tbody>
 }

  </table>
</div>

  
)
}

 </>
}




<br />
<br />
<br />
<br />
<br />
<br />





    </div>
  );
}
