import axios from 'axios'
import React, { useEffect } from 'react'

export default function Test() {


    useEffect(()=>{
        axios.get('https://kg999price.dremerz.net/updatePrice')
        .then(res=>console.log(res))
        .catch(err=>console.log(err))
    },[])
  return (
    <div>test</div>
  )
}
