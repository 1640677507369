import React, { useEffect, useState } from 'react';
import axios from 'axios';

export default function SquareOffPage() {
  const [formData, setFormData] = useState({
    amount: '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };



  const handleSubmit = () => {
    const apiUrl = 'https://api.dremerz.net/api/kg999SquareOffAmount/1/';

    // Determine the appropriate key for the selected gold bar type
 

    // Prepare the data to be sent to the API
    const postData = {
        amount: formData.amount
    };

    // Send the data to the API endpoint
    axios.put(apiUrl, postData)
      .then((response) => {
        alert('Data added successfully');
      })
      .catch((error) => {
        alert('Error adding data to the API');
      });
  };




  
  useEffect(()=>{
    axios.get('https://api.dremerz.net/api/kg999SquareOffAmount/1/')
    .then((response) => {
      setFormData({amount:response.data.amount});
    });
  },[])

  return (
  
 
      <div className="col-md-6 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">EDIT SQUARE OFF AMOUNT</h4>

            <form className="forms-sample">
              <div className="form-group" style={{width:"100%"}}>
                <label htmlFor="name">EDIT SQUARE OFF AMOUNT</label>
                <input
                style={{width:"100%"}}
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  value={formData.amount}
                  onChange={(event) =>{
                    setFormData({
                      ...formData,
                      amount: event.target.value,
                    });
                  } }
                  placeholder="SQUARE OFF AMOUNT"
                />
              </div>

        
              <span type="submit" className="btn btn-primary mr-2" onClick={handleSubmit}>
                Submit
              </span>
              <span className="btn btn-light">Cancel</span>
            </form>
          </div>
        </div>
      </div>
   
  );
}





