import React, { useState, useEffect } from 'react';
import axios from 'axios';

export default function SilverCoinPrice() {
  const [formData, setFormData] = useState([]);
  

  const apiUrl = 'https://api.dremerz.net/api/kg999StockName/';

  useEffect(() => {
    axios.get(apiUrl)
      .then((response) => {
        console.log(response.data);
        //MAKE A filter to get only Silver COIN
        response.data = response.data.filter((item) => item.stockname == 'Silver Coin');
        setFormData(response.data ); 
      
      })
      .catch((error) => {
        console.error('Error fetching data from the API:', error);
      });
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const newFormData = formData.map((item) => {
      if (item.name == name) {
        return {
          ...item,
          cost: value,
        };
      }
      return item;
    });
    setFormData(newFormData);
  }

  const handleSubmit = () => {
    // Display a confirmation dialog
    const isConfirmed = window.confirm('Are you sure you want to update the data?');

    if (isConfirmed) {
      // User confirmed, proceed with the update
      // Send the updated data to the API
     formData.forEach((item) => {
        axios.put(`https://api.dremerz.net/api/kg999StockName/${item.id}/`, item)
          .then((response) => {
            // Handle the response, e.g., show a success message
            //alet should be shown only once

              if(item.name == formData[formData.length-1].name){
                  alert('Data updated successfully');
              }
          })
          .catch((error) => {
            alert('Error updating data in the API:');
          });
      });
    } else {
      // User canceled the update
      console.log('Data update canceled');
    }
  }

  return (


      <div className="col-md-6 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">SILVER COIN COST </h4>
            <br />
            <form className="forms-sample">
              <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '5%' }}>
                {formData.length >0 &&
                 formData.map((key) => (
                    <div className="form-group" style={{ width: '45%' }}>
                      <label htmlFor="name">{(key.name +  ' GRAM SILVER COIN COST' )    }</label>
                      <input
                        style={{ width: '100%' }}
                        type="text"
                        className="form-control"
                        id="name"
                        name={key.name}
                        value={key.cost}
                        onChange={handleInputChange}
                        placeholder={(key.name +  ' GRAM SILVER COIN COST' ) }
                      />
                    </div>
                ))}
              </div>
              <div className="form-check form-check-flat form-check-primary"></div>
              <span className="btn btn-primary mr-2" style={{ marginLeft: "80%" }} onClick={handleSubmit}>
                UPDATE
              </span>
             
            </form>
          </div>
        </div>
      </div>
    

  );
}
