import React, { useEffect, useState } from 'react';
import axios, { all, formToJSON } from 'axios';
import { useNavigate } from 'react-router-dom';
import Logo from "../logo.png";
import { Puff as Loader } from 'react-loader-spinner';

import Select from "react-select";


import { getStorage, ref, getDownloadURL,   uploadBytes } from 'firebase/storage';
import { getFirestore, collection, getDocs,addDoc} from 'firebase/firestore/lite';
import { initializeApp } from 'firebase/app'; 


import { Document, Page, Text, View, StyleSheet, Image, Font,pdf } from '@react-pdf/renderer';

import  CustomFont from "../times.ttf";

export default function NewSale() {
  const [loading, setLoading] = useState(false);
  const [data21,setdata21]=useState([]);
  const [disc,setDisc]=useState(0);
  const [StockToDecrease,setStockToDecrease]=useState([]);

  const [totalDiscount,setTotalDiscount]=useState(0);
  const firebaseConfig = {
    apiKey: "AIzaSyCGMDhQP-EAnFmlR9r1hFeZB1S4BFpc3rQ",
    authDomain: "sam-hyper-business-682ea.firebaseapp.com",
    projectId: "sam-hyper-business-682ea",
    storageBucket: "sam-hyper-business-682ea.appspot.com",
    messagingSenderId: "707629335149",
    appId: "1:707629335149:web:1edbe7ab6c476cee02f564",
    measurementId: "G-WLYSXR186X"
  };

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const storage = getStorage(app);


  const [stockName,setStockName]=useState([]);
  const [stockList,setStockList] = useState([]);


    const [stockData, setStockData] = useState();
    const [filteredstockData, setfilteredStockData] = useState();
    const[salesuess,setSalesuess]=useState(false);
    const [clientData, setClientData] = useState([]);
    const navigate = useNavigate();

  const [apiUrl, setApiUrl] = useState('https://api.dremerz.net/api/kg999GoldBarStockList/');
  const tableHeaderStyle = {
    background: '#f2f2f2',
    border: '1px solid #dddddd',
    padding: '8px',
    color: 'black',
    textAlign: 'left',
    fontWeight: 'bold',
  };

  const tableCellStyle = {
    border: '1px solid #dddddd',
    color: 'black',
    padding: '8px',
    textAlign: 'left',
  };
  const [paid,setPaid]=useState(0);
  const [paid1,setPaid1]=useState(0);
  const [balance,setBalance]=useState(0);
  const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
  function formatDate(dateString) {
    const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-GB', options);
  }
  const [invoiceNumber,setInvoiceNumber]=useState(0);
  const [paymentMode1,setPaymentMode1]=useState('cash');
  const [paymentMode2,setPaymentMode2]=useState('');
  const [formData, setFormData] = useState({
    date: new Date().toLocaleDateString('en-GB', options),
    invoice: 0,
    name: '',
    address: '',
    aadharotpan: '',
    number: '',
    quantity: '',
    grams: '',
    byuDate: '',
    "soldBy": "Admin",
    stockType: 'gold', // Default to 'gold'
    amount: 0,
    discount: 0,
    totalAmount: 0,
    previousAmount: 0,
    "paymentMode1": paymentMode1,
    "paymentMode2": paymentMode2,
    "paymentMode1Amount": paid,
    "paymentMode2Amount": paid1,
    "code": "",
  });
  const [quantity, setquantity] = useState('half_stock');
  const [sold, setsold] = useState('half_sold');
  const [goldpriceKey, setGoldPriceKey] = useState('half_price');

  const [silverstockKey, setSilverStockKey] = useState('ten_stock');
  const [silversoldKey, setSilverSoldKey] = useState('ten_sold');
  const [silverpriceKey, setSilverPriceKey] = useState('ten_price');


  const [stockkey ,setStockKey]=useState('');

const[GoldBarPrice,setGoldBarPrice]=useState();
const[SilverBarPrice,setSilverBarPrice]=useState();
const[GoldCoinPrice,setGoldCoinPrice]=useState();
const[SilverCoinPrice,setSilverCoinPrice]=useState();  



const [error,setError]=useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };






  const callback = (data, quantity) => {
    console.log("b4 callback data", data);
  
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      console.log("element", element);
  
      const stockKey ='quantity';
      const soldKey ='sold';
      const priceKey ='totalcost';
      if (parseInt(element[stockKey]) - parseInt(element[soldKey]) >= parseInt(quantity)) {
        element[soldKey] = parseInt(element[soldKey]) + parseInt(quantity);
        formData.previousAmount +=
          (parseInt(element[priceKey]) / parseFloat(element[stockKey])) * parseInt(quantity);
          setSalesuess(true);
        formData.byuDate = element.date;
        formData.grams  = stockkey;
        
        alert("stock deducted");
        index = data.length;
        
      } else if (parseInt(element[stockKey]) - parseInt(element[soldKey]) !== 0) {
        const q = quantity - (parseInt(element[stockKey]) - parseInt(element[soldKey]));
        element[soldKey] = parseInt(element[stockKey]);
        formData.grams  = stockkey;
  
        formData.previousAmount +=
          (parseInt(element[priceKey]) / parseFloat(element[stockKey])) * parseInt(element[quantity]);
  
        callback(data, q);
        index = data.length;
      }
    }
  



    setdata21(data);

    
    
    // data.map((item) => {
    //   axios.put(`${'https://api.dremerz.net/api/kg999StockList/'}${item.id}/`, item);
    // }
    // )

    data.map((item) => {
      StockToDecrease.push(item);
    }
    )

formData.code = stockName.filter((item) => item.stockname == formData.stockType) .filter((item) => item.name == stockkey)[0].number;

    setClientData([...clientData, formData]);

    console.log("clientData", clientData);
  setDisc(0);
    setPaid(0);
    setBalance(0);
    setFormData({
      ...formData,
      amount: 0,
      stockType: 'gold',
      discount: 0,
      totalAmount: 0,
      previousAmount: 0,
      quantity: '',
      invoice: parseInt(formData.invoice),
    });
  
  
  };
  




  const   postData = () => {

setLoading  (true);

    if (formData.name == '' || formData.number == '' || formData.address == '' ||  formData.quantity == 0 ||  formData.quantity == ''   ) {
     
      setLoading(false);
       alert('Please enter all the field values.');
     
      return;
    }

    setFormData({...formData, previousAmount:0});

setError('');    
setSalesuess(false);



if (formData.stockType == 'gold') {
  const filteredStock = stockList
    .filter((item) => item.stockkey == 'Gold Bar')
    .filter((item) => item.grams == stockkey);
formData.stockType = 'Gold Bar';
console.log("filtered gold", filteredStock);
console.log(stockName.filter((item) => item.stockname == 'Gold Bar') .filter((item) => item.name == stockkey)[0].number)
formData.code = stockName.filter((item) => item.stockname == 'Gold Bar') .filter((item) => item.name == stockkey)[0].number;
  var totalStock = 0;
  filteredStock.forEach((item) => {
    totalStock += parseInt(item.quantity) - parseInt(item.sold);
  });
  console.log("totalStock", totalStock)
  if (totalStock >= formData.quantity) {
    console.log("b4 call back data", filteredStock);
 
    setFormData({...formData, code: stockName.filter((item) => item.stockname == 'Gold Bar') .filter((item) => item.name == stockkey)[0].number});

    callback(filteredStock, formData.quantity);
  } else {
     setLoading(false);
    setError("Not enough stock");
  }
}

if (formData.stockType == 'silver') {
  const filteredStock = stockList
    .filter((item) => item.stockkey == 'Silver Bar')
    .filter((item) => item.grams == stockkey);
  formData.stockType = 'Silver Bar';
  console.log("filtered silver", filteredStock);
  setFormData({...formData, code: stockName.filter((item) => item.stockname == 'Silver Bar') .filter((item) => item.name == stockkey)[0].number});


  var totalStock = 0;
  filteredStock.forEach((item) => {
    totalStock += parseInt(item.quantity) - parseInt(item.sold);
  });
  console.log("totalStock", totalStock)
  if (totalStock >= formData.quantity) {
    console.log("b4 call back data", filteredStock);
    callback(filteredStock, formData.quantity);
  } else {
     setLoading(false);
    setError("Not enough stock");
  }
}

if (formData.stockType == 'gold-coin') {
  const filteredStock = stockList
    .filter((item) => item.stockkey == 'Gold Coin')
    .filter((item) => item.grams == stockkey);
  formData.stockType = 'Gold Coin';
  console.log("filtered gold", filteredStock);
  setFormData({...formData, code: stockName.filter((item) => item.stockname == 'Gold Coin') .filter((item) => item.name == stockkey)[0].number});


  var totalStock = 0;
  filteredStock.forEach((item) => {
    totalStock += parseInt(item.quantity) - parseInt(item.sold);
  });
  console.log("totalStock", totalStock)
  if (totalStock >= formData.quantity) {
    console.log("b4 call back data", filteredStock);
    callback(filteredStock, formData.quantity);
  } else {
     setLoading(false);
    setError("Not enough stock");
  }
}

if (formData.stockType == 'silver-coin') {
  const filteredStock = stockList
    .filter((item) => item.stockkey == 'Silver Coin')
    .filter((item) => item.grams == stockkey);
  formData.stockType = 'Silver Coin';
  setFormData({...formData, code: stockName.filter((item) => item.stockname == 'Silver Coin') .filter((item) => item.name == stockkey)[0].number});

  var totalStock = 0;
  filteredStock.forEach((item) => {
    totalStock += parseInt(item.quantity) - parseInt(item.sold);
  });
  console.log("totalStock", totalStock)
  if (totalStock >= formData.quantity) {
    console.log("b4 call back data", filteredStock);
    callback(filteredStock, formData.quantity);
  } else {
     setLoading(false);
    setError("Not enough stock");
  }
}
if (formData.stockType == 'gold-coin916') {
  const filteredStock = stockList
    .filter((item) => item.stockkey == 'Gold Coin 916')
    .filter((item) => item.grams == stockkey);
  formData.stockType = 'Gold Coin 916';
  console.log("filtered gold", filteredStock);
  setFormData({...formData, code: stockName.filter((item) => item.stockname == 'Gold Coin 916') .filter((item) => item.name == stockkey)[0].number});


  var totalStock = 0;
  filteredStock.forEach((item) => {
    totalStock += parseInt(item.quantity) - parseInt(item.sold);
  });
  console.log("totalStock", totalStock)
  if (totalStock >= formData.quantity) {
    console.log("b4 call back data", filteredStock);
    callback(filteredStock, formData.quantity);
  } else {
     setLoading(false);
    setError("Not enough stock");
  }
}

        setLoading(false);
    }




    useEffect(()=>{

      axios.get('https://api.dremerz.net/api/kg999StockName/')
      .then((response) => {
        setStockName(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data from the API:', error);
      });


      axios.get('https://api.dremerz.net/api/kg999StockList/')
      .then((response) => {
        setStockList(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data from the API:', error);
      });


      const isUserAuthenticated = localStorage.getItem("authenticated");

      if (isUserAuthenticated == "true") {
        const username = localStorage.getItem("username");
      
        if (username) {
          
          formData.soldBy = username;

        } else {
           setLoading(false);
          console.error("Username is not available in local storage.");
        }
      }
      
   
      axios.get('https://api.dremerz.net/api/kg999SquareOffAmount/1/')
      .then((response) => {
        setFormData({ ...formData,invoice: (parseInt(response.data.invoice)+1 )});
      })



    },
     [formData.stockType]);







     Font.register({ family: 'CustomFont', src: CustomFont });

 

     const ones = ['Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
     const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
 
     function NumberToWords(number) {
       if (number == 0) return 'Zero';
   
       let words = [];
   
       const crore = Math.floor(number / 10000000);
       number -= crore * 10000000;
       const lakh = Math.floor(number / 100000);
       number -= lakh * 100000;
       const thousand = Math.floor(number / 1000);
       number -= thousand * 1000;
       const hundred = Math.floor(number / 100);
       number -= hundred * 100;
   
       if (crore > 0) words.push(convertBelowThousand(crore) + ' Crore');
       if (lakh > 0) words.push(convertBelowThousand(lakh) + ' Lakh');
       if (thousand > 0) words.push(convertBelowThousand(thousand) + ' Thousand');
       if (hundred > 0) words.push(convertBelowThousand(hundred) + ' Hundred');
   
       if (number > 0) words.push(convertBelowHundred(number));
   
       return words.join(' ').trim();
   }
   
   function convertBelowHundred(number) {
   
       if (number < 20) return ones[number];
       const digit = number % 10;
       if (number < 100) return tens[Math.floor(number / 10)] + (digit ? ' ' + ones[digit] : '');
       return '';
   }
   
   function convertBelowThousand(number) {
       if (number < 100) return convertBelowHundred(number);
       const rest = number % 100;
       const hundredPart = Math.floor(number / 100);
       return ones[hundredPart] + ' Hundred' + (rest ? ' ' + convertBelowHundred(rest) : '');
   } // Output: "One Lakh One Thousand Six Hundred Sixty One"
     
     
 let data  = clientData
 const styles = StyleSheet.create({
  
  section: {
     
  },
  table: {
    display: 'table',
    width: '100%',
    // borderWidth: 0.5,
    // borderColor: '#000',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 0.5,
    borderBottomColor: '#000',
    borderTopWidth: 0.5,
    borderTopColor: '#000',
    borderLeftWidth: 0.5,
    borderLeftColor: '#000',
    borderRightWidth: 0.5,
    borderRightColor: '#000',

  },   
   tableRowA: {
    flexDirection: 'row',
    // borderWidth: 0.5,
    // borderColor : '#000',
    borderLeftWidth: 0.5,
    borderRightWidth: 0.5,
    borderColor: '#000',
    
  },
  tableRowAA: {
    flexDirection: 'row',
    // borderWidth: 0.5,
    // borderColor : '#000',
    borderLeftWidth: 0.5,
    borderRightWidth: 0.5,
    borderBottomWidth: 0.5,
    borderColor: '#000',
    
  },
  tableCell: {
    width: '15%',
    borderStyle: 'solid',
    padding: 8,
    fontSize:11,
    fontFamily:"CustomFont",
    fontWeight:"extrabold",
    alignContent:"center",
    textAlign:"right",
    borderLeftWidth: 0.5,
    borderLeftColor: '#000',


  },


  tableCellWW: {
    width: '15%',
    borderStyle: 'solid',
    padding: 8,
    fontSize:11,
    fontFamily:"CustomFont",
    fontWeight:"extrabold",
    alignContent:"center",
    textAlign:"right",


  },

  tableCellW: {
    width: '85%',
    borderStyle: 'solid',
    padding: 8,
    fontSize:11,
    fontFamily:"CustomFont",
    fontWeight:"extrabold",
    alignContent:"center",
    textAlign:"left",


  },
  tableCellA: {
    width: '10%',
    borderStyle: 'solid',
    padding: 8,
    fontSize:11,
    fontFamily:"CustomFont",
    fontWeight:"extrabold",
    alignContent:"left",
    textAlign:"left",
  },
  tableCellB: {
    width: '35%',
    borderStyle: 'solid',
    padding: 8,
    alignContent:"center",
    textAlign:"center",
    fontSize:11,
    fontFamily:"CustomFont",
    fontWeight:"extrabold", alignContent:"left",
    textAlign:"left",
    borderLeftWidth: 0.5,
    borderLeftColor: '#000',
  },

  tableCellBW: {
    width: '25%',
    borderStyle: 'solid',
    padding: 8,
    alignContent:"center",
    textAlign:"center",
    fontSize:11,
    fontFamily:"CustomFont",
    fontWeight:"extrabold", alignContent:"left",
    textAlign:"left",
  },
  tableCell1: {
      width: '20%',
      borderStyle: 'solid',
      padding: 8,
      fontSize:10,
      fontFamily:"CustomFont", alignContent:"center",
      textAlign:"center",
    },
});
  const tax = (parseFloat(((data.reduce((a,b)=>a+parseFloat(b.amount),0)).toFixed(2)) - (totalDiscount - totalDiscount *0.03)) * 0.03).toFixed(2);

  const amount  = (parseFloat(((data.reduce((a,b)=>a+parseFloat(b.amount),0)).toFixed(2)) - (totalDiscount - totalDiscount *0.03))).toFixed(2);
  
  const grandTotal = (parseFloat(amount) + parseFloat(tax)).toFixed(0);
  let discountDisplayed = false;
  const MyDocument = () => (

    data &&
      data.length > 0?
      <Document>
      <Page size="A4" style={{ flex: 1, padding: 15 }}>
        <View style={{ borderWidth: 0.5, padding: 10, height: "100%", width: "100%", borderColor: "black", borderRadius: 7 }}>
  
          <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
  
            <View style={{ backgroundColor: "#5E17EB", borderRadius: 0 ,}}>
              <Text style={{ color: "white", fontSize: 9, paddingHorizontal: 3, paddingVertical: 3, fontFamily:"CustomFont" }}>TAX INVOICE</Text>
            </View>
            <View>
              <Text style={{ fontSize: 10, color: "#FF914D", textAlign: "right",  width: "40%",fontFamily:"CustomFont" }}>GST IN : 37BKOPS7684H2Z5C</Text>
            </View>
          </View>
  
          <View style={{ flexDirection: "row", justifyContent: "center", marginTop: 20, alignItems: "center" }}>
            <Image src={Logo} style={{ width: 350, height: 120 }}></Image>
          </View>
          <View style={{ marginTop: 10 }}></View>
  
  
  
          <View style={{ borderTopWidth: 0.5, width: "100%", borderColor: "black", borderRadius: 7 }}></View>
          <View style={{ marginTop: 10 }}></View>
  
  <View style={{flexDirection:"row",marginTop:10,width:"100%"}}>
  
  <View style={{width:"100%",flexDirection:"row",gap:15,justifyContent:"space-between"}}>
  
  <Text style={{fontFamily:"CustomFont",fontSize:11,textAlign:"left",flex:1,}}>NAME</Text>
  <Text style={{fontFamily:"CustomFont",fontSize:11,textAlign:"left"}}>:</Text>
    <Text style={{fontFamily:"CustomFont",fontSize:10,textAlign:"left",flex:3}}>{data[0].name.toUpperCase()}</Text>
  </View>
  
  <View style={{width:"100%",flexDirection:"row",backgroundColor:"",gap:15,justifyContent:"space-between"}}>
  
  <Text style={{fontFamily:"CustomFont",fontSize:11,textAlign:"right",flex:5,}}>DATE</Text>
  <Text style={{fontFamily:"CustomFont",fontSize:11,textAlign:"left"}}>:</Text>
  
    <Text style={{fontFamily:"CustomFont",fontSize:10,textAlign:"right",flex:1}}>{data[0].date}</Text>
  </View>
  
    </View>
  
  
  
  
    <View style={{flexDirection:"row",marginTop:10,width:"100%"}}>
  
  <View style={{width:"100%",flexDirection:"row",gap:15,justifyContent:"space-between"}}>
  
  <Text style={{fontFamily:"CustomFont",fontSize:11,textAlign:"left",flex:1}}>ADDRESS</Text>
  <Text style={{fontFamily:"CustomFont",fontSize:11,textAlign:"left"}}>:</Text>
  
    <Text style={{fontFamily:"CustomFont",fontSize:10,textAlign:"left",flex:3}}>{data[0].address.toUpperCase()}</Text>
  </View>
  
  <View style={{width:"100%",flexDirection:"row",backgroundColor:"",gap:15,justifyContent:"space-between"}}>
  
  <Text style={{fontFamily:"CustomFont",fontSize:11,textAlign:"right",flex:5,}}>INVOICE NO</Text>
  <Text style={{fontFamily:"CustomFont",fontSize:11,textAlign:"left",}}>:</Text>
  
    <Text style={{fontFamily:"CustomFont",fontSize:10,textAlign:"right",flex:1}}>{data[0].invoice}</Text>
  </View>
  
    </View>
  
  
    <View style={{flexDirection:"row",marginTop:10,width:"100%"}}>
  
  <View style={{width:"100%",flexDirection:"row",gap:15,justifyContent:"space-between"}}>
  
  <Text style={{fontFamily:"CustomFont",fontSize:11,textAlign:"left",flex:1}}>NUMBER</Text>
  <Text style={{fontFamily:"CustomFont",fontSize:11,textAlign:"left"}}>:</Text>
  
    <Text style={{fontFamily:"CustomFont",fontSize:10,textAlign:"left",flex:3}}>{data[0].number}</Text>
  </View>
  




    {
      data[0].aadharotpan.length>=15?    <View style={{width:"100%",flexDirection:"row",backgroundColor:"",gap:15,}}>
      <Text style={{fontFamily:"CustomFont",fontSize:11,textAlign:"right",flex:1,}}>GST IN</Text>
      <Text style={{fontFamily:"CustomFont",fontSize:11,textAlign:"left"}}>:</Text>
      
        <Text style={{fontFamily:"CustomFont",fontSize:10,textAlign:"left",}}>{data[0].aadharotpan}</Text>
      </View>:

<View style={{width:"100%",flexDirection:"row",backgroundColor:"",gap:15,}}>
<Text style={{fontFamily:"CustomFont",fontSize:11,textAlign:"right",flex:1,}}></Text>
<Text style={{fontFamily:"CustomFont",fontSize:11,textAlign:"left"}}></Text>

  <Text style={{fontFamily:"CustomFont",fontSize:10,textAlign:"left",}}></Text>
</View>
    }

  
    </View>
  
    <View style={{marginTop:10}}></View>
  
    {/* <View style={{ borderTopWidth: 0.5, width: "100%", borderColor: "black", borderRadius: 7 }}></View> */}
  
  
  
    <View style={{marginTop:10}}></View>
    <View style={{ marginTop: 10 }}></View>
  
  
    <View style={styles.section}>
        
        <View style={styles.table}>
          {/* Table Header */}
          <View style={styles.tableRow}>
            <View style={styles.tableCellA}>
              <Text>S.N.O</Text>
            </View>
            <View style={styles.tableCellB}>
              <Text>DESCRIPTION</Text>
            </View>
            <View style={styles.tableCell}>
              <Text>HSN / SAC CODE</Text>
            </View> 
            <View style={styles.tableCell}>
              <Text>UNITS</Text>
            </View>
            <View style={styles.tableCell}>
              <Text>GRAMS</Text>
            </View>
            <View style={styles.tableCell}>
              <Text>RATE PER UNIT</Text>
            </View>
            <View style={styles.tableCell}>
              <Text>AMOUNT</Text>
            </View>
          </View>
  
  
  
  {
  data.map((item,index)=>(
    <View style={styles.tableRowA}>
    <View style={styles.tableCellA}>
      <Text>{index+1}</Text>
    </View>
    <View style={styles.tableCellB}>
      <Text>{item.stockType.toUpperCase()}  {item.grams} GRAM</Text>
    </View>
    <View style={styles.tableCell}>
      <Text>{item.code}</Text>
    </View> 
    <View style={styles.tableCell}>
      <Text>{item.quantity}</Text>
    </View>
    <View style={styles.tableCell}>
      <Text>{item.grams}</Text>
    </View>
    <View style={styles.tableCell}>
      <Text>{(parseFloat(item.amount)/ parseInt(item.quantity)).toFixed(2)}</Text>
    </View>
    <View style={styles.tableCell}>
      <Text>{((parseFloat(item.amount)/ parseInt(item.quantity))* parseFloat(item.quantity)).toFixed(2)}</Text>
    </View>
  </View>
  ))
  }
  
  {

  data.map((item, index) => {
    if ((item.discount != "0" || item.discount != 0) && !discountDisplayed) {
      discountDisplayed = true;

      return (
        <View key={index} style={styles.tableRowA}>
          <View style={styles.tableCellA}>
            <Text></Text>
          </View>
          <View style={styles.tableCellB}>
            <Text>DISCOUNT</Text>
          </View>
          <View style={styles.tableCell}>
            <Text></Text>
          </View>
          <View style={styles.tableCell}>
            <Text></Text>
          </View>
          <View style={styles.tableCell}>
            <Text></Text>
          </View>
          <View style={styles.tableCell}>
            <Text></Text>
          </View>
          <View style={styles.tableCell}>
            <Text>-{((data.reduce((a, b) => a + parseFloat(b.discount), 0))).toFixed(2)}</Text>
          </View>
        </View>
      );
    }
    return null;
  })
}



  
  <View style={styles.tableRowA}>
  <View style={styles.tableCellA}>
    <Text></Text>
  </View>
  <View style={styles.tableCellB}>
    <Text></Text>
  </View>
  <View style={styles.tableCell}>
    <Text></Text>
  </View>
  <View style={styles.tableCell}>
    <Text></Text>
  </View>
  <View style={styles.tableCell}>
    <Text></Text>
  </View>
  <View style={styles.tableCell}>
    <Text></Text>
  </View>
  <View style={styles.tableCell}>
    <Text></Text>
  </View>
  </View>
  <View style={styles.tableRowA}>
  <View style={styles.tableCellA}>
    <Text></Text>
  </View>
  <View style={styles.tableCellB}>
    <Text>GROSS AMOUNT</Text>
  </View>
  <View style={styles.tableCell}>
    <Text></Text>
  </View>
  <View style={styles.tableCell}>
    <Text></Text>
  </View>
  <View style={styles.tableCell}>
    <Text></Text>
  </View>
  <View style={styles.tableCell}>
    <Text></Text>
  </View>
  <View style={styles.tableCell}>
  <Text>
  {
  ((  (((
  
  (data.reduce((a,b)=>a+parseFloat(b.amount),0))).toFixed(2)))
  - (((data.reduce((a,b)=>a+parseFloat(b.discount),0))).toFixed(2))).toFixed(2))
  }
  
  </Text>
  
  </View>
  </View>
  
  
  
  
  
  <View style={styles.tableRowA}>
  <View style={styles.tableCellA}>
    <Text></Text>
  </View>
  <View style={styles.tableCellB}>
    <Text>CGST</Text>
  </View>
  <View style={styles.tableCell}>
    <Text></Text>
  </View>
  <View style={styles.tableCell}>
    <Text></Text>
  </View>
  <View style={styles.tableCell}>
    <Text></Text>
  </View>
  <View style={styles.tableCell}>
    <Text>( 1.5% )</Text>
  </View>
  <View style={styles.tableCell}>
  
  <Text>  {
  ((  (((
  
  (data.reduce((a,b)=>a+parseFloat(b.amount),0)) ).toFixed(2)))
  - (((data.reduce((a,b)=>a+parseFloat(b.discount),0))).toFixed(2)))*0.015).toFixed(2)
  }</Text>
  
  </View>
  </View>
  
  <View style={styles.tableRowA}>
  <View style={styles.tableCellA}>
    <Text></Text>
  </View>
  <View style={styles.tableCellB}>
    <Text>SGST</Text>
  </View>
  <View style={styles.tableCell}>
    <Text></Text>
  </View>
  <View style={styles.tableCell}>
    <Text></Text>
  </View>
  <View style={styles.tableCell}>
    <Text></Text>
  </View>
  <View style={styles.tableCell}>
    <Text>( 1.5% )</Text>
  </View>
  <View style={styles.tableCell}>
  <Text>  {
  ((  (((
  
  (data.reduce((a,b)=>a+parseFloat(b.amount),0)) ).toFixed(2) ))
  - (((data.reduce((a,b)=>a+parseFloat(b.discount),0))).toFixed(2)))*0.015).toFixed(2)
  }</Text>
   </View>
  </View>







 <View style={styles.tableRowA}>
  <View style={[styles.tableCellA,{borderBottomWidth:0.5,borderBottomColor:"grey"}]}>
    <Text></Text>
  </View>
  <View style={[styles.tableCellB,{borderBottomWidth:0.5,borderBottomColor:"grey"}]}>
    <Text></Text>
  </View>
  <View style={[styles.tableCell,{borderBottomWidth:0.5,borderBottomColor:"grey"}]}>
    <Text></Text>
  </View>
  <View style={[styles.tableCell,{borderBottomWidth:0.5,borderBottomColor:"grey"}]}>
    <Text></Text>
  </View>
  <View style={[styles.tableCell,{borderBottomWidth:0.5,borderBottomColor:"grey"}]}>
    <Text></Text>
  </View>
  <View style={[styles.tableCell,{borderBottomWidth:0.5,borderBottomColor:"grey"}]}>
    <Text></Text>
  </View>
  <View style={[styles.tableCell,{borderBottomWidth:0.5,borderBottomColor:"grey"}]}>
    <Text></Text>
  </View>
  </View>





  <View style={styles.tableRowAA}>

  <View style={styles.tableCellBW}>
    <Text>TOTAL AMOUNT</Text>
  </View>
  <View style={[styles.tableCellW]}>
    <Text style={{fontWeight:"extrabold"}}>{NumberToWords( parseFloat(grandTotal).toFixed(0)
    ).toUpperCase()} RUPEES</Text>
  </View>

  <View style={styles.tableCellWW}>
  <Text>
  {
  grandTotal
  }.00
  </Text>
    </View>
  </View>
  
  




  
  
  
  
          <View style={{marginTop:10}}></View>
  
  
  
  
        </View>
  
  
  
      </View>
  
          {/* <View style={{ borderTopWidth: 0.5, width: "100%", borderColor: "black", borderRadius: 7 }}></View> */}
          <View style={{marginTop:10}}></View>

  
  
  <View style={{position:"absolute",bottom:70,width:"100%"}}>
  <View style={{flexDirection:"row",justifyContent:"space-between"}}> 
    <Text style={{fontFamily:"CustomFont",fontSize:11,textAlign:"center",paddingLeft:20}}>SIGNATURE OF THE PARTY</Text>
    <Text style={{fontFamily:"CustomFont",fontSize:11}}>KG999 AUTHORIZED SIGNATURE</Text>
  </View>
  </View>
  
  
  <View style={{position:"absolute",bottom:10,width:"100%"}}>
    <Text style={{fontFamily:"CustomFont",fontSize:9,textAlign:"center",color:"#800000",paddingLeft:15}}>DOOR NO 27-1-97/2, RAMJI NAGAR, NEAR MORE SUPER MARKET, CHILDRENS PARK ROAD, NELLORE 524002, ANDHRA PRADESH</Text>
    <Text style={{fontFamily:"CustomFont",fontSize:9,textAlign:"center",color:"#800000",paddingLeft:15}}>CONTACT NUMBER : +91 888 555 8228 </Text>
  
  </View>
  
        </View>
      </Page>
    </Document>
      :
      <div>
        <h1>NO DATA FOUND!</h1>
      </div>
    
  
  
  );
 

   const quantitychange = () => {
    let amount = 0;
     if(stockkey=='' || stockkey==undefined) {
                
    return  alert("Please select stock type")
      }

   if (stockName.length > 0 )
   {
    if (formData.stockType == 'gold') {
      amount = formData.quantity *( ( stockName.filter(item=>item.stockname == 'Gold Bar').filter(item=>item.name == stockkey)[0].cost )/1.03).toFixed(2);
    }
    else if (formData.stockType == 'gold-coin') {
      amount = formData.quantity * (  (stockName.filter(item=>item.stockname == 'Gold Coin').filter(item=>item.name == stockkey)[0].cost )/1.03).toFixed(2);
    }
    else if (formData.stockType == 'silver') {
      amount = formData.quantity * (  (stockName.filter(item=>item.stockname == 'Silver Bar').filter(item=>item.name == stockkey)[0].cost )/1.03).toFixed(2);
    }
    else if (formData.stockType == 'silver-coin') {
      amount = formData.quantity * (  (stockName.filter(item=>item.stockname == 'Silver Coin').filter(item=>item.name == stockkey)[0].cost )/1.03).toFixed(2);
    }
    else if (formData.stockType == 'gold-coin916') {
      amount = formData.quantity * (  (stockName.filter(item=>item.stockname == 'Gold Coin 916').filter(item=>item.name == stockkey)[0].cost )/1.03).toFixed(2);
    }

    
   }
   console.log("amount",amount)


    setFormData({ ...formData, amount });

  }


  const [oldClientData, setOldClientData] = useState([]);
  const [oldClientData1, setOldClientData1] = useState([]);
  


  useEffect(()=>{
    
      axios.get('https://api.dremerz.net/api/kg999ClientList/')
      .then((response) => {
        // get unique vendor names
        const uniqueVendorNames = Array.from(new Set(response.data.map((item) => item.name)));
        const uniqueVendorData = uniqueVendorNames.map((name) => {
          return response.data.find((item) => item.name == name);
        });

        
        setOldClientData(uniqueVendorData);
       
      })
      .catch((error) => {
        console.error('Error fetching data from the API:', error);
      });


      axios.get('https://api.dremerz.net/api/kg999ClientList/')
      .then((response) => {
        // get unique vendor names
        const uniqueVendorNames = Array.from(new Set(response.data.map((item) => item.number)));
        const uniqueVendorData = uniqueVendorNames.map((number) => {
          return response.data.find((item) => item.number == number);
        });
        setOldClientData1(uniqueVendorData);
       
      })
      .catch((error) => {
        console.error('Error fetching data from the API:', error);
      });
  },[])


  const [selectedVendor, setSelectedVendor] = useState(null);
  const [selectedNumber, setSelectedNumber] = useState(null);

  const handleVendorChange = (selectedOption) => {
    setSelectedVendor(selectedOption);
    if(selectedOption && selectedOption.value !== "SELECT VENDOR") {
      const vendorData = oldClientData.find(item => item.name == selectedOption.value);
      setFormData({
        ...formData,
        name: vendorData.name,
        address: vendorData.address,
        aadharotpan: vendorData.aadharotpan,
        number: vendorData.number
      });
    } else {
      setFormData({
        ...formData,
        name: "",
        address: "",
        aadharotpan: "",
        number: ""
      });
    }
  };

  const handleNumberChange = (selectedOption) => {
    setSelectedNumber(selectedOption);
    if(selectedOption && selectedOption.value !== "SELECT NUMBER") {
      const numberData = oldClientData1.find(item => item.number == selectedOption.value);
      setFormData({
        ...formData,
        name: numberData.name,
        address: numberData.address,
        aadharotpan: numberData.aadharotpan,
        number: selectedOption.value
      });
    } else {
      setFormData({
        ...formData,
        name: "",
        address: "",
        aadharotpan: "",
        number: ""
      });
    }
  };

  const vendorOptions = [{ value: "SELECT VENDOR", label: "SELECT VENDOR" }, ...oldClientData.map(item => ({ value: item.name, label: item.name }))];
  const numberOptions = [{ value: "SELECT NUMBER", label: "SELECT NUMBER" }, ...oldClientData1.map(item => ({ value: item.number, label: item.number }))];




  return (

    
      loading ? 
     
        <div style={{ position: "absolute", top: "50%", left: "50%", width: "100vw", height: "100vh" }}>
        <Loader
          style={{ zIndex: 1000 }}
          type="Puff"
          color = "#00BFFF"
          height={100}
          width={100}
        />
      </div>
      
      :
      <div>
      <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",paddingRight:40}}>
      <h1 style={{color:"black",fontWeight:"bolder"}}>SALE PAGE!</h1>



         <div className="form-group" style={{width:"300px",padding:"20px"}}>
<label  style={{color:'black'}} htmlFor="date">Date</label>
<input style={{color:"black",width:"300px"}}
  type="date"
  className="form-control"
  id="date"
  name="date"
  onChange={(e)=>{
    formData.date = formatDate(e.target.value)
    console.log(formData.date)
    
  
  }}
/>

</div>

      </div>
 
   

<div style={{ display: "flex", justifyContent: "center", flexDirection: "row", gap: '50px' }}>
      <div className="form-group" style={{width:"350px"}}>
        <label htmlFor="name">CLIENT NAME</label>
        <Select
          value={selectedVendor}
          onChange={handleVendorChange}
          options={vendorOptions}
          placeholder="Select Client..."
          isSearchable
        />
      </div>

      <div className="form-group" style={{width:"250px"}}>
        <label htmlFor="number">CLIENT NUMBER</label>
        <Select
          value={selectedNumber}
          onChange={handleNumberChange}
          options={numberOptions}
          placeholder="Select number..."
          isSearchable
        />
      </div>
    </div>


       


            <br />

        <div className="col-md-6 grid-margin stretch-card" style={{minWidth:"100%"}}>
            
        <div className="card">


          <div className="card-body">
            <div style={{display:"flex",flexWrap:"wrap",justifyContent:"center",gap:"20px"}}  >





<div className="form-group" style={{width:"300px"}}>
<label  style={{color:'black'}} htmlFor="name">Name</label>
<input style={{color:"black",width:"300px"}}
  type="text"
 
  className="form-control"
  id="name"
  name="name"
  value={formData.name}
  onChange={handleInputChange}
/>
</div>

<div className="form-group" style={{width:"300px"}}>
<label  style={{color:'black'}} htmlFor="address">Address</label>
<input style={{color:"black",width:"300px"}}
  type="text"
  className="form-control"
  id="address"
  name="address"
  
  value={formData.address}
  onChange={handleInputChange}
/>
</div>
<div className="form-group" style={{width:"300px"}}>
<label  style={{color:'black'}} htmlFor="aadharotpan">Aadhar / PAN  / GST ( OPTIONAL )</label>
<input style={{color:"black",width:"300px"}}
  type="text"
  className="form-control"
  id="aadharotpan"
  
  name="aadharotpan"
  value={formData.aadharotpan}
  onChange={handleInputChange}
/>
</div>
<div className="form-group" style={{width:"300px"}}>
<label  style={{color:'black'}} htmlFor="number">Mobile Number</label>
<input style={{color:"black",width:"300px"}}
  type="text"
  
  className="form-control"
  id="number"
  name="number"
  value={formData.number}
  onChange={handleInputChange}
/>
</div>

            </div>
          
            <form style={{display:"flex",justifyContent:"center",flexWrap:"wrap",gap:20,color:"black",fontWeight:"bolder"}}>
            
              <div className="form-group" style={{ width: "200px" }}>
  <label style={{ color: 'black' }} htmlFor="stockType">
    Stock Type
  </label>
  <select
    style={{ color: 'black',width:"200px",height:"45px " }}
    className="form-control"
    id="stockType"
    name="stockType"
    value={formData.stockType}
    onChange={(e) => 
     ( setStockKey(''),
      setFormData({ ...formData, stockType: e.target.value, quantity:"" }))}
  >
    <option value="gold">Gold Bar</option>
    <option value="silver">Silver Bar</option>
    <option value="gold-coin">Gold Coin</option>
    <option value="gold-coin916">Gold Coin 916</option>
    <option value="silver-coin">Silver Coin</option>
  </select>
</div>


{
    formData.stockType == 'gold' && (
        <div className="form-group" style={{width:"200px"}}>
        <label  style={{color:'black'}} htmlFor="goldBarType">GOLD BAR ( GRAM )</label>
        <select style={{color:"black",width:"200px",height:"45px"}}
          className="form-control"
          id="goldBarType"
          name="goldBarType"
          onChange={(e)=>
            {
              setFormData({ ...formData,  quantity:"", amount:0,discount:0 }) 
              setDisc(0)
              setStockKey(e.target.value)
            }}

        >
          <option value="">SELECT GOLD BAR ( GRAM )</option>
          {
            stockName.filter  (item=>item.stockname == 'Gold Bar').map((item)=>{

              return(
                <option value={item.name}>{item.name} GRAM</option>
              )
            
            })

          }
        </select>
      </div>
    )
}

{
    formData.stockType == 'gold-coin' && (
        <div className="form-group" style={{width:"200px"}}>
        <label  style={{color:'black'}} htmlFor="goldBarType">GOLD COIN ( GRAM )</label>
        <select style={{color:"black",width:"200px",height:"45px"}}
          className="form-control"
          id="goldBarType" 
          name="goldBarType"
          onChange={(e)=>
            {
              setFormData({ ...formData,  quantity:"", amount:0,discount:0 }) 
              setDisc(0)
              setStockKey(e.target.value)
            }}
        >
          <option value="">SELECT COIN ( GRAM )</option>
          {
            stockName.filter  (item=>item.stockname == 'Gold Coin').map((item)=>{

              return(
                <option value={item.name}>{item.name} GRAM</option>
              )
            
            })

          }
        </select>
      </div>
    )
}


{
    formData.stockType == 'gold-coin916' && (
        <div className="form-group" style={{width:"200px"}}>
        <label  style={{color:'black'}} htmlFor="goldBarType">GOLD COIN 916 ( GRAM )</label>
        <select style={{color:"black",width:"200px",height:"45px"}}
          className="form-control"
          id="goldBarType" 
          name="goldBarType"
          onChange={(e)=>
            {
              setFormData({ ...formData,  quantity:"", amount:0,discount:0 }) 
              setDisc(0)
              setStockKey(e.target.value)
            }}
        >
          <option value="">SELECT COIN 916 ( GRAM )</option>
          {
            stockName.filter  (item=>item.stockname == 'Gold Coin 916').map((item)=>{

              return(
                <option value={item.name}>{item.name} GRAM</option>
              )
            
            })

          }
        </select>
      </div>
    )
}


{
    formData.stockType == 'silver' && (
        <div className="form-group" style={{width:"200px"}}>
        <label  style={{color:'black'}} htmlFor="goldBarType">SILVER BAR ( GRAM )</label>
        <select style={{color:"black",width:"200px",height:"45px"}}
          className="form-control"
          id="goldBarType"
          name="goldBarType"
          onChange={(e)=>
            {
              setFormData({ ...formData,  quantity:"", amount:0,discount:0 }) 
              setDisc(0)
              setStockKey(e.target.value)
            }}
        >
          <option value="">SELECT SILVER BAR ( GRAM )</option>
          {
            stockName.filter  (item=>item.stockname == 'Silver Bar').map((item)=>{

              return(
                <option value={item.name}>{item.name} GRAM</option>
              )
            
            })

          }
        </select>
      </div>
    )
}
           

{
    formData.stockType == 'silver-coin' && (
        <div className="form-group" style={{width:"200px"}}>
        <label  style={{color:'black'}} htmlFor="goldBarType">SILVER COIN ( GRAM )</label>
        <select style={{color:"black",width:"200px",height:"45px"}}
          className="form-control"
          id="goldBarType"
          name="goldBarType"
          onChange={(e)=>
            {
              setFormData({ ...formData,  quantity:"", amount:0,discount:0 }) 
              setDisc(0)
              setStockKey(e.target.value)
            }}
        >
          <option value="">SELECT SILVER COIN ( GRAM )</option>
          {
            stockName.filter  (item=>item.stockname == 'Silver Coin').map((item)=>{

              return(
                <option value={item.name}>{item.name} GRAM</option>
              )
            
            })

          }
        </select>
      </div>
    )
}
       {
        stockkey !=='' && stockkey !==undefined ? (
          <div className="form-group" style={{width:"200px"}}>
                <label  style={{color:'black'}} htmlFor="quantity">Quantity</label>
                <input style={{color:"black",width:"200px"}}
                  type="number"
                  className="form-control"
                  id="quantity"
                  name="quantity"
                  value={formData.quantity}
                  onChange={(e)=>{
                    formData.quantity=e.target.value;
                    quantitychange()
                  }}
                />
              </div>
        )
        :
        (
          <div className="form-group" style={{width:"200px"}}>
                <label  style={{color:'black'}} htmlFor="quantity">Quantity</label>
                <input style={{color:"black",width:"200px"}}
                  type="number"
                  disabled
                  className="form-control"
                  id="quantity"
                  name="quantity"
                  value={formData.quantity}
                  onChange={(e)=>{
                    formData.quantity=e.target.value;
                    quantitychange()
                  }}
                />
              </div>
        )

       }

              


<div className="form-group" style={{width:"200px"}}>
                <label  style={{color:'black'}} htmlFor="amount">1 Piece Cost</label>
                <input style={{color:"black",width:"200px"}}
                  type="number"
                  className="form-control"
                  disabled
                  id="amount"
                  name="amount"
                  value={formData.amount / formData.quantity || 0}
                />
              </div>

    
              <div className="form-group" style={{width:"200px"}}>
                <label  style={{color:'black'}} htmlFor="amount">Gross Amount</label>
                <input style={{color:"black",width:"200px"}}
                  type="number"
                  className="form-control"
                  disabled
                  id="amount"
                  name="amount"
                  value={formData.amount}
                  onChange={handleInputChange}
                />
              </div>
         

              <div className="form-group" style={{width:"200px"}}>
                <label  style={{color:'black'}} htmlFor="discount">Total Amount</label>
                <input style={{color:"black",width:"200px"}}
                  type="number"
                  className="form-control"
                  id="discount"
                  name="discount"
                  disabled
                  value={(formData.amount- formData.discount).toFixed(2)}
                />
              </div>

              <div className="form-group" style={{width:"200px"}}>
                <label  style={{color:'black'}} htmlFor="totalAmount">CGST ( 1.5% )</label>
                <input style={{color:"black",width:"200px"}}
                disabled
                  type="number"
                  className="form-control"
                  id="totalAmount"
                  
                  name="totalAmount"
                  value={((formData.amount- formData.discount )*0.015 ).toFixed(2)}
                />
              </div>

              <div className="form-group" style={{width:"200px"}}>
                <label  style={{color:'black'}} htmlFor="totalAmount">SGST ( 1.5% )</label>
                <input style={{color:"black",width:"200px"}}
                  type="number"
                  className="form-control"
                  id="totalAmount"
                  name="totalAmount"
                  disabled
                  value={((formData.amount- formData.discount )*0.015 ).toFixed(2)}
                />
              </div>


              <div className="form-group" style={{width:"200px"}}>
                <label  style={{color:'black'}} htmlFor="totalAmount">Net Amount</label>
                <input style={{color:"black",width:"200px"}}
                  type="number"
                  className="form-control"
                  id="totalAmount"
                  disabled
                  name="totalAmount"
                  onChange={(e) => {
                    const totalAmount = ((formData.amount - formData.discount) * 0.03 + formData.amount - formData.discount).toFixed(0);
                    setFormData({ ...formData, totalAmount });
                  }}
                  
                 value={
                  (((formData.amount - formData.discount) * 0.03 + formData.amount - formData.discount)).toFixed(0)
                 }
                
                  
                />
              </div>

          

              
        
            </form>

            <br></br>


            <br></br>
            {
            error!=""&&( <div style={{color:"red",marginLeft:"10px"}}>{error}</div>)
          }
          {
            salesuess&&( <div style={{color:"green",marginLeft:"10px"}}>sale successfull</div>)
          }
          
            <br></br>
            <span onClick={()=>{
                postData()
              }} className="btn btn-primary mr-2">
                Submit
              </span>
             
          </div>
         
        </div>
      </div>


<br></br>






      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
    <thead>
      <tr>
        <th style={tableHeaderStyle}>invoice no</th>
        <th style={tableHeaderStyle}>Stock Type</th>
        <th style={tableHeaderStyle}>Stock Grams</th>
        <th style={tableHeaderStyle}>1 Piece Price</th>

        <th style={tableHeaderStyle}>Quantity</th>
        <th style={tableHeaderStyle}>Gross Amount</th>
        <th style={tableHeaderStyle}>Discount</th>
        <th style={tableHeaderStyle}>Total Amount</th>
        <th style={tableHeaderStyle}>CGST</th>
        <th style={tableHeaderStyle}>SGST</th>
        <th style={tableHeaderStyle}>Net Amount</th>

        </tr>
        </thead>

        <tbody>
        {clientData.map((item) => {
          return (
            <tr key={item.id}>
              <td style={tableCellStyle}>{item.invoice}</td>
              <td style={tableCellStyle}>{item.stockType}</td>
              <td style={tableCellStyle}>{item.grams} GM</td>
              <td style={tableCellStyle}>{item.amount/item.quantity}</td>

              <td style={tableCellStyle}>{item.quantity}</td>
              <td style={tableCellStyle}>{item.amount}</td>
              <td style={tableCellStyle}>{item.discount}</td>
              <td style={tableCellStyle}>{(item.amount-item.discount).toFixed(2)}</td>
              <td style={tableCellStyle}>{((item.amount-item.discount)*0.015).toFixed(2)}</td>
              <td style={tableCellStyle}>{((item.amount-item.discount)*0.015).toFixed(2)}</td>
              <td style={tableCellStyle}>{(((item.amount-item.discount)*0.03)+(item.amount-item.discount)).toFixed(0)}</td>
              
            </tr>
          );
        })}

        {
            //display total of all the above
        }
        
        <tr>
        <td style={tableCellStyle}></td>
        <td style={tableCellStyle}></td>
        <td style={tableCellStyle}>Total Amount</td>

        {/* <td style={tableCellStyle}>
            {clientData.reduce((a, b) => +a + +b.grams, 0)} GM
        </td> */}
        <td style={tableCellStyle}>
        {clientData.reduce((a, b) => +a + +(b.amount/b.quantity), 0)}
        </td>

        <td style={tableCellStyle}>{clientData.reduce((a, b) => +a + +b.quantity, 0)}</td>
        <td style={tableCellStyle}>{clientData.reduce((a, b) => +a + +b.amount, 0)}</td>
        <td style={tableCellStyle}>{clientData.reduce((a, b) => +a + +b.discount, 0)}</td>
        <td style={tableCellStyle}>{clientData.reduce((a, b) => +a + +(b.amount-b.discount), 0).toFixed(2)}</td>
        <td style={tableCellStyle}>{((clientData.reduce((a, b) => +a + +(b.amount-b.discount), 0))*0.015).toFixed(2)}</td>
        <td style={tableCellStyle}>{((clientData.reduce((a, b) => +a + +(b.amount-b.discount), 0))*0.015).toFixed(2)}</td>
        <td style={tableCellStyle}>{((clientData.reduce((a, b) => +a + +(b.amount-b.discount), 0))*0.03+(clientData.reduce((a, b) => +a + +(b.amount-b.discount), 0))).toFixed(0)}</td>
        </tr>
        </tbody>
        
        
        </table>

        <br />

        <div style={{display:"flex",flexWrap:"wrap",justifyContent:"space-between"}}>





        <div className="form-group" style={{width:"200px"}}>
<label  style={{color:'black'}} >Total Discount</label>
<input style={{color:"black",width:"200px"}}
  type="number"
  className="form-control"
 onChange={(e)=>{
    setTotalDiscount(e.target.value);
  
 }}
value={totalDiscount}
/>
</div>



<div className="form-group" style={{width:"200px"}}>
<label  style={{color:'black'}} htmlFor="totalAmount">Total Amount</label>
<input style={{color:"black",width:"200px"}}
  type="number"
  className="form-control"
  id="totalAmount"
  name="totalAmount"
  disabled

  value={(((clientData.reduce((a, b) => +a + +(b.amount-b.discount), 0))+(clientData.reduce((a, b) => +a + +(b.amount-b.discount), 0))*0.03).toFixed(0)) - totalDiscount}
/>
</div>





<div 
 className="form-group"
    style={{width:"200px"}}>

<label  style={{color:'black'}} htmlFor="totalAmount">Payment Mode 1</label>
<select  style={{ color: 'black',width:"200px",height:"45px " }}
    type="number"
    className="form-control"
    id="totalAmount"
    name="totalAmount"
    onChange={(e)=>{setPaymentMode1(e.target.value)
    formData.paymentMode1=e.target.value;}}
    >
    <option value="cash">Cash</option>
    <option value="UPI">UPI</option>
    <option value="gpay">GPay</option>
    <option value="phonePay">PhonePay</option>
    <option value="paytm">Paytm</option>
    <option value="Debit Card">Debit Card</option>
    <option value="Credit Card">Credit Card</option>
    <option value="Net Banking">Net Banking</option>

</select>
</div>




<div className="form-group" style={{width:"200px"}}>

<label  style={{color:'black'}} htmlFor="totalAmount">Amount Paid ( Mode 1 )</label>

<input style={{color:"black",width:"200px"}}
    type="number"
    className="form-control"
    id="totalAmount"
    name="totalAmount"
    value={paid}
    onChange={(e)=>{
        setPaid(e.target.value);
        formData.paymentMode1Amount =e.target.value;
        setBalance((((clientData.reduce((a, b) => +a + +(b.amount-b.discount), 0))+(clientData.reduce((a, b) => +a + +(b.amount-b.discount), 0))*0.03).toFixed(0)- e.target.value - paid1)-totalDiscount);
    }}
    />
</div>





<div 
    className="form-group"
        style={{width:"200px"}}>
<label  style={{color:'black'}} htmlFor="totalAmount">Payment Mode 2</label>
<select  style={{ color: 'black',width:"200px",height:"45px " }}
    type="number"
    className="form-control"
    id="totalAmount"
    
    name="totalAmount"
    onChange={(e)=>{setPaymentMode2(e.target.value)
    formData.paymentMode2=e.target.value;
    }}
    >
    <option value="">Select</option>
    <option value="cash">Cash</option>
    <option value="UPI">UPI</option>
    <option value="gpay">GPay</option>
    <option value="phonePay">PhonePay</option>
    <option value="paytm">Paytm</option>
    <option value="Debit Card">Debit Card</option>
    <option value="Credit Card">Credit Card</option>
    <option value="Net Banking">Net Banking</option>
</select>
</div>




<div className="form-group" style={{width:"200px"}}>

<label  style={{color:'black'}} htmlFor="totalAmount">Amount Paid ( Mode 2 )</label>

<input style={{color:"black",width:"200px"}}
type="number"
className="form-control"
id="totalAmount"
name="totalAmount"
value={paid1}
onChange={(e)=>{
    setPaid1(e.target.value);
    formData.paymentMode2Amount=e.target.value;
    setBalance((((clientData.reduce((a, b) => +a + +(b.amount-b.discount), 0))+(clientData.reduce((a, b) => +a + +(b.amount-b.discount), 0))*0.03).toFixed(0)- e.target.value - paid) - totalDiscount);
}}
/>
</div>



<div className="form-group" style={{width:"200px"}}>
<label  style={{color:'black'}} htmlFor="totalAmount">Balance Amount</label>
<input style={{color:"black",width:"200px"}}
  type="number"
  className="form-control"
  id="totalAmount"
  name="totalAmount"
  disabled
  value={balance}
/>
</div>
<div className="form-group" style={{width:"200px"}}>
<label  style={{color:'black'}} htmlFor="totalAmount">Invoice</label>
<input style={{color:"black",width:"200px"}}
  type="number"
  className="form-control"
  id="totalAmount"
  name="totalAmount"
  disabled
  value={formData.invoice}
/>

</div>

</div>
<div style={{marginRight:"20px"}}>
<span
  style={{ float: "right" }}
  onClick={async () => {

    //get confirmation from user
    if (!window.confirm("Are you sure you want to complete the sale?")) {
      return;
    }


    setLoading(true);

if (formData.paymentMode1Amount == "" || formData.paymentMode1Amount == 0 || formData.paymentMode1Amount == "0") {
  setLoading(false);
  alert("Please enter payment mode 1 amount");
  return;
}

if (formData.paymentMode2 !== "" && (formData.paymentMode2Amount == "" || formData.paymentMode2Amount == 0 || formData.paymentMode2Amount == "0")) {
  setLoading(false);
  alert("Please enter payment mode 2 amount");
  return;
}


// scroll to top
window.scrollTo(0, 0);

StockToDecrease.forEach((item) => {
  axios.put(`https://api.dremerz.net/api/kg999StockList/${item.id}/`, item)
    .then((res) => {
      console.log("uiib", res);
    })
    .catch((err) => {
      setLoading(false);
      console.log("err", err);
    });
});







  clientData.forEach((data) => {
    data.paymentMode1 = formData.paymentMode1;
    data.paymentMode2 = formData.paymentMode2;
    data.paymentMode1Amount = formData.paymentMode1Amount;
    data.paymentMode2Amount = formData.paymentMode2Amount;
    data.discount = ((totalDiscount - totalDiscount *0.03) / clientData.length) ;
  });


      try {
        const storage = getStorage();
        const storageRef = ref(storage, `kg999/${clientData[0].invoice}.pdf`);
        let blob = await pdf(MyDocument()).toBlob();
        await uploadBytes(storageRef, blob).then((snapshot) => {
          console.log("Uploaded a blob or file!", snapshot);
        });
      } catch(e) {
        setLoading(false);
        console.log("Error", e);
        alert("Error");
      }
    
      const pdfRef = ref(storage, `kg999/${clientData[0].invoice}.pdf`);
      const downloadURL = await getDownloadURL(pdfRef);
    try {
      await axios.put('https://api.dremerz.net/api/kg999SquareOffAmount/1/', { invoice: formData.invoice });

      clientData.forEach((data) => {
        data.paymentMode1 = formData.paymentMode1;
        data.paymentMode2 = formData.paymentMode2;
        data.paymentMode1Amount = formData.paymentMode1Amount;
        data.paymentMode2Amount = formData.paymentMode2Amount;
        data.downloadURL = downloadURL;
        
        axios.post('https://api.dremerz.net/api/kg999ClientList/', data)
          .then((res) => {
            console.log("res", res);
          })
          .catch((err) => {
            setLoading(false);
            console.log("err", err);
          });
      });






      setLoading(false);
      alert("Sale successful");


     navigate("/bill", { state: { data: clientData ,downloadURL:downloadURL} })
    } catch (e) {
      setLoading(false);
      console.log("Error", e);
      setLoading(false);
      alert("Error");
    }
  }}
  className="btn btn-primary mr-2"
>



  Complete Sale
</span>

</div>



<br /><br />
<br /><br />
<br /><br />
<br /><br />
     </div>
      
    
     

     
    
  );
}
