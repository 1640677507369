import React from 'react'
import NewStockName from '../Components/NewStockName'

export default function AddStockQuantity() {
  return (
    <div>
       <h1 style={{color:"black",fontWeight:"bolder",transform:"uppercase" }}>ADD STOCK QUANTITY</h1>
         <br />
         <NewStockName></NewStockName>
    </div>
  )
}
