import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ViewUser = () => {
  const [user, setUser] = useState([]);
  const [value, setValue] = useState('');
  const [id, setId] = useState('');
  const [userName, setUserName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {

    function handleKeyDown(event) {
      if (event.key == 'F2' || event.key == '-') {
        navigate('/newSale');
      }
    }
    window.addEventListener('keydown', handleKeyDown);
    axios.get('https://api.dremerz.net/api/kg999LoginData/')
      .then((res) => {
        setUser(res.data);
        if (userName == '') {
        setUserName(res.data[0].name);
        setId(res.data[0].id);
        setValue(res.data[0].auth);
            
        }
        const selectedUser = res.data.find((item) => item.name == userName);
        if (selectedUser) {
          setId(selectedUser.id);
          setValue(selectedUser.auth);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [userName]);

  const handleDelete = () => {
    if (userName == 'kg999') {
      alert('Cannot delete this user');
      return;
    }
    if (id) {
      axios.delete(`https://api.dremerz.net/api/kg999LoginData/${id}/`)
        .then((res) => {
          console.log(res);
          alert('User Deleted Successfully');
          setUserName('');
          setValue('');
          setId('');
        })
        .catch((err) => {
          console.log(err);
          alert('Error Occurred');
        });
    } else {
      alert('No user selected to delete');
    }
  };

  const handleCheckboxChange = (permission) => {
    if (userName == 'kg999') {
      alert('Cannot edit this user');
      return;
    }
    const hasPermission = value.includes(permission);

    if (hasPermission) {
      // If it has permission, remove it
      const newValue = value
        .split(',')
        .filter((item) => item !== permission)
        .join(',');

      setValue(newValue);
    } else {
      // If it doesn't have permission, add it
      const newValue = value == '' ? permission : `${value},${permission}`;
      setValue(newValue);
    }
  };

  const renderCheckbox = (moduleName, permission) => (
    <tr key={moduleName}>
      <td>{moduleName}</td>
      <td style={{ textAlign: 'right' }}>
        <input
          type="checkbox"
          checked={value.includes(permission)}
          onChange={() => handleCheckboxChange(permission)}
        />
      </td>
    </tr>
  );

  return (
    <div style={{ backgroundColor: '', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', padding: '20px' }}>
      <div className="col-md-6 grid-margin stretch-card" style={{ minWidth: '100%' }}>
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">NEW / EDIT USER!</h4>
            <br />
            <form className="forms-sample">
              <div className="form-group" style={{ width: '100%' }}>
                <label htmlFor="name">USER NAME</label>
                <select
                  className="form-control"
                  id="exampleFormControlSelect1"
                  style={{ width: '100%' }}
                  onChange={(e) => setUserName(e.target.value)}
                >
                  {user.map((item) => (
                    <option key={item.name}>{item.name}</option>
                  ))}
                </select>
              </div>

              <div className="form-group" style={{ width: '100%' }}>
                <label htmlFor="name">USER PERMISSIONS</label>
                <input
                  style={{ width: '100%' }}
                  type="text"
                  className="form-control"
                  id="name"
                  disabled
                  name="name"
                  value={value}
                  placeholder="PERMISSIONS"
                />
              </div>

              <div className="form-group" style={{ width: '100%' }}>
                <h2 htmlFor="name" style={{ color: 'black' }}>USER PERMISSIONS</h2>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>MODULE NAME</th>
                      <th style={{ textAlign: 'right' }}>PERMISSION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderCheckbox('ADD NEW USER', 'ADD NEW USER')}
                    {renderCheckbox('VIEW / EDIT USER', 'VIEW / EDIT USER')}
                    {renderCheckbox('ADD STOCK QUANTITY', 'ADD STOCK QUANTITY')}
                    {renderCheckbox('FIX PRICE DETAILS', 'FIX PRICE DETAILS')}
                    {renderCheckbox('ADD - STOCK', 'ADD - STOCK')}
                    {renderCheckbox('VIEW - STOCK', 'VIEW - STOCK')}
                    {renderCheckbox('VIEW - STOCK SUMMARY', 'VIEW - STOCK SUMMARY')}
                    {renderCheckbox('CREATE NEW SALE', 'CREATE NEW SALE')}
                    {renderCheckbox('VIEW SALE SUMMARY', 'VIEW SALE SUMMARY')}
                    {renderCheckbox('VIEW SALE DETAILS', 'VIEW SALE DETAILS')}
                    {renderCheckbox('VIEW - PROFIT', 'VIEW - PROFIT')}
                    {renderCheckbox('VIEW - NON PROFIT', 'VIEW - NON PROFIT')}
                    {renderCheckbox('VIEW - LOSS', 'VIEW - LOSS')}



                  </tbody>
                </table>
              </div>

              <span
                type="submit"
                className="btn btn-primary mr-2"
                onClick={() => {

                    if (userName == 'kg999') {
                        alert('Cannot edit this user');
                        return;
                    }

                  if (userName !== '' && value !== '' && id !== '') {
                    axios.put(`https://api.dremerz.net/api/kg999LoginData/${id}/`, { auth: value })
                      .then((res) => {
                        console.log(res);
                        alert('User Updated Successfully');
                      })
                      .catch((err) => {
                        console.log(err);
                        alert('Error Occurred');
                      });
                  } else {
                    alert('Please fill all the fields');
                  }
                }}
              >
                UPDATE
              </span>
              <span
                  className="btn btn-danger"
                  onClick={handleDelete}
                >
                  DELETE
                </span>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewUser;
