import React,{useEffect, useState} from 'react';
import { Document, View, Text, StyleSheet, Image, Font,Page } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import Logo from "../logo.png";
import  CustomFont from "../times.ttf";
import { useLocation } from 'react-router-dom';
import axios from 'axios';

export default function StockSummary() {





  const tableHeaderStyle = {
    background: '#f2f2f2',
    border: '1px solid #dddddd',
    padding: '8px',
    color: 'black',
    textAlign: 'left',
    fontWeight: 'bold',
  };

  const tableCellStyle = {
    border: '1px solid #dddddd',
    color: 'grey',
    padding: '8px',
    textAlign: 'left',
  };

  const [goldbar, setgoldbar] = useState([]);
  const [goldcoin, setgoldcoin] = useState([]);
  const [goldcoin916, setgoldcoin916] = useState([]);
    const [silverbar, setsilverbar] = useState([]);
    const [silvercoin, setsilvercoin] = useState([]);
const [goldName,setGoldName] = useState("");
const [silverName,setSilverName] = useState("");
const [goldCoinName,setGoldCoinName] = useState("");
const [goldCoin916Name,setGoldCoin916Name] = useState("");
const [silverCoinName,setSilverCoinName] = useState("");


  useEffect(() => {

    axios.get('https://api.dremerz.net/api/kg999StockName/')
        .then((response) => {   
            var   a = response.data.filter((item) => item.stockname == 'Gold Bar');
            setGoldName(a);
            var  b = response.data.filter((item) => item.stockname == 'Silver Bar');
            setSilverName(b);
            var   c= response.data.filter((item) => item.stockname == 'Gold Coin');
            setGoldCoinName(c);
            var  d = response.data.filter((item) => item.stockname == 'Silver Coin');
            setSilverCoinName(d);
            var  e = response.data.filter((item) => item.stockname == 'Gold Coin 916');
            setGoldCoin916Name(e);


        })
        .catch((error) => {
            console.error('Error fetching data from the API:', error);
        });


    axios.get('https://api.dremerz.net/api/kg999StockList/')
        .then((response) => {
          var   a = response.data.filter((item) => item.stockkey == 'Gold Bar');
            setgoldbar(a);
           var  b = response.data.filter((item) => item.stockkey == 'Gold Coin');
            setgoldcoin(b);
          var   c= response.data.filter((item) => item.stockkey == 'Silver Bar');
            setsilverbar(c);
          var  d = response.data.filter((item) => item.stockkey == 'Silver Coin');
            setsilvercoin(d);
            var  e = response.data.filter((item) => item.stockkey == 'Gold Coin 916');
            setgoldcoin916(e);

        })
        .catch((error) => {
            console.error('Error fetching data from the API:', error);
        });


 
  }, []);



    const styles = StyleSheet.create({
        page: {
          backgroundColor: '#ffffff',
          padding: "20px"
        },
        View: {
            padding:"20px"
        },
        section: {
          margin: 10,
          padding: 10,
          flexGrow: 1
        },
        table: {
          display: "table",
          width: "auto",
          borderStyle: "solid",
          borderColor: '#bfbfbf',
          borderWidth: 1,
          borderRightWidth: 0,
          borderBottomWidth: 0
        },
        tableRow: { 
          margin: "auto", 
          flexDirection: "row" 
        },
        tableColHeader: { 
          width: "20%", 
          borderStyle: "solid", 
          borderColor: '#bfbfbf',
          borderBottomColor: '#000',
          borderWidth: 1,
          borderLeftWidth: 0,
          borderTopWidth: 0
        },  
        tableCol: { 
          width: "20%", 
          borderStyle: "solid", 
          borderColor: '#bfbfbf',
          borderWidth: 1,
          borderLeftWidth: 0,
          borderTopWidth: 0
        },  
        tableCellHeader: {
          margin: "auto", 
          margin: 5, 
          fontSize: 12,
          fontWeight: "bold"
        },  
        tableCell: { 
          margin: "auto", 
          margin: 5, 
          fontSize: 10 
        }
      });

    const MyDocument = () => (
        <Document>
            <Page size="A4" style={styles.page}>



<View style={styles.view}>
  <View style={{ width: "100%", display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
    <Text style={{ fontSize: 12, fontWeight: "bold" }}>GOLD BAR STOCK TABLE</Text>
  </View>
  <View style={{ marginTop: 10 }}></View>
  <View style={{ width: "100%", display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <View style={styles.tableColHeader}>
          <Text style={styles.tableCellHeader}>STOCK ITEM</Text>
        </View>
        <View style={styles.tableColHeader}>
          <Text style={styles.tableCellHeader}>TOTAL STOCK</Text>
        </View>
        <View style={styles.tableColHeader}>
          <Text style={styles.tableCellHeader}>SOLD STOCK</Text>
        </View>
        <View style={styles.tableColHeader}>
          <Text style={styles.tableCellHeader}>AVAILABLE STOCK</Text>
        </View>
      </View>
      {goldName.length > 0 &&
        goldName.map((goldItem) => {
          const matchingBarItem = goldbar.find((barItem) => barItem.grams == goldItem.name);
          if (matchingBarItem) {
            const totalStock = goldbar.reduce((sum, item) => (item.grams == goldItem.name && item.quantity !== '') ? sum + parseInt(item.quantity) : sum, 0);
            const totalSold = goldbar.reduce((sum, item) => (item.grams == goldItem.name && item.sold !== '') ? sum + parseInt(item.sold) : sum, 0);
            const totalAvailable = totalStock - totalSold;
            return (
              <View style={styles.tableRow} key={goldItem.name}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{goldItem.name} GRAM</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{totalStock}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{totalSold}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{totalAvailable}</Text>
                </View>
              </View>
            );
          }
          return null;
        })}
    </View>
  </View>
</View>

<View style={{ marginTop: 40 }}></View>


<View style={styles.view}>
  <View style={{ width: "100%", display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
    <Text style={{ fontSize: 12, fontWeight: "bold" }}>GOLD COIN STOCK TABLE</Text>
  </View>
  <View style={{ marginTop: 10 }}></View>
  <View style={{ width: "100%", display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <View style={styles.tableColHeader}>
          <Text style={styles.tableCellHeader}>STOCK ITEM</Text>
        </View>
        <View style={styles.tableColHeader}>
          <Text style={styles.tableCellHeader}>TOTAL STOCK</Text>
        </View>
        <View style={styles.tableColHeader}>
          <Text style={styles.tableCellHeader}>SOLD STOCK</Text>
        </View>
        <View style={styles.tableColHeader}>
          <Text style={styles.tableCellHeader}>AVAILABLE STOCK</Text>
        </View>
      </View>
      {goldCoinName.length > 0 &&
        goldCoinName.map((goldCoinItem) => {
          console.log(goldCoinItem.name);
          const matchingBarItem = goldcoin.find((barItem) => barItem.grams == goldCoinItem.name);

          if (matchingBarItem) {
            const totalStock = goldcoin.reduce((sum, item) => (item.grams == goldCoinItem.name && item.quantity !== '') ? sum + parseInt(item.quantity) : sum, 0);
            const totalSold = goldcoin.reduce((sum, item) => (item.grams == goldCoinItem.name && item.sold !== '') ? sum + parseInt(item.sold) : sum, 0);
            const totalAvailable = totalStock - totalSold;

            return (
              <View style={styles.tableRow} key={goldCoinItem.name}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{goldCoinItem.name} GRAM</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{totalStock}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{totalSold}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{totalAvailable}</Text>
                </View>
              </View>
            );
          }

          return null;
        })}
    </View>
  </View>
</View>


<View style={{ marginTop: 40 }}></View>


<View style={styles.view}>
  <View style={{ width: "100%", display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
    <Text style={{ fontSize: 12, fontWeight: "bold" }}>GOLD COIN 916 STOCK TABLE</Text>
  </View>
  <View style={{ marginTop: 10 }}></View>
  <View style={{ width: "100%", display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <View style={styles.tableColHeader}>
          <Text style={styles.tableCellHeader}>STOCK ITEM</Text>
        </View>
        <View style={styles.tableColHeader}>
          <Text style={styles.tableCellHeader}>TOTAL STOCK</Text>
        </View>
        <View style={styles.tableColHeader}>
          <Text style={styles.tableCellHeader}>SOLD STOCK</Text>
        </View>
        <View style={styles.tableColHeader}>
          <Text style={styles.tableCellHeader}>AVAILABLE STOCK</Text>
        </View>
      </View>
      {goldCoin916Name.length > 0 &&
        goldCoin916Name.map((goldCoinItem) => {
          const matchingBarItem = goldcoin916.find((barItem) => barItem.grams == goldCoinItem.name);

          if (matchingBarItem) {
            const totalStock = goldcoin916.reduce((sum, item) => (item.grams == goldCoinItem.name && item.quantity !== '') ? sum + parseInt(item.quantity) : sum, 0);
            const totalSold = goldcoin916.reduce((sum, item) => (item.grams == goldCoinItem.name && item.sold !== '') ? sum + parseInt(item.sold) : sum, 0);
            const totalAvailable = totalStock - totalSold;

            return (
              <View style={styles.tableRow} key={goldCoinItem.name}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{goldCoinItem.name} GRAM</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{totalStock}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{totalSold}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{totalAvailable}</Text>
                </View>
              </View>
            );
          }

          return null;
        })}
    </View>
  </View>
</View>

<View style={{ marginTop: 40 }}></View>

<View style={styles.view}>
  <View style={{ width: "100%", display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
    <Text style={{ fontSize: 12, fontWeight: "bold" }}>SILVER BAR STOCK TABLE</Text>
  </View>
  <View style={{ marginTop: 10 }}></View>
  <View style={{ width: "100%", display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <View style={styles.tableColHeader}>
          <Text style={styles.tableCellHeader}>STOCK ITEM</Text>
        </View>
        <View style={styles.tableColHeader}>
          <Text style={styles.tableCellHeader}>TOTAL STOCK</Text>
        </View>
        <View style={styles.tableColHeader}>
          <Text style={styles.tableCellHeader}>SOLD STOCK</Text>
        </View>
        <View style={styles.tableColHeader}>
          <Text style={styles.tableCellHeader}>AVAILABLE STOCK</Text>
        </View>
      </View>
      {silverName.length > 0 &&
        silverName.map((silverItem) => {
          const matchingBarItem = silverbar.find((barItem) => barItem.grams == silverItem.name);

          if (matchingBarItem) {
            const totalStock = silverbar.reduce((sum, item) => (item.grams == silverItem.name && item.quantity !== '') ? sum + parseInt(item.quantity) : sum, 0);
            const totalSold = silverbar.reduce((sum, item) => (item.grams == silverItem.name && item.sold !== '') ? sum + parseInt(item.sold) : sum, 0);
            const totalAvailable = totalStock - totalSold;

            return (
              <View style={styles.tableRow} key={silverItem.name}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{silverItem.name} GRAM</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{totalStock}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{totalSold}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{totalAvailable}</Text>
                </View>
              </View>
            );
          }

          return null;
        })}
    </View>
  </View>
</View>

<View style={{ marginTop: 40 }}></View>

<View style={styles.view}>
  <View style={{ width: "100%", display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
    <Text style={{ fontSize: 12, fontWeight: "bold" }}>SILVER COIN STOCK TABLE</Text>
  </View>
  <View style={{ marginTop: 10 }}></View>
  <View style={{ width: "100%", display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <View style={styles.tableColHeader}>
          <Text style={styles.tableCellHeader}>STOCK ITEM</Text>
        </View>
        <View style={styles.tableColHeader}>
          <Text style={styles.tableCellHeader}>TOTAL STOCK</Text>
        </View>
        <View style={styles.tableColHeader}>
          <Text style={styles.tableCellHeader}>SOLD STOCK</Text>
        </View>
        <View style={styles.tableColHeader}>
          <Text style={styles.tableCellHeader}>AVAILABLE STOCK</Text>
        </View>
      </View>
      {silverCoinName.length > 0 &&
        silverCoinName.map((silverCoinItem) => {
          const matchingBarItem = silvercoin.find((barItem) => barItem.grams == silverCoinItem.name);

          if (matchingBarItem) {
            const totalStock = silvercoin.reduce((sum, item) => (item.grams == silverCoinItem.name && item.quantity !== '') ? sum + parseInt(item.quantity) : sum, 0);
            const totalSold = silvercoin.reduce((sum, item) => (item.grams == silverCoinItem.name && item.sold !== '') ? sum + parseInt(item.sold) : sum, 0);
            const totalAvailable = totalStock - totalSold;

            return (
              <View style={styles.tableRow} key={silverCoinItem.name}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{silverCoinItem.name} GRAM</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{totalStock}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{totalSold}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{totalAvailable}</Text>
                </View>
              </View>
            );
          }

          return null;
        })}
    </View>
  </View>
</View>







</Page>



      </Document>
      );

          


      return (
        <>



<PDFViewer style={{width:"100%",height:"100vh"}}>
          <MyDocument />

        </PDFViewer>
        </>
    
      );

}
